import { useContext } from "react";
import { TextField } from "@mui/material";
import { Notes as NotesIcon } from "@mui/icons-material";

// Components
import { DownloadMemoButton } from "./BuildMemo";
import { EditorToolbar, GenerateMemoButton } from "./DocumentEditor";

// Utilities
import { post, useDebouncedCallback } from "../utilities";

// Contexts
import MemoContext from "./contexts/MemoContext";

// CSS
import "./DocumentPreamble.css";

export function DocumentPreamble({ displaySnackbar, ...props }) {
	// Context
	const memoContext = useContext(MemoContext);

	// Handles memo title change
	function handleTitleChange(e) {
		let title = e.target.value;

		// Update memo title in context
		memoContext.setTitle(title);

		// Save to database
		debouncedSave(title);
	}

	// Saves memo to database; debounced
	const debouncedSave = useDebouncedCallback((title) => {
		// Save to database
		post(`/api/docs/memo/${props.docId}/metadata`, {
			title: title,
		});
	}, 500);

	return (
		<div className="document-toolbar u-flex u-align-center u-padding-s-l">
			<div
				className="u-flex u-align-center u-absolute-align-center"
				style={{ position: "absolute", left: "8px" }}
			>
				<NotesIcon sx={{ marginRight: "8px", flexShrink: 0, flexGrow: 0 }} />
				<TextField
					variant="standard"
					value={memoContext.title || ""}
					onChange={handleTitleChange}
					id="memo-title"
					autoComplete="off"
					inputProps={{ style: { fontSize: 24 } }}
					sx={{ width: 400 }}
				/>
			</div>
			<EditorToolbar />
			<div
				className="u-flex u-absolute-align-center u-align-center u-gap-20"
				style={{ position: "absolute", right: "8px" }}
			>
				<GenerateMemoButton />
				<DownloadMemoButton memoId={props.docId} dealId={props.dealId} />
			</div>
		</div>
	);
}

export default DocumentPreamble;
