import { useContext, useState, useEffect } from "react";
import {
	TextField,
	Avatar,
	Select,
	MenuItem,
	Tooltip,
	LinearProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

// Components
import DealFiles from "../components/DealFiles";
import DealContext from "../components/contexts/DealContext";

// Utilities
import {
	postPython,
	stringToColor,
	get,
	hasUploadedDealFiles,
} from "../utilities";

// CSS
import "./Sidekick.css";

// Contexts
import UserContext from "../components/contexts/UserContext";
import { useSearchParams } from "react-router-dom";
import { DrawerMenu } from "../components/DocumentEditor";
import { SmartToy } from "@mui/icons-material";

/**
 * Diligence sidekick page
 *
 * Can add files and request answers/analysis
 */
export function Sidekick() {
	// Params hook
	const [searchParams, setSearchParams] = useSearchParams();

	// State
	const [dealId, setDealId] = useState(null);
	const [deal, setDeal] = useState({});

	// Display state
	const [messageLoading, setMessageLoading] = useState(false);

	// Function to set deal files
	function setDealFiles(files) {
		setDeal({ ...deal, files: files });
	}

	// useEffect to set dealId
	useEffect(() => {
		let newDealId = searchParams.get("dealId");

		if (newDealId) {
			setDealId(newDealId);
		}
	}, [searchParams.get("dealId")]); // eslint-disable-line react-hooks/exhaustive-deps

	// useEffect to set searchParam if dealId changes
	// Also clears conversation
	useEffect(() => {
		if (dealId) {
			setSearchParams({ dealId: dealId });
		}
	}, [dealId, setSearchParams]);

	// useEffect to set document title
	useEffect(() => {
		document.title = "Deepdive | Sidekick";
	}, []);

	return (
		<DealContext.Provider
			value={{
				files: deal.files ? deal.files : [],
				setFiles: setDealFiles,
			}}
		>
			<div className="u-flex">
				<SidekickLeftMenu dealId={dealId} setDealId={setDealId} />
				<div className="sidekick-container">
					{dealId ? (
						<SidekickChat
							dealId={dealId}
							messageLoading={messageLoading}
							setMessageLoading={setMessageLoading}
						/>
					) : (
						<SidekickPlaceholder />
					)}
					<div
						className="sidekick-chatbox-content-spacer"
						id="sidekick-chatbox-content-spacer"
					/>
				</div>
			</div>
		</DealContext.Provider>
	);
}

/**
 * Chat frame for sidekick page
 */
export function SidekickChat({ dealId, messageLoading, setMessageLoading }) {
	const welcomeMessageText =
		"Welcome to Deepdive's diligence sidekick. I can help you answer specific questions for a deal you're looking at.";

	const welcomeMessage = {
		role: "sidekick",
		content: welcomeMessageText,
	};

	const [chatHistory, setChatHistory] = useState([welcomeMessage]);

	// Function to scroll to bottom of body
	function scrollToBottom() {
		window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
	}

	// Function to send a chat message
	// Adds message to frontend with loading animation
	// Then sends to backend and displays checkmark when received
	// TODO: think about a "Thinking animation when GPT is generating"
	function sendChatMessage(role, messageText) {
		setMessageLoading(true);

		let newMessage = {
			role: role,
			content: messageText,
		};

		let newChatHistory = [...chatHistory, newMessage];

		// Append to frontend
		setChatHistory(newChatHistory);

		// Send to backend
		postPython(`/pyapi/sidekick/chat`, {
			conversation: newChatHistory,
			dealId: dealId,
		})
			.then((res) => {
				// Assemble response
				let newMessage = {
					role: "sidekick",
					content: res.response,
				};

				// Append to frontend
				newChatHistory = [...newChatHistory, newMessage];

				// Update frontend
				setChatHistory(newChatHistory);
			})
			.finally(() => {
				setMessageLoading(false);
			});
	}

	// useEffect to scroll to bottom when chat history changes
	// Also scrolls to bottom when messageLoading changes
	useEffect(() => {
		scrollToBottom();
	}, [chatHistory, messageLoading]);

	// useEffect to clear chat history when dealId changes
	useEffect(() => {
		setChatHistory([welcomeMessage]);
	}, [dealId]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="sidekick-chat-container" id="sidekick-chat-container">
			{chatHistory.map((chatMessage, index) => (
				<SidekickChatMessage message={chatMessage} key={index} />
			))}
			{messageLoading && <SidekickMessageLoading />}
			<SidekickChatBox
				sendChatMessage={sendChatMessage}
				messageLoading={messageLoading}
			/>
		</div>
	);
}

/**
 * Message loading placeholder.
 */
export function SidekickMessageLoading() {
	return (
		<div className="u-flex-column u-align-center u-gap-10 sidekick-message-loading">
			<div className="sidekick-logo-container">
				<SmartToy sx={{ fontSize: 90, color: "#" }} />
			</div>
			<LinearProgress sx={{ width: 90 }} />
		</div>
	);
}

/**
 * Chat message bubble for sidekick chat. Displays name and message
 */
export function SidekickChatMessage({ message }) {
	return (
		<div
			className={`u-flex-column u-gap-20 chat-message ${message.role || ""}`}
		>
			<ChatMessageRoleBanner role={message.role} />
			<div className="u-flex-column u-gap-10">
				<ReactMarkdown remarkPlugins={[remarkGfm]}>
					{message.content}
				</ReactMarkdown>
			</div>
		</div>
	);
}

/**
 * Role banner for chat message
 */
export function ChatMessageRoleBanner({ role }) {
	// Contexts
	const userContext = useContext(UserContext);

	// Default role of sidekick with green DD logo
	const banner = (
		<div className="u-flex u-align-center u-gap-10">
			<Avatar
				variant="rounded"
				sx={{
					bgcolor: "#00e575",
					fontWeight: 500,
					border: "1px solid #eee",
					fontSize: "1.6rem",
				}}
			>
				dd
			</Avatar>
			<h3>Sidekick</h3>
		</div>
	);

	// If role is user, get initials and put in avatar
	if (role === "user") {
		const firstName = userContext.user.firstName;
		const lastName = userContext.user.lastName;
		const fullName = `${firstName} ${lastName}`;
		const initials = firstName[0] + lastName[0];

		return (
			<div className="u-flex u-align-center u-gap-10">
				<Avatar
					variant="rounded"
					sx={{
						bgcolor: stringToColor(fullName),
						fontWeight: 500,
						border: "1px solid #eee",
						fontSize: "1.6rem",
					}}
				>
					{initials}
				</Avatar>
				<h3>{fullName}</h3>
			</div>
		);
	}

	return (
		<div className={`chat-message-role-banner ${role || ""}`}>{banner}</div>
	);
}

/**
 * User chat entry box for sidekick chat
 */
export function SidekickChatBox({ sendChatMessage, messageLoading }) {
	// Context
	const dealContext = useContext(DealContext);

	// State
	const [userMessage, setUserMessage] = useState("");

	// Tooltip message
	const noDealFiles = !hasUploadedDealFiles(dealContext.files);
	const tooltipMessage = noDealFiles
		? "Please add source files to use Sidekick"
		: null;

	// Handles submission of a chat message
	function handleChatSubmit(event) {
		event.preventDefault();
		sendChatMessage("user", userMessage);
		// TODO: check that message actually sent before deleting in F/E
		setUserMessage("");
	}

	// useEffect to click on chat box when it loads
	useEffect(() => {
		document.querySelector("#sidekick-chatbox").click();
	}, []);

	return (
		<Tooltip arrow title={tooltipMessage} placement="top">
			<form
				onSubmit={handleChatSubmit}
				className="u-flex u-gap-10 sidekick-chatbox-container"
			>
				<TextField
					fullWidth
					onChange={(e) => setUserMessage(e.target.value)}
					placeholder="Ask me anything"
					value={userMessage}
					disabled={messageLoading || noDealFiles}
					autoComplete="off"
					sx={{ backgroundColor: "white" }}
					id="sidekick-chatbox"
				/>
				<LoadingButton
					type="submit"
					variant="outlined"
					loading={messageLoading}
					disabled={!userMessage}
					sx={{ backgroundColor: "white" }}
				>
					Send
				</LoadingButton>
			</form>
		</Tooltip>
	);
}

/**
 * Left drawer menu for sidekick page
 */
export function SidekickLeftMenu({ dealId, setDealId }) {
	const sidekickLogo = (
		<div className="sidekick-logo-container" key="sidekick-logo">
			<SmartToy sx={{ fontSize: "inherit", mt: 0.4 }} /> Sidekick
		</div>
	);
	const dealFiles = <DealFiles dealId={dealId} key="deal-files" />;
	const dealSelector = (
		<SidekickDealSelector
			dealId={dealId}
			setDealId={setDealId}
			key="deal-selector"
		/>
	);

	const content = [sidekickLogo, dealSelector, dealFiles];

	return <DrawerMenu drawerWidth={400}>{content}</DrawerMenu>;
}

/**
 * Sidekick deal selector
 */
export function SidekickDealSelector({ dealId, setDealId }) {
	// State
	const [deals, setDeals] = useState([]);

	// useEffect to get deals
	useEffect(() => {
		get(`/api/deal/deals`).then((res) => {
			setDeals(res);
		});
	}, []);

	return (
		<div className={"u-flex-column" + (!dealId && " highlight-deal-selection")}>
			<div className="drawer-section-header">
				<h3>Select Deal</h3>
			</div>
			<Select
				value={dealId ? dealId : ""}
				sx={{
					minWidth: 200,
					".MuiOutlinedInput-notchedOutline": { borderStyle: "none" },
				}}
				onChange={(e) => setDealId(e.target.value)}
				displayEmpty
				fullWidth
			>
				<MenuItem value="" disabled key="placeholder">
					<em>Existing Deals</em>
				</MenuItem>
				{deals.map((deal) => (
					<MenuItem value={deal.dealId} key={deal.dealId}>
						{deal.companyName}
					</MenuItem>
				))}
			</Select>
		</div>
	);
}

/**
 * Placeholder when no deal is selected
 */
export function SidekickPlaceholder() {
	return (
		<div className="sidekick-placeholder-container">
			<div className="sidekick-placeholder-content">
				<div className="u-flex u-gap-10 u-align-center sidekick-placeholder-logo">
					<SmartToy sx={{ fontSize: "10rem" }} />
					Sidekick
				</div>
				<div className="u-flex-column u-gap-10">
					<div>Ask your diligence sidekick anything</div>
					<div>Select a deal on the left to get started</div>
				</div>
			</div>
		</div>
	);
}

export default Sidekick;
