import "./App.css";
import "./utilities.css";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import Memo from "./pages/Memo";
import QuestionList from "./pages/QuestionList";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import { Routes, Route, Navigate } from "react-router-dom";
import Profile from "./pages/Profile";
import ModelCreator from "./components/ModelInputs";
import Deal, { NewDealForm } from "./pages/Deal";
import Register from "./pages/Register";
import NotFound from "./pages/NotFound";
import { Sidekick } from "./pages/Sidekick";
import { pdfjs } from "react-pdf";

// Import and configure PDF.js worker
// This is necessary for PDF.js to work in prod
// See https://github.com/wojtekmaj/react-pdf?tab=readme-ov-file#copy-worker-to-public-directory
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

// Add comment
function App() {
	return (
		<>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/logout" element={<Navigate to="/" />} />
				<Route path="/login" element={<Login />} />
				<Route exact path="/memo" element={<Memo />} />
				<Route exact path="/memo/:memoId" element={<Memo />} />
				<Route exact path="/question-list" element={<QuestionList />} />
				<Route
					exact
					path="/question-list/:questionId"
					element={<QuestionList />}
				/>
				<Route path="/dashboard" element={<Dashboard />} />
				<Route path="/profile" element={<Profile />} />
				<Route path="/model" element={<ModelCreator dealId={56} />} />
				<Route path="/deal" element={<NewDealForm />} />
				<Route path="/deal/:dealId" element={<Deal />} />
				<Route path="/register" element={<Register />} />
				<Route path="/sidekick" element={<Sidekick />} />
				<Route path="*" element={<NotFound />} />
			</Routes>
			<Routes>
				<Route path="/" element={<Footer />} />
				<Route path="*" element={<></>} />
			</Routes>
		</>
	);
}

export default App;
