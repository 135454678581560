import React, { useContext, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
	TextField,
	Select,
	MenuItem,
	Divider,
	Button,
	CircularProgress,
	IconButton,
	Dialog,
	DialogTitle,
	DialogActions,
	Fab,
	Tooltip,
	Skeleton,
} from "@mui/material";
import {
	Add,
	ArrowDropDown,
	ArrowDropUp,
	Checklist,
	Delete,
	Download,
} from "@mui/icons-material";
import ReactMarkdown from "react-markdown";

// Components
import { DeleteDocumentButton, DrawerMenu } from "../components/DocumentEditor";
import { DealInfo } from "../components/DocumentEditor";

// Utilities
import {
	get,
	post,
	patch,
	postPython,
	downloadAPIFile,
	hasUploadedDealFiles,
	useDebouncedCallback,
} from "../utilities";

// CSS
import "./QuestionList.css";
import DealFiles from "../components/DealFiles";
import { LoadingButton } from "@mui/lab";
import DealSelector from "../components/DealSelector";
import DealContext from "../components/contexts/DealContext";
import DocumentOutline from "../components/DocumentOutline";
import { AlertSnackbar } from "../components/AlertSnackbar";

// Example question list section
const exampleSection = {
	category: "General",
	questions: "",
};

/**
 * Renders a Question List page component.
 */
function QuestionList() {
	const params = useParams();
	const [searchParams] = useSearchParams();

	// IDs
	const [docId, setDocId] = useState(null);
	const [dealId, setDealId] = useState(null);

	// State
	const [deal, setDeal] = useState({});
	const [title, setTitle] = useState("");
	const [questionSections, setQuestionSections] = useState([exampleSection]);
	const [newDealCompanyName, setNewDealCompanyName] = useState(""); // Only used for new deals
	const [snackbar, setSnackbar] = useState(null); // { message, severity, open}

	// Display state
	const [loading, setLoading] = useState(true);

	// Set deal files in deal
	function setDealFiles(files) {
		setDeal((oldDeal) => {
			return { ...oldDeal, files: files };
		});
	}

	// Function to add a section
	function addSection() {
		const newSection = { category: "General", questions: "" };
		const newQuestionSections = [...questionSections, newSection];
		const newSectionIndex = newQuestionSections.length - 1;
		setQuestionSections(newQuestionSections);
		updateBackendContent(newQuestionSections);

		// Wait 0.1 seconds and then scroll to new section

		setTimeout(() => {
			const newSection = document.getElementById(`section-${newSectionIndex}`);
			if (newSection)
				newSection.scrollIntoView({ behavior: "smooth", block: "start" });
		}, 100);
	}

	// Function to delete a section
	function deleteSection(index) {
		const newQuestionSections = [...questionSections];
		newQuestionSections.splice(index, 1);
		setQuestionSections(newQuestionSections);
		updateBackendContent(newQuestionSections);
	}

	// Function to move a section up
	function moveSectionUp(index) {
		const newQuestionSections = [...questionSections];
		const temp = newQuestionSections[index];
		newQuestionSections[index] = newQuestionSections[index - 1];
		newQuestionSections[index - 1] = temp;
		setQuestionSections(newQuestionSections);
		updateBackendContent(newQuestionSections);
	}

	// Function to move a section down
	function moveSectionDown(index) {
		const newQuestionSections = [...questionSections];
		const temp = newQuestionSections[index];
		newQuestionSections[index] = newQuestionSections[index + 1];
		newQuestionSections[index + 1] = temp;
		setQuestionSections(newQuestionSections);
		updateBackendContent(newQuestionSections);
	}

	// Write content to backend
	function updateBackendContent(newContent) {
		// If no docId, return
		if (!docId) return;

		// Write content to backend
		patch(`/api/docs/question-list/${docId}/content`, {
			content: newContent,
		});
	}

	// Function to create new question list doc
	// Function to handle submit
	async function createNewQuestionList(dealId) {
		let saveCompanyName = newDealCompanyName; // Used for existing deals
		let saveDealId = dealId; // Used for new deals

		// If existing deal, set dealId and return
		if (dealId) {
			setDealId(dealId);

			// Get deal from backend
			const deal = await get(`/api/deal/${dealId}/`);
			setDeal(deal);
			saveCompanyName = deal.companyName;
		}

		// If new deal, create new deal
		if (!dealId) {
			// If no deal company name, return
			if (!newDealCompanyName) return;

			// Create new deal
			const res = await post("/api/deal/", { companyName: newDealCompanyName });
			// Set deal
			setDeal(res.deal);

			// Set dealId
			setDealId(res.deal._id);
			saveDealId = res.deal._id;
		}

		// Create new question list
		post("/api/docs/question-list/", {
			dealId: saveDealId,
			companyName: saveCompanyName,
		}).then((res) => {
			// Set docId
			setDocId(res.docId);

			// Get content
			const content = res.content;
			if (content) setQuestionSections(content);

			// Set URL
			window.history.pushState({}, "", `/question-list/${res.docId}`);
		});
	}

	// useEffect to set docId from URL
	useEffect(() => {
		// If docId, set from params
		if (params.questionId) setDocId(params.questionId);
	}, [params]);

	// useEffect to set dealId from URL
	useEffect(() => {
		// If dealId, set from search params
		if (searchParams.get("dealId")) setDealId(searchParams.get("dealId"));

		// If no docId, create a new doc
		if (!docId) createNewQuestionList(searchParams.get("dealId"));

		// Set loading to false
		setLoading(false);
	}, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

	// useEffect to get doc from backend
	useEffect(() => {
		// If no docId, return
		if (!docId) return;

		// Get doc from backend
		get(`/api/docs/question-list/${docId}/`).then((res) => {
			const doc = res.questionList;

			// Set title
			setTitle(doc.title);

			// Set dealId
			setDealId(doc.dealId);

			// Set question sections
			setQuestionSections(doc.content);
		});
	}, [docId]);

	// useEffect to get deal from backend
	useEffect(() => {
		// If no dealId, return
		if (!dealId) return;

		// Get deal from backend
		get(`/api/deal/${dealId}/`).then((res) => {
			setDeal(res);
		});
	}, [dealId]);

	// useEffect to set page title
	useEffect(() => {
		if (!title) document.title = "Deepdive | Question List Builder";
		else document.title = `Deepdive | ${title}`;
	}, [title]);

	// If loading, show loading
	if (loading) return <CircularProgress />;

	// If no docId, show new doc page
	if (!docId && !loading)
		return (
			<NewQuestionListCreator
				dealId={dealId}
				setDealId={setDealId}
				dealCompany={newDealCompanyName}
				setDealCompany={setNewDealCompanyName}
				createNewQuestionList={createNewQuestionList}
			/>
		);

	return (
		<DealContext.Provider
			value={{
				files: deal.files ? deal.files : [],
				setFiles: setDealFiles,
			}}
		>
			<QuestionListToolbar
				title={title}
				setTitle={setTitle}
				docId={docId}
				questionSections={questionSections}
				setSnackbar={setSnackbar}
			/>
			<div className="u-flex">
				<QuestionListLeftDrawer
					docId={docId}
					dealId={dealId}
					deal={deal}
					questionSections={questionSections}
					addSection={addSection}
					title={title}
				/>
				<QuestionListContainer
					questionSections={questionSections}
					setQuestionSections={setQuestionSections}
					addSection={addSection}
					deleteSection={deleteSection}
					moveSectionUp={moveSectionUp}
					moveSectionDown={moveSectionDown}
					docId={docId}
					dealId={dealId}
					updateBackendContent={updateBackendContent}
					setSnackbar={setSnackbar}
				/>
			</div>
			<div className="spacer-100" />
			<AlertSnackbar
				snackbar={snackbar}
				setSnackbar={setSnackbar}
				sx={{ top: "130px !important" }}
			/>
		</DealContext.Provider>
	);
}

/**
 * New question list creator. Contains deal selector and create button.
 */
function NewQuestionListCreator({
	dealId,
	setDealId,
	dealCompany,
	setDealCompany,
	createNewQuestionList,
}) {
	const disableCreate = !dealId && !dealCompany;

	// Function to handle submit
	function handleSubmit(e) {
		e.preventDefault();
		createNewQuestionList(dealId);
	}

	return (
		<div className="question-list-creator-container">
			<form className="question-list-creator-form" onSubmit={handleSubmit}>
				<div className="question-list-creator-form-title">
					Create New Question List
				</div>
				<div className="question-list-creator-form-input">
					<DealSelector
						dealSelect={dealId}
						setDealCompany={setDealCompany}
						setDealId={setDealId}
						allowNewDeals={true}
					/>
					<Button
						variant="contained"
						disabled={disableCreate}
						disableElevation
						fullWidth
						type="submit"
					>
						Create
					</Button>
				</div>
			</form>
		</div>
	);
}

/**
 * Question list toolbar. Contains title and download button.
 */
function QuestionListToolbar({
	title,
	setTitle,
	docId,
	questionSections,
	setSnackbar,
}) {
	// Handle title change
	const handleTitleChange = (e) => {
		setTitle(e.target.value);
		debouncedSave(e.target.value);
	};

	// Debounced function to save title
	const debouncedSave = useDebouncedCallback((title) => {
		// Write to backend
		patch(`/api/docs/${docId}/title`, {
			title: title,
		});
	}, 500);

	return (
		<div className="document-toolbar u-flex u-align-center u-padding-s-l">
			<div
				className="u-flex u-align-center u-absolute-align-center"
				style={{ position: "absolute", left: "8px" }}
			>
				<Checklist sx={{ marginRight: "8px", flexShrink: 0, flexGrow: 0 }} />
				<TextField
					variant="standard"
					value={title || ""}
					onChange={handleTitleChange}
					id="memo_title"
					autoComplete="off"
					inputProps={{ style: { fontSize: 24 } }}
					sx={{ width: 400 }}
				/>
			</div>
			<div
				className="u-flex u-absolute-align-center u-align-center u-gap-20"
				style={{ position: "absolute", right: "8px" }}
			>
				<GenerateQuestionListButton
					questionSections={questionSections}
					setSnackbar={setSnackbar}
				/>
				<DownloadWordButton docId={docId} title={title} />
			</div>
		</div>
	);
}

function QuestionListLeftDrawer({
	docId,
	dealId,
	deal,
	questionSections,
	addSection,
	title,
}) {
	const dealFiles = <DealFiles dealId={dealId} key="deal-files" />;
	const dealOverview = <DealInfo key="deal-info" deal={deal} />;
	const documentOutline = (
		<DocumentOutline
			docJSON={questionSections}
			key="doc-outline"
			addSection={addSection}
		/>
	);
	const deleteDocumentButton = (
		<DeleteDocumentButton
			docId={docId}
			docTitle={title}
			key="delete-doc-button"
			sx={{
				display: "flex",
				justifyContent: "center",
				position: "fixed",
				bottom: "0px",
				width: "340px",
				backgroundImage:
					"linear-gradient(180deg, rgba(255,255,255,0), rgba(255,255,255,1))",
				padding: "10px 0px",
			}}
		/>
	);
	const deleteButtonSpacer = (
		<div key="delete-button-spacer" style={{ height: "60px" }} />
	);

	const children = [
		dealFiles,
		dealOverview,
		documentOutline,
		deleteDocumentButton,
		deleteButtonSpacer,
	];

	return (
		<DrawerMenu position="left" heightOffset={113}>
			{children}
		</DrawerMenu>
	);
}

/**
 * Renders a question list container.
 */
function QuestionListContainer({
	docId,
	dealId,
	questionSections,
	setQuestionSections,
	addSection,
	deleteSection,
	moveSectionUp,
	moveSectionDown,
	updateBackendContent,
}) {
	// If no questionSections, make questionSections a blank array
	questionSections = questionSections || [exampleSection];

	// Const
	const numQuestionSections = questionSections.length;

	// Function to set a section
	function setSection(index, section) {
		setQuestionSections((oldQuestionSections) => {
			const newQuestionSections = [...oldQuestionSections];
			newQuestionSections[index] = section;
			updateBackendContent(newQuestionSections);
			return newQuestionSections;
		});
	}

	return (
		<>
			<div className="question-list-container">
				{questionSections.map((section, index) => (
					<div key={index}>
						<QuestionListSection
							section={section}
							setSection={(section) => setSection(index, section)}
							index={index}
							numQuestionSections={numQuestionSections}
							deleteSection={deleteSection}
							moveSectionDown={moveSectionDown}
							moveSectionUp={moveSectionUp}
							docId={docId}
							dealId={dealId}
						/>
						<Divider />
					</div>
				))}
			</div>
			<Fab
				onClick={addSection}
				variant="extended"
				sx={{ position: "fixed", right: 30, bottom: 20 }}
			>
				<Add />
				Add Section
			</Fab>
		</>
	);
}

/**
 * Renders a question list section.
 */
function QuestionListSection({
	docId,
	dealId,
	section,
	setSection,
	index,
	numQuestionSections,
	deleteSection,
	moveSectionUp,
	moveSectionDown,
}) {
	// If no section, make section a blank object
	section = section || {};

	// State
	const [loading, setLoading] = useState(false);

	const options = [
		"General",
		"Operations",
		"Financials",
		"Sales",
		"Marketing",
		"Competition",
		"Industry",
		"Legal",
		"Environmental",
		"Other",
	];

	// Changes the section category
	const changeCategory = (e) => {
		setSection({ ...section, category: e.target.value, questions: "" });
	};

	// Sets the section questions
	function setQuestions(questions) {
		setSection({ ...section, questions, generating: false });
	}

	// Sets the section generating
	function setGenerating(generating) {
		setSection({ ...section, generating });
	}

	return (
		<div className="question-list-section" id={`section-${index}`}>
			<div className="question-list-category-container">
				Section {index + 1}
				<Select
					value={section.category || ""}
					variant="outlined"
					size="small"
					sx={{
						fontSize: 24,
						minWidth: 200,
						".MuiOutlinedInput-notchedOutline": { borderStyle: "none" },
					}}
					onChange={changeCategory}
				>
					{options.map((option) => (
						<MenuItem value={option} key={option}>
							{option}
						</MenuItem>
					))}
				</Select>
				<GenerateSectionButton
					category={section.category}
					sectionNumber={index}
					setQuestions={setQuestions}
					setGenerating={setGenerating}
					loading={loading}
					setLoading={setLoading}
					docId={docId}
					dealId={dealId}
				/>
				<div className="u-flex-grow-1" />
				<QuestionListSectionControls
					section={section}
					index={index}
					numQuestionSections={numQuestionSections}
					deleteSection={deleteSection}
					moveSectionUp={moveSectionUp}
					moveSectionDown={moveSectionDown}
				/>
			</div>
			<div className="question-list-questions">
				{loading ? (
					<QuestionListSectionPlaceholder generating={loading} />
				) : (
					<ReactMarkdown>{section.questions}</ReactMarkdown>
				)}
			</div>
		</div>
	);
}

/**
 * Controls (section type, generate button, move/delete) for a question list section.
 */
function QuestionListSectionControls({
	section,
	index,
	deleteSection,
	moveSectionUp,
	moveSectionDown,
	numQuestionSections,
}) {
	// State
	const [deleteOpen, setDeleteOpen] = useState(false);

	return (
		<div className="question-list-section-controls">
			{index !== 0 ? (
				<IconButton onClick={() => moveSectionUp(index)}>
					<ArrowDropUp />
				</IconButton>
			) : null}
			{index + 1 < numQuestionSections ? (
				<IconButton onClick={() => moveSectionDown(index)}>
					<ArrowDropDown />
				</IconButton>
			) : null}
			<IconButton onClick={() => setDeleteOpen(true)}>
				<Delete />
			</IconButton>
			<Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)}>
				<DialogTitle>Delete Section {index + 1}?</DialogTitle>
				<DialogActions>
					<Button onClick={() => setDeleteOpen(false)} color="secondary">
						Cancel
					</Button>
					<Button onClick={() => deleteSection(index)} color="error">
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

function GenerateSectionButton({
	category,
	docId,
	dealId,
	sectionNumber,
	setQuestions,
	setGenerating,
	loading,
	setLoading,
}) {
	// Context
	const dealContext = useContext(DealContext);

	// Consts
	const disableGenerate = !hasUploadedDealFiles(dealContext.files);
	const disableTooltip = disableGenerate
		? "Add source files to generate questions"
		: null;

	// Function to generate a section
	function generateSection() {
		setLoading(true);
		setGenerating(true);
		postPython(`/pyapi/question-list/${docId}/section/${sectionNumber}`, {
			category,
			dealId,
		})
			.then((res) => {
				setQuestions(res.questions);
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}

	return (
		<Tooltip
			arrow
			title={disableTooltip}
			disableHoverListener={!disableTooltip}
			key={`generate-section-${sectionNumber}`}
		>
			<div>
				<LoadingButton
					variant="contained"
					disableElevation
					loading={loading}
					onClick={generateSection}
					id={`generate-section-${sectionNumber}`}
					disabled={disableGenerate}
				>
					Generate
				</LoadingButton>
			</div>
		</Tooltip>
	);
}

/**
 * Button to download a word doc for the question list.
 */
function DownloadWordButton({ docId, title }) {
	// State
	const [loading, setLoading] = useState(false);

	// Function to download a word doc
	function downloadWordDoc() {
		// Set loading
		setLoading(true);

		// Downloads file from API
		downloadAPIFile(
			`${process.env.REACT_APP_PYTHON_URL}/pyapi/question-list/${docId}/docx`,
			title
		)
			.catch(() => {
				alert("Error downloading memo.");
			})
			.finally(() => {
				setLoading(false);
			});
	}

	return (
		<LoadingButton
			variant="contained"
			disableElevation
			loading={loading}
			onClick={downloadWordDoc}
			color="wordDoc"
			startIcon={<Download />}
		>
			Word
		</LoadingButton>
	);
}

/**
 * Button to generate all question list sections.
 */
export function GenerateQuestionListButton({ questionSections, setSnackbar }) {
	// State
	const [loading, setLoading] = useState(false);

	// Context
	const dealContext = useContext(DealContext);

	// Consts
	const disableGenerate = !hasUploadedDealFiles(dealContext.files);
	const disableTooltip = disableGenerate
		? "Add source files to generate questions"
		: null;

	// loading bool; only false when all sections are generated
	const sectionsGenerating = questionSections.some(
		(section) => section.generating
	);

	// Generates all question list sections that are blank
	function generateQuestionList() {
		setLoading(true);

		// Go through each section to see if there is content, if not, generate
		let sectionToGenerate = false;
		Object.keys(questionSections).forEach((sectionNumber) => {
			if (
				questionSections[sectionNumber]?.questions === "" ||
				!questionSections[sectionNumber]?.questions
			) {
				// Click generate button for section
				var generate_button = document.getElementById(
					"generate-section-" + sectionNumber
				);
				generate_button.click();
				sectionToGenerate = true;
			}
		});

		// If no sections to generate, set loading to false and display alert
		if (!sectionToGenerate) {
			setLoading(false);
			setSnackbar({
				message: "No blank sections to generate",
				severity: "warning",
				open: true,
			});
		}
	}

	// useEffect to set loading to false once all sections are generated
	useEffect(() => {
		if (!sectionsGenerating) setLoading(false);
	}, [sectionsGenerating]);

	return (
		<Tooltip
			arrow
			title={disableTooltip}
			disableHoverListener={!disableTooltip}
		>
			<div className="generate-memo-button">
				<LoadingButton
					variant="contained"
					color="primary"
					disableElevation
					onClick={generateQuestionList}
					loading={loading}
					disabled={disableGenerate}
				>
					Generate Question List
				</LoadingButton>
			</div>
		</Tooltip>
	);
}

/**
 * Placeholder for a question list section while generating.
 */
export function QuestionListSectionPlaceholder({ generating, numQuestions }) {
	numQuestions = numQuestions || 10; // Default to 10 questions

	if (generating)
		return (
			<div className="question-list-section-placeholder">
				{/* Show numQuestions QuestionSkeletons */}
				{[...Array(numQuestions)].map((_, i) => (
					<QuestionSkeleton key={i} questionNumber={i + 1} />
				))}
			</div>
		);
	else return null;
}

/**
 * Placeholder for a single question skeleton.
 */
export function QuestionSkeleton({ questionNumber }) {
	const [width, setWidth] = useState("50%");

	const skeletonHeight = "1.7em";
	const skeletonAnimation = "wave";

	// useEffect to set width to random percentage between 50% and 90%
	useEffect(() => {
		setWidth(Math.floor(Math.random() * 40 + 50) + "%");
	}, []);

	return (
		<div className="u-flex u-align-center question-skeleton">
			<QuestionNumberSkeleton questionNumber={questionNumber} />
			<Skeleton
				height={skeletonHeight}
				animation={skeletonAnimation}
				variant="text"
				width={width}
			/>
		</div>
	);
}

/**
 * Question number for question skeleton.
 */
export function QuestionNumberSkeleton({ questionNumber }) {
	return <span className="question-number-skeleton">{questionNumber}.</span>;
}

export default QuestionList;
