import { useContext, useEffect, useState } from "react";
import { Avatar, TextField, Button, Snackbar, Alert } from "@mui/material";

// Contexts
import UserContext from "../components/contexts/UserContext";

// Utilities
import { getInitials, post, stringToColor, patch } from "../utilities";

// CSS
import "../utilities.css";
import "./Profile.css";

const basicInfoSection = {
	id: "basicInfo",
	title: "Basic Info",
	buttonText: "Save",
	fields: [
		{
			id: "email",
			label: "Email",
			readOnly: true,
			type: "email",
		},
		{
			id: "firstName",
			label: "First Name",
			type: "text",
		},
		{
			id: "lastName",
			label: "Last Name",
			type: "text",
		},
	],
};
const passwordSection = {
	id: "password",
	title: "Change Password",
	buttonText: "Change",
	fields: [
		{
			id: "newPassword",
			label: "New Password",
			type: "password",
			autocomplete: "new-password",
			error: false,
		},
		{
			id: "confirmNewPassword",
			label: "Confirm New Password",
			type: "password",
			autocomplete: "new-password",
			error: false,
		},
	],
};
const defaultProfileSections = [basicInfoSection, passwordSection];

/**
 * @file Profile.js is the main page for the profile page.
 *
 * @returns {Component} - Profile page
 */
export function Profile() {
	// State
	const userContext = useContext(UserContext);
	const [profileSections] = useState(defaultProfileSections);
	const [snackBarMessage, setSnackBarMessage] = useState({}); // {severity, message}

	// Display state
	const [displaySnackBar, setDisplaySnackBar] = useState(false);

	// Constants
	const userName = userContext.user.firstName + " " + userContext.user.lastName;

	return (
		<div className="u-flex">
			<div className="profile-container u-flex-column u-gap-10">
				<ProfileNameBanner name={userName} />
				<div className="profile-sections-container u-flex-column u-gap-40">
					{profileSections.map((section) => (
						<ProfileSection
							key={section.title}
							title={section.title}
							fields={section.fields}
							buttonText={section.buttonText}
							sectionId={section.id}
							sectionInfo={section}
							helperText={section.helperText}
							setSnackBarMessage={setSnackBarMessage}
							setDisplaySnackBar={setDisplaySnackBar}
						/>
					))}
				</div>
				<Snackbar
					open={displaySnackBar}
					anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
					autoHideDuration={3000}
					onClose={() => setDisplaySnackBar(false)}
				>
					<Alert severity={snackBarMessage.severity}>
						{snackBarMessage.message}
					</Alert>
				</Snackbar>
			</div>
		</div>
	);
}

/**
 * Profile name banner with circle image (initials)
 * @param {string} props.name - name of user
 * @returns {Component}
 */
export function ProfileNameBanner(props) {
	if (!props.name) return null;

	return (
		<div className="profile-name-banner u-flex u-align-center u-justify-center u-gap-20">
			<div className="profile-name-banner-image">
				<Avatar
					sx={{
						bgcolor: stringToColor(props.name),
						width: "4rem",
						height: "4rem",
						fontSize: "2.5rem",
					}}
					variant="rounded"
				>
					{getInitials(props.name)}
				</Avatar>
			</div>
			<div className="profile-name-banner-name">{props.name}</div>
		</div>
	);
}

/**
 * Profile section
 * @param {string} props.title - title of section
 * @param {string} props.buttonText - text on button
 * @param {string} props.sectionId - id of section
 * @param {object} props.sectionInfo - section info (JSON)
 * @param {object} props.fields - fields in section (JSON)
 * @returns {Component}
 */
export function ProfileSection(props) {
	const [sectionInfo, setSectionInfo] = useState(props.sectionInfo);
	const [canSave, setCanSave] = useState(false);
	const userContext = useContext(UserContext);

	// useEffect to set section info on load
	useEffect(() => {
		var newSectionInfo = { ...sectionInfo };
		sectionInfo.fields.forEach((field) => {
			// Check userContext for values
			if (userContext.user[field.id]) {
				field.value = userContext.user[field.id];
			}
		});

		setSectionInfo(newSectionInfo);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// Updates the sectionInfo state when a field is updated
	function handleFieldUpdate(fieldId, newValue) {
		// Allow saving
		setCanSave(true);
		var newSectionInfo = { ...sectionInfo };
		newSectionInfo.fields.find((element) => element.id === fieldId).value =
			newValue;
		setSectionInfo(newSectionInfo);
	}

	// Submits the sectionInfo state to the backend
	function handleSectionSubmit() {
		// Disable saving
		setCanSave(false);

		// For non-password fields, post to backend and update userContext
		if (sectionInfo.id !== "password") {
			if (checkInfoLogic()) {
				// Retrieve email, firstName, lastName from sectionInfo
				var email = sectionInfo.fields.find(
					(element) => element.id === "email"
				).value;
				let firstName = sectionInfo.fields.find(
					(element) => element.id === "firstName"
				).value;
				let lastName = sectionInfo.fields.find(
					(element) => element.id === "lastName"
				).value;

				post("/api/users/basic-info", {
					email,
					firstName,
					lastName,
				})
					.then((response) => {
						// Display snackbar
						props.setSnackBarMessage({
							severity: "success",
							message: "Updated basic info",
						});
						props.setDisplaySnackBar(true);

						userContext.updateUser(response.user);
					})
					.catch((error) => {
						console.log(error);
					});
			}
		}
		// For password fields, confirm passwords match and then post to backend
		else {
			if (checkPasswordLogic()) {
				var newPassword = sectionInfo.fields.find(
					(element) => element.id === "newPassword"
				).value;
				let newPassword2 = sectionInfo.fields.find(
					(element) => element.id === "confirmNewPassword"
				).value;

				// Post to auth change_password api
				patch("/api/users/password", {
					newPassword: newPassword,
					newPassword2: newPassword2,
				})
					.then((response) => {
						// Clear password fields
						var newSectionInfo = { ...sectionInfo };
						newSectionInfo.fields.find(
							(element) => element.id === "newPassword"
						).value = "";
						newSectionInfo.fields.find(
							(element) => element.id === "confirmNewPassword"
						).value = "";
						setSectionInfo(newSectionInfo);

						// Display snackbar
						props.setSnackBarMessage({
							severity: "success",
							message: "Updated password",
						});
						props.setDisplaySnackBar(true);
					})
					.catch((error) => {
						console.log(error);
					});
			}
		}
	}

	function checkPasswordLogic() {
		var newSectionInfo = { ...sectionInfo };

		// Get password fields
		var newPasswordField = newSectionInfo.fields.find(
			(element) => element.id === "newPassword"
		);
		var confirmNewPasswordField = newSectionInfo.fields.find(
			(element) => element.id === "confirmNewPassword"
		);

		// Reset errors
		newPasswordField.error = false;
		newPasswordField.helperText = "";
		confirmNewPasswordField.error = false;
		confirmNewPasswordField.helperText = "";

		// Check if first password is empty
		if (newPasswordField.value === "") {
			newPasswordField.error = true;
			newPasswordField.helperText = "Please enter a password";
			return false;
		}

		// Check if passwords match
		if (newPasswordField.value !== confirmNewPasswordField.value) {
			newPasswordField.error = true;
			confirmNewPasswordField.error = true;
			confirmNewPasswordField.helperText = "Passwords do not match";

			setSectionInfo(newSectionInfo);
			return false;
		}

		// All good
		return true;
	}

	function checkInfoLogic() {
		var newSectionInfo = { ...sectionInfo };

		//Grabs new first and last name
		var newFirstNameField = newSectionInfo.fields.find(
			(element) => element.id === "firstName"
		);
		var newLastNameField = newSectionInfo.fields.find(
			(element) => element.id === "lastName"
		);

		//Removes white space, both for request, and in input field to show user that it was removed
		newFirstNameField.value = newFirstNameField.value.trim();
		newLastNameField.value = newLastNameField.value.trim();

		//Resets errors
		newFirstNameField.error = false;
		newFirstNameField.helperText = "";
		newLastNameField.error = false;
		newLastNameField.helperText = "";

		//First name is empty
		if (newFirstNameField.value === "") {
			newFirstNameField.error = true;
			newFirstNameField.helperText = "Please enter a first name";
		}

		//Last name is empty
		if (newLastNameField.value === "") {
			newLastNameField.error = true;
			newLastNameField.helperText = "Please enter a last name";
		}

		//First name and last name is the same as before (no need to make a request)
		if (
			newFirstNameField.value === userContext.user.firstName &&
			newLastNameField.value === userContext.user.lastName
		) {
			//No need to return an error, say someone changed the name and changed back, no request needed to be made nor error to be sent
			return false;
		}

		// Check through all fields to see if any errors were set
		if (
			newSectionInfo.fields.filter((field) => {
				return field.error;
			}).length
		)
			return false;

		//Passed all checks
		return true;
	}

	return (
		<div className="profile-section u-flex-column u-align-center u-gap-15">
			<div className="profile-section-title u-flex">
				<h2>{props.title}</h2>
			</div>
			<div className="profile-section-fields u-flex-column u-gap-10">
				{props.fields
					? props.fields.map((field) => (
							<ProfileField
								key={field.id}
								id={field.id}
								label={field.label}
								type={field.type}
								value={field.value ? field.value : ""}
								readOnly={field.readOnly}
								callback={handleFieldUpdate}
								autocomplete={field.autocomplete}
								error={field.error}
								helperText={field.helperText}
							/>
					  ))
					: null}
				<Button
					variant="outlined"
					disabled={!canSave}
					onClick={() => handleSectionSubmit(props.sectionId)}
				>
					{props.buttonText}
				</Button>
			</div>
		</div>
	);
}

/**
 * Profile field
 * @param {object} props.label - name of field
 * @param {object} props.type - type of field
 * @param {object} props.value - value of field
 * @returns {Component}
 */
export function ProfileField(props) {
	return (
		<div className="profile-field u-flex">
			<TextField
				sx={{ minWidth: 400 }}
				id={props.id}
				label={props.label}
				variant="outlined"
				value={props.value}
				disabled={props.readOnly}
				type={props.type}
				autoComplete={props.autocomplete}
				onChange={(event) => props.callback(props.id, event.target.value)}
				error={props.error}
				helperText={props.helperText}
			/>
		</div>
	);
}

export default Profile;
