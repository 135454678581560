import { HelpOutline } from "@mui/icons-material";

// CSS
import "../utilities.css";
import "./NotFound.css";

function NotFound() {
	// Set the page title
	document.title = "Deepdive | Page Not Found";

	return (
		<div className="u-flex-column u-gap-40 u-align-center">
			<div className="notfound-icon-container">
				<HelpOutline style={{ fontSize: 256 }} />
			</div>
			<div className="notfound-text-container u-flex-column u-align-center u-gap-20">
				<h1>Page Not Found</h1>
				<div>
					We're sorry, but the page you're looking for doesn't exist or may have
					been moved.
				</div>
				<div className="u-align-center">
					If you believe you've reached this page in error,{" "}
					<a href="mailto:tim@deepdiveai.ca">contact the Deepdive team</a> and
					we'll get right on it.
				</div>
			</div>
		</div>
	);
}

export default NotFound;
