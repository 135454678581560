import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

// Components
import Login from "../pages/Login";
import Register from "../pages/Register";

// Context
import UserContext from "./contexts/UserContext";

// Utilities
import { get } from "../utilities";

export function ProtectedRoute(props) {
	// Context
	const userContext = useContext(UserContext);

	// Get current URL and search params
	const location = useLocation();

	// useEffect to check if we should logout
	useEffect(() => {
		// If no userToken, return
		if (!userContext.userToken) {
			return;
		}

		// Heartbeat to check if user is valid
		get("/api/auth/")
			.then((res) => {
				console.log(res);
			})
			.catch((err) => {
				// If 401, check for errorMessage and forceLogout
				if (err.status === 401) {
					const { errorMessage, forceLogout } = err.res;

					// If logout is true, log user out with error message
					if (forceLogout) {
						userContext.logoutWithError(errorMessage);
					}
				}
			});
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// If user is logged in and token is valid
	if (
		userContext.userToken &&
		userContext.verifyToken() &&
		!userContext.refreshingToken
	) {
		// If user is logged in, render the component
		return props.children;
	} else if (userContext.refreshingToken) {
		// If refreshing token, show empty
		return <></>;
	} else if (location.pathname === "/register") {
		// Let user register if not logged in
		return <Register />;
	} else {
		// Show login page
		return <Login />;
	}
}
