// Libraries
import React, { useState } from "react";
import {
	AppBar,
	Link,
	Toolbar,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	TextField,
} from "@mui/material";
import ProfileMenu from "./ProfileMenu";
import { Feedback, SmartToy } from "@mui/icons-material";
import UserContext from "./contexts/UserContext";

// Imports
import { post } from "../utilities.js";

// CSS
import "./NavBar.css";
import "../utilities.css";

const sidekickLabel = (
	<>
		<SmartToy sx={{ mr: 1 }} /> Sidekick
	</>
);

const pages = [
	{ label: "Home", url: "/" },
	{ label: "Memo", url: "/memo" },
	{ label: "Question List", url: "/question-list" },
	{ label: sidekickLabel, url: "/sidekick" },
];

export default function NavBar() {
	return (
		<AppBar className="appbar" position="sticky" variant="dense">
			<Toolbar className="app-toolbar u-gap-15" variant="dense">
				<Link href="/">
					<div className="logo-white u-flex u-align-center navbar-logo">
						deepdive
					</div>
				</Link>
				<div className="navbar-pages-container u-flex u-align-center u-flex-grow-0 u-gap-20">
					{pages.map((page) => (
						<Link
							key={"link_" + page.label}
							className="navbar-page"
							href={page.url}
						>
							<Button
								key={"button_" + page.label}
								className="navbar-link-button"
							>
								{page.label}
							</Button>
						</Link>
					))}
				</div>
				<div className="u-flex-grow-1"></div>
				<FeedbackButton />
				<ProfileMenu />
			</Toolbar>
		</AppBar>
	);
}

export function FeedbackButton() {
	// State
	const [open, setOpen] = useState(false);
	const [feedback, setFeedback] = useState("");

	// Hooks
	const userContext = React.useContext(UserContext);

	// Handles button click on navbar
	function handleFeedbackClick() {
		setOpen(true);

		// Clear feedback text field
		setFeedback("");

		// Focus on the text field
		setTimeout(() => {
			const textField = document.getElementById("feedback-text-field");
			if (textField) textField.focus();
		}, 100);
	}

	// Sends feedback to API route
	function sendFeedback() {
		let location = window.location.href;
		let user = userContext.user;

		post("/api/utils/feedback", {
			feedback: feedback,
			location: location,
			user: user,
		})
			.then((res) => {
				if (res.success) {
					setOpen(false);
				} else {
					alert("Error sending feedback");
				}
			})
			.catch((err) => {
				alert("Error sending feedback");
				console.log(err);
			});
	}

	return (
		<>
			<Button className="navbar-link-button" onClick={handleFeedbackClick}>
				<Feedback sx={{ mr: 1 }} />
				Feedback
			</Button>
			<Dialog open={open} onClose={() => setOpen(false)}>
				<DialogTitle>Send Feedback</DialogTitle>
				<DialogContent className="u-flex-column u-gap-20">
					<DialogContentText>
						What's on your mind? Give us your unfiltered opinion and we'll work
						hard to make it better.
					</DialogContentText>
					<TextField
						multiline
						rows={4}
						fullWidth
						id="feedback-text-field"
						value={feedback}
						onChange={(e) => setFeedback(e.target.value)}
					/>
				</DialogContent>
				<DialogActions>
					<Button color="secondary" onClick={() => setOpen(false)}>
						Cancel
					</Button>
					<Button onClick={sendFeedback} disabled={!feedback.trim()}>
						Send
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
