import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, TextField } from "@mui/material";

// Custom components
import DealFiles from "../components/DealFiles";
import { TitleTile } from "../components/TitleTile";
import { DeleteButton, DocumentDisplayText, ItemList } from "./Home";

// Contexts
import DealContext from "../components/contexts/DealContext";

// Misc functions
import { get, post, formatDate, del } from "../utilities";

// CSS
import "../utilities.css";
import "./Deal.css";

/**
 * Landing page for a specific deal.
 *
 * Displays deal metadata (company name, description, deal team).
 * Also displays a list of documents associated with the deal.
 *
 * @param {*} props
 */
function Deal() {
	const params = useParams();
	const dealId = params.dealId;

	// State
	const [loading, setLoading] = useState(true); // Use this as a test for other components
	const [error, setError] = useState(null);
	const [deal, setDeal] = useState({});

	// Get deal metadata from DB
	useEffect(() => {
		if (!dealId) return;
		get(`/api/deal/${dealId}`)
			.then((deal) => {
				setDeal(deal);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				setError(err.res.status);
			});
	}, [dealId]);

	// Handles setting deal files in deal
	function setDealFiles(files) {
		setDeal({ ...deal, files: files });
	}

	// useEffect to set window title to deal name
	useEffect(() => {
		document.title = `Deepdive | ${
			deal.companyName ? deal.companyName : ""
		} Deal Overview`;
	}, [deal]);

	// Return switches
	if (loading) {
		return <div>Loading...</div>;
	} else if (error) {
		if (error === 404) {
			return <div>Deal not found.</div>;
		} else if (error === 403 || error === 401) {
			return <div>Unauthorized.</div>;
		}
		return <div>Something went wrong.</div>;
	}

	return (
		<DealContext.Provider
			value={{ files: deal.files ? deal.files : [], setFiles: setDealFiles }}
		>
			<DealDashboard deal={deal} />
		</DealContext.Provider>
	);
}

/**
 * Deal dashboard.
 *
 * Consists of deal metadata, source files, and a list of associated documents.
 * @param {*} props
 */
function DealDashboard({ deal }) {
	return (
		<div className="u-flex-column u-gap-40 deal-dashboard-container">
			<TitleTile
				label={deal.companyName}
				description={"Deal Overview"}
				type="deal"
			/>
			<DealFiles dealId={deal._id} showBackground />
			<DealDocuments deal={deal} />
		</div>
	);
}

/**
 * Deal documents display.
 *
 * Includes a list of files associated with the deal.
 * @param {*} props
 */
function DealDocuments({ deal }) {
	const [documents, setDocuments] = useState([]);
	const headers = ["Type", "Title", "Author", "Updated", ""]; // final col is delete button
	const alignments = ["left", "left", "right", "right", "right"];
	const routingPrefixes = {
		memo: "/memo/",
		"question-list": "/question-list/",
	};

	// Get documents associated with deal from DB
	useEffect(() => {
		if (deal._id !== undefined && deal._id !== null) {
			get(`/api/deal/${deal._id}/documents`).then((documents) => {
				setDocuments(documents);
			});
		}
	}, [deal._id]);

	// Delete document handler
	function handleDelete(deleteId) {
		del(`/api/docs/${deleteId}`)
			.then((res) => {
				if (res.success) {
					setDocuments((prevDocs) => {
						var newDocs = [...prevDocs];
						newDocs = newDocs.filter((doc) => doc._id !== deleteId);
						return newDocs;
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}

	function documentsToRows(documents) {
		let mappedContent = documents.map((document) => {
			let content = [
				<DocumentDisplayText doc={document} />,
				document.title,
				document.author,
				formatDate(document.lastUpdated),
				<DeleteButton
					deleteCallback={handleDelete}
					deleteTitle={document.title}
					deleteId={document._id}
				/>,
			];

			let mappedContent = content.map((item) => {
				return {
					content: item,
					link: routingPrefixes[document.type] + document._id,
				};
			});

			// Remove link from last item
			mappedContent[mappedContent.length - 1].link = null;

			return mappedContent;
		});

		return mappedContent;
	}

	return (
		<ItemList
			title={"Deal documents"}
			headers={headers}
			items={documentsToRows(documents)}
			alignments={alignments}
			newLabel={"New Document"}
			newLink={`/memo?dealId=${deal._id}`}
			dealId={deal._id}
			newDropdown
		/>
	);
}

/**
 * Fields to create a new deal.
 * @param {*} props
 */
export function NewDealForm(props) {
	const [companyName, setCompanyName] = useState("");

	// useEffect to set focus to company name field
	useEffect(() => {
		document.getElementById("company-name").focus();
	}, []);

	// Handle form submission
	function handleSubmit(e) {
		e.preventDefault();

		// Create deal in DB
		post("/api/deal", { companyName: companyName }).then((res) => {
			if (res.success) {
				// Redirect to deal page
				window.location.href = `/deal/${res.dealId}`;
			}
		});
	}

	return (
		<div className="deal-creator-container">
			<form className="new-deal-form u-flex-column" onSubmit={handleSubmit}>
				<div className="new-deal-header">New Deal</div>
				<div className="new-deal-body">
					<TextField
						value={companyName}
						label="Company Name"
						id="company-name"
						onChange={(e) => setCompanyName(e.target.value)}
						fullWidth
						autoComplete="off"
					/>
					<Button
						type="submit"
						variant="contained"
						disabled={!companyName}
						disableElevation
					>
						Create Deal
					</Button>
				</div>
			</form>
		</div>
	);
}

export default Deal;
