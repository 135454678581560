import "../utilities.css";
import "./Dashboard.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
	Paper,
	Table,
	TableCell,
	TableContainer,
	TableRow,
	TableHead,
	TableBody,
	TableFooter,
	TablePagination,
	CircularProgress,
	Menu,
	MenuItem,
	ListItemIcon,
} from "@mui/material";
import { Checklist, Notes } from "@mui/icons-material";

export function Dashboard({ headers, tableContent, alignments, loading }) {
	const [tablePage, setTablePage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	return (
		<TableContainer component={Paper} elevation={0}>
			<Table sx={{ minWidth: 650 }} aria-label="simple table">
				<DashboardHeader columnLabels={headers} alignments={alignments} />
				{loading ? (
					<LoadingRow />
				) : (
					<DashboardRows
						tableContent={tableContent}
						tablePage={tablePage}
						alignments={alignments}
						rowsPerPage={rowsPerPage}
					/>
				)}
				<TableFooter>
					<TableRow>
						<TablePagination
							rowsPerPage={rowsPerPage}
							count={tableContent ? Object.keys(tableContent).length : 0}
							page={tablePage}
							onPageChange={(event, newPage) => {
								setTablePage(newPage);
							}}
							onRowsPerPageChange={(event) => {
								setRowsPerPage(parseInt(event.target.value));
								setTablePage(0);
							}}
							rowsPerPageOptions={[5, 10, 20]}
						/>
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	);
}

/**
 * Header row for the dashboard table.
 * @param {*} props
 */
export function DashboardHeader({ columnLabels, alignments }) {
	return (
		<TableHead className="dashboard-table-header">
			<TableRow>
				{columnLabels.map((column, index) => (
					<TableCell
						key={column}
						align={alignments ? alignments[index] : "left"}
					>
						{column}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

/**
 * The sliced rows of the dashboard table.
 * @param {*} props
 */
export function DashboardRows({
	tableContent,
	alignments,
	tablePage,
	rowsPerPage,
}) {
	// If the table content is empty, return an empty row.
	if (!tableContent || Object.keys(tableContent).length === 0) {
		return <EmptyRow />;
	}

	return (
		<TableBody>
			{Object.keys(tableContent)
				.slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage)
				.map((key) => (
					<DashboardRow
						key={"doc_" + key}
						rowContent={tableContent[key]}
						alignments={alignments}
					/>
				))}
		</TableBody>
	);
}

/**
 * Content row for the dashboard table.
 * @param {*} props
 */
export function DashboardRow({ rowContent, alignments }) {
	return (
		<TableRow hover>
			{rowContent.map((rc, index) => (
				<DashboardCell
					key={`row_${index}`}
					content={rc.content}
					link={rc.link}
					align={alignments[index]}
				/>
			))}
		</TableRow>
	);
}

/**
 * Dashboard cell.
 * @param {*} props
 */
export function DashboardCell({ link, content, align }) {
	return (
		<TableCell component={link ? Link : TableCell} to={link} align={align}>
			{content}
		</TableCell>
	);
}

/**
 * Loading row for the dashboard table.
 */
export function LoadingRow() {
	return (
		<TableRow>
			<TableCell colSpan="100%" align="center">
				<CircularProgress />
			</TableCell>
		</TableRow>
	);
}

/**
 * Empty row for the dashboard table.
 */
export function EmptyRow() {
	return (
		<TableRow>
			<TableCell colSpan="100%" align="center">
				None
			</TableCell>
		</TableRow>
	);
}

export function NewDocumentDropdown({ dealId, open, anchorEl, onClose }) {
	const options = [
		{ label: "Memo", link: "/memo", icon: <Notes /> },
		{ label: "Question List", link: "/question-list", icon: <Checklist /> },
	];

	return (
		<Menu
			open={open}
			onClose={onClose}
			anchorEl={anchorEl}
			anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
			transformOrigin={{ vertical: "top", horizontal: "right" }}
		>
			{options.map((option) => (
				<MenuItem
					key={option.label}
					component={Link}
					to={dealId ? `${option.link}?dealId=${dealId}` : option.link}
				>
					{option.icon ? <ListItemIcon>{option.icon}</ListItemIcon> : null}
					{option.label}
				</MenuItem>
			))}
		</Menu>
	);
}

export default Dashboard;
