import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { Button, CircularProgress } from "@mui/material";

// Components
import DocumentEditor from "../components/DocumentEditor.js";
import UserContext from "../components/contexts/UserContext.js";
import MemoContext from "../components/contexts/MemoContext.js";
import DealContext from "../components/contexts/DealContext.js";
import DocumentPreamble from "../components/DocumentPreamble.js";
import DealSelector from "../components/DealSelector.js";
import AlertSnackbar from "../components/AlertSnackbar.js";

// Utilities
import { changeMemoType, get, post } from "../utilities.js";

// CSS
import "../utilities.css";
import "./Memo.css";

function Memo() {
	const params = useParams();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	// Contexts
	const userContext = useContext(UserContext);

	// Display states
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);

	// MemoContext states
	const [memoId, setMemoId] = useState(null);
	const [memoFormat, setMemoFormat] = useState("docx");
	const [memoInfo, setMemoInfo] = useState({});
	const [allowSaving, setAllowSaving] = useState(false);
	const [sectionTypes, setSectionTypes] = useState([]);
	const [title, setTitle] = useState("");
	const [target, setTarget] = useState("");
	const [memoJSON, setMemoJSON] = useState({});
	const [memoType, setMemoType] = useState("direct_pe");
	const [charts, setCharts] = useState([]);

	// Generation model
	const [generationModel, setGenerationModel] = useState("");
	const [generationModelJSON, setGenerationModelJSON] = useState({}); // Generation model JSON
	const [generationModels, setGenerationModels] = useState([]); // List of generation models [deepdive, gpt-nl-1

	// Deal states
	const [dealId, setDealId] = useState(null);
	const [deal, setDeal] = useState({});
	const [dealCompany, setDealCompany] = useState("");

	// Other states
	const [dealSelected, setDealSelected] = useState(false);
	const [creationStage, setCreationStage] = useState(0); // 0 - deal/format selection, 1 - file upload, 2 - memo

	// Snackbar
	const [snackbar, setSnackbar] = useState({
		open: false,
		message: "",
		severity: "info", // "info", "success", "warning", "error"
	});

	// Function to display a snackbar message
	function displaySnackbar(message) {
		setSnackbar({
			...message,
			open: true,
		});
	}

	// Set values from URL and search params
	useEffect(
		() => {
			let paramMemoId = params.memoId;
			let paramDealId = searchParams.get("dealId");
			let paramMemoFormat = searchParams.get("memoFormat");

			// Set memoId from URL
			if (paramMemoId !== undefined && paramMemoId !== null) {
				handleSetMemoId(params.memoId);
				return; // return to prevent setting dealId from search params
			}

			// Set dealId from search params
			if (paramDealId !== undefined && paramDealId !== null) {
				setDealId(searchParams.get("dealId"));
			}

			// Set memoFormat from search params
			if (paramMemoFormat !== undefined && paramMemoFormat !== null) {
				setMemoFormat(searchParams.get("memoFormat"));
			}

			setLoading(false);
		}, // eslint-disable-next-line react-hooks/exhaustive-deps
		[params.memoId, searchParams.get("dealId"), searchParams.get("memoFormat")]
	);

	// Set deal info from backend
	useEffect(() => {
		if (dealId !== null && dealId !== undefined) {
			get(`/api/deal/${dealId}`)
				.then((deal) => {
					setDeal(deal);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}, [dealId]);

	// Set memo info from backend
	function handleSetMemoId(memoId) {
		get(`/api/docs/memo/${memoId}`)
			.then((memoInfo) => {
				if (memoInfo.memoInfo) {
					setDealId(memoInfo["memoInfo"]["dealId"]);
					setMemoInfo(memoInfo["memoInfo"]);
					let resFormat = memoInfo["memoInfo"]["format"];
					if (resFormat) {
						setMemoFormat(resFormat);
					} else {
						setMemoFormat("docx"); // default to word
					}

					// Set generating in each section to false on load
					if (memoInfo["memoInfo"]["content"])
						Object.keys(memoInfo["memoInfo"]["content"]).forEach(
							(sectionId) => {
								memoInfo["memoInfo"]["content"][sectionId].generating = false;
							}
						);

					// Set memo JSON and ID
					setMemoJSON(memoInfo["memoInfo"]["content"]);
					setMemoId(memoId);

					// Set memoTitle
					if (memoInfo.memoInfo.title) setTitle(memoInfo.memoInfo.title);

					// Set generation model
					if (memoInfo.memoInfo.generationModelId)
						setGenerationModel(memoInfo.memoInfo.generationModelId);

					// Set memo subtype
					if (memoInfo.memoInfo.subtype) setMemoType(memoInfo.memoInfo.subtype);

					// Set charts
					if (memoInfo.memoInfo.charts) setCharts(memoInfo.memoInfo.charts);

					setCreationStage(1);

					if (window.location.pathname !== `/memo/${memoId}`) {
						navigate(`/memo/${memoId}`);
					}

					setLoading(false);
				}
			})
			.catch((err) => {
				console.log(err);
				setError(err.res.status);
			});
	}

	// useEffect for title to set window title
	useEffect(() => {
		if (title) {
			document.title = "Deepdive | " + title;
		} else {
			document.title = "Deepdive | Memo Builder";
		}
	}, [title]);

	// useEffect to set dealSelected
	useEffect(() => {
		if (dealId) {
			setDealSelected(true);
		} else if (dealId === null && dealCompany !== "") {
			setDealSelected(true);
		} else {
			setDealSelected(false);
		}
	}, [dealId, dealCompany]);

	// useEffect for generationModels
	useEffect(() => {
		get("/api/generation-models").then((res) => {
			setGenerationModels(res);
		});
	}, []);

	// useEffect for generationModel
	useEffect(() => {
		const gmJSON = generationModels.find(
			(model) => model._id === generationModel
		);
		if (gmJSON) setGenerationModelJSON(gmJSON);
	}, [generationModel, generationModels]);

	// Set section types from backend
	useEffect(() => {
		if (!generationModelJSON._id) return;

		// If current memo type is not in generation model, set memo type to first type in generation model
		var newMemoType = memoType;
		if (
			generationModelJSON.memoTypes.find(
				(memoTypeObj) => memoTypeObj.value === memoType
			) === undefined
		) {
			newMemoType = generationModelJSON.memoTypes[0].value;
			setMemoType(generationModelJSON.memoTypes[0].value);
			changeMemoType(memoId, memoJSON, newMemoType, generationModelJSON).then(
				(newMemoJSON) => setMemoJSON(newMemoJSON)
			);
			return;
		}

		get("/api/docs/memo/section-types", {
			generationModelId: generationModelJSON._id,
			customerSpecific: generationModelJSON.customerSpecific,
			memoType: newMemoType,
		}).then((res) => {
			setSectionTypes(res.sectionTypes);
			setAllowSaving(true);
		});
	}, [generationModelJSON, memoType]); // eslint-disable-line react-hooks/exhaustive-deps

	// Handle stage 0 (deal/format selection)
	async function handleStage0(e) {
		e.preventDefault();

		let newMemoDealId = dealId;

		// If dealId is null, create a new deal
		if (dealId === null) {
			// Create a new deal and set deal Id in component
			const newDeal = await post(`/api/deal/`, {
				companyName: dealCompany,
			}).catch((err) => {
				console.log(err);
			});

			newMemoDealId = newDeal.dealId;
			setDealId(newMemoDealId);
		}

		// Get user's full name
		let fullName = userContext.user.firstName + " " + userContext.user.lastName;

		// Create a new memo and set memo Id in component
		const newMemo = await post(`/api/docs/memo/`, {
			author: fullName,
			format: memoFormat,
			dealId: newMemoDealId,
		}).catch((err) => {
			console.log(err);
		});
		handleSetMemoId(newMemo.memoId);

		// Advance to stage 1
		setCreationStage(1);
	}

	// Set deal files in deal
	function setDealFiles(files) {
		setDeal((oldDeal) => {
			return { ...oldDeal, files: files };
		});
	}

	// Error handling switch
	if (error) {
		if (error === 404) {
			return <div>Memo not found.</div>;
		} else if (error === 403 || error === 401) {
			return <div>Unauthorized.</div>;
		}
		return <div>Something went wrong.</div>;
	}

	// Loading switch
	if (loading) {
		return (
			<CircularProgress
				size={200}
				thickness={2}
				sx={{
					color: "var(--primary)",
					margin: "auto",
					display: "flex",
					alignItems: "center",
				}}
			/>
		);
	}

	return (
		<DealContext.Provider
			value={{
				files: deal.files
					? deal.files.filter((file) => file.status !== "deleted")
					: [],
				setFiles: setDealFiles,
			}}
		>
			<MemoContext.Provider
				value={{
					memoId,
					setMemoId,
					allowSaving,
					setAllowSaving,
					sectionTypes,
					setSectionTypes,
					title,
					setTitle,
					target,
					setTarget,
					memoJSON,
					setMemoJSON,
					memoFormat,
					setMemoFormat,
					memoType,
					setMemoType,
					displaySnackbar,
					charts,
					setCharts,
				}}
			>
				<div className="memo-container">
					{creationStage === 1 && (
						<DocumentPreamble docId={memoId} dealId={dealId} />
					)}
					{loading && <CircularProgress />}
					{
						// Selecting format and deal
						!loading && creationStage === 0 && (
							<MemoSelector
								dealId={dealId}
								setDealId={setDealId}
								setDealCompany={setDealCompany}
								memoInfo={memoInfo}
								disableNext={!(memoFormat && dealSelected)}
								handleStageSubmit={handleStage0}
							/>
						)
					}
					{
						// Generating sections
						creationStage === 1 && !loading && (
							<DocumentEditor
								docId={memoId}
								dealId={dealId}
								docInfo={memoInfo}
								handleSetDocId={handleSetMemoId}
								deal={deal}
								loading={loading}
								generationModel={generationModel}
								setGenerationModel={setGenerationModel}
								generationModelJSON={generationModelJSON}
								generationModels={generationModels}
							/>
						)
					}
					<div className="spacer-100" />
				</div>
				<AlertSnackbar
					snackbar={snackbar}
					setSnackbar={setSnackbar}
					sx={{ top: "130px !important" }}
				/>
			</MemoContext.Provider>
		</DealContext.Provider>
	);
}

/**
 * Component to choose memo format (word vs. ppt).
 * @param {*} props
 */
export function MemoFormatChooser() {
	// Context
	const memoContext = useContext(MemoContext);

	// Format options
	const memoFormats = ["docx", "pptx-hybrid", "pptx"];
	const memoFormatDescriptions = {
		docx: "Word document with long-form paragraphs",
		pptx: "PowerPoint deck with short bullet points",
		"pptx-hybrid": "PowerPoint deck with longer sentence-based bullets",
	};

	// Handles click on a memo format tile
	function handleMemoFormatClick(format) {
		memoContext.setMemoFormat(format);
	}

	return (
		<div className="memo-format-chooser-container">
			<h2>Select Memo Format</h2>
			<div className="memo-format-chooser">
				{memoFormats.map((format) => {
					return (
						<div className="u-flex-column" key={format}>
							<MemoFormatTile
								memoFormat={format}
								selected={format === memoContext.memoFormat}
								key={format}
								handleMemoFormatClick={handleMemoFormatClick}
							/>
							<div className="memo-format-description">
								{memoFormatDescriptions[format]}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}

/**
 * Memo format tile.
 * @param {*} props
 */
export function MemoFormatTile(props) {
	const displayNames = {
		docx: "Word",
		pptx: "PowerPoint",
		"pptx-hybrid": "PowerPoint (Hybrid)",
	};

	return (
		<div
			className={
				"memo-format-tile" +
				(props.memoFormat ? " " + props.memoFormat : "") +
				(props.selected ? " selected" : "")
			}
			onClick={() => props.handleMemoFormatClick(props.memoFormat)}
		>
			{displayNames[props.memoFormat]}
		</div>
	);
}

/**
 * Initial memo deal and format selection.
 * @param {*} props
 */
export function MemoSelector({
	dealId,
	setDealId,
	setDealCompany,
	memoInfo,
	disableNext,
	handleStageSubmit,
}) {
	return (
		<div className="memo-selector-container">
			<form
				className="memo-selector-form u-flex-column"
				onSubmit={handleStageSubmit}
			>
				<div className="memo-creation-header u-flex u-justify-center">
					Create New Memo
				</div>
				<div className="memo-creation-body u-flex-column u-justify-center">
					<MemoFormatChooser memoInfo={memoInfo} />
					<div className="u-flex-column u-align-center u-gap-20">
						<DealSelector
							dealSelect={dealId}
							setDealId={setDealId}
							setDealCompany={setDealCompany}
							allowNewDeals={true}
						/>
						<Button
							variant="contained"
							disabled={disableNext}
							disableElevation
							type="submit"
							fullWidth
						>
							Next
						</Button>
					</div>
				</div>
			</form>
		</div>
	);
}

export default Memo;
