import { useContext, useMemo } from "react";
import { Select, MenuItem, ListSubheader } from "@mui/material";

// Contexts
import MemoContext from "./contexts/MemoContext";

// Utilities
import { post } from "../utilities";

export function ModelSelector({ docId, model, setModel, models }) {
	// Context
	const memoContext = useContext(MemoContext);

	// If no models, make models a blank array
	models = models || [];

	// Sort models into standard and custom
	const standardModels = useMemo(() => {
		let filteredModels = models.filter((model) => !model.customerSpecific);
		filteredModels = filteredModels.map((model) => ({
			...model,
			...checkDisabled(model),
		}));
		return filteredModels;
	}, [models, memoContext.memoType]); // eslint-disable-line react-hooks/exhaustive-deps
	const customModels = useMemo(() => {
		let filteredModels = models.filter((model) => model.customerSpecific);
		filteredModels = filteredModels.map((model) => ({
			...model,
			...checkDisabled(model),
		}));
		return filteredModels;
	}, [models, memoContext.memoType]); // eslint-disable-line react-hooks/exhaustive-deps

	// Check if a model should be disabled, and if so, for what reason
	function checkDisabled(model) {
		// If current memoType not in model memoTypes, disable
		if (
			memoContext.memoType &&
			model.memoTypes.find(
				(memoType) => memoType.value === memoContext.memoType
			) === undefined
		)
			return { disabled: true };
		else return { disabled: false };
	}

	// Handles model change; sets model in context and backend
	function handleModelChange(e) {
		let model = e.target.value;

		setModel(model);
		if (docId && model)
			post(`/api/docs/memo/${docId}/generation-model`, {
				modelId: model,
			});
	}

	return (
		<div className="drawer-section">
			<div className="drawer-section-header">
				<h3>Generation Model</h3>
			</div>
			<Select
				fullWidth
				value={models.length === 0 && model ? "" : model}
				onChange={handleModelChange}
				sx={{
					minWidth: 200,
					".MuiOutlinedInput-notchedOutline": { borderStyle: "none" },
				}}
				size="small"
			>
				{standardModels.length > 0 && (
					<ListSubheader disableSticky>Standard</ListSubheader>
				)}
				{standardModels.map((model) => (
					<MenuItem key={model._id} value={model._id} disabled={model.disabled}>
						{model.name}
					</MenuItem>
				))}
				{customModels.length > 0 && (
					<ListSubheader disableSticky>Custom</ListSubheader>
				)}
				{customModels.map((model) => (
					<MenuItem key={model._id} value={model._id} disabled={model.disabled}>
						{model.name}
					</MenuItem>
				))}
			</Select>
		</div>
	);
}

export default ModelSelector;
