import { useState, useEffect, useRef, useCallback } from "react";
import { Buffer } from "buffer";
import { pdfjs } from "react-pdf";

const apiUrl = process.env.REACT_APP_API_URL;
const pythonUrl = process.env.REACT_APP_PYTHON_URL;

// ex: formatParams({ some_key: "some_value", a: "b"}) => "some_key=some_value&a=b"
function formatParams(params) {
	// iterate of all the keys of params as an array,
	// map it to a new array of URL string encoded key,value pairs
	// join all the url params using an ampersand (&).
	return Object.keys(params)
		.map((key) => {
			if (Array.isArray(params[key])) {
				return params[key]
					.map((value) => key + "=" + encodeURIComponent(value))
					.join("&");
			} else {
				return key + "=" + encodeURIComponent(params[key]);
			}
		})
		.join("&");
}

// convert a fetch result to a JSON object with error handling for fetch and json errors
function convertToJSON(res) {
	if (res.ok) {
		return res
			.clone() // clone so that the original is still readable for debugging
			.json(); // start converting to JSON object
	} else {
		const err = new Error(
			`Error ${res.status} ${res.statusText} while fetching ${res.url}`
		);

		return res
			.clone()
			.json()
			.then((json) => {
				err.res = json;
				err.status = res.status;
				err.statusText = res.statusText;
				throw err;
			});
	}
}

// Helper code to make a get request. Default parameter of empty JSON Object for params.
// Returns a Promise to a JSON Object.
export function get(endpoint, params = {}) {
	const user = window.localStorage.getItem("userToken");
	const fullPath = apiUrl + endpoint + "?" + formatParams(params);
	return fetch(fullPath, {
		headers: {
			user: user,
		},
	}).then(convertToJSON);
}

// Helper code to make a delete request. Default parameter of empty JSON Object for params.
// Returns a Promise to a JSON Object.
export function del(endpoint, params = {}) {
	const user = window.localStorage.getItem("userToken");
	const fullPath = apiUrl + endpoint + "?" + formatParams(params);
	return fetch(fullPath, {
		headers: {
			user: user,
		},
		method: "delete",
	}).then(convertToJSON);
}

// Raw get (no JSON parsing)
export function getRaw(endpoint, params = {}) {
	const user = window.localStorage.getItem("userToken");
	const fullPath = apiUrl + endpoint + "?" + formatParams(params);
	return fetch(fullPath, {
		headers: {
			user: user,
		},
	});
}

// Helper code to make a post request. Default parameter of empty JSON Object for params.
// Returns a Promise to a JSON Object.
export function post(endpoint, params = {}) {
	const user = window.localStorage.getItem("userToken");
	return fetch(apiUrl + endpoint, {
		method: "post",
		headers: {
			"Content-type": "application/json",
			user: user,
		},
		body: JSON.stringify(params),
	}).then(convertToJSON); // convert result to JSON object
}

// Helper code to make a patch request. Default parameter of empty JSON Object for params.
// Returns a Promise to a JSON Object.
export function patch(endpoint, params = {}) {
	const user = window.localStorage.getItem("userToken");
	return fetch(apiUrl + endpoint, {
		method: "PATCH",
		headers: { "Content-type": "application/json", user: user },
		body: JSON.stringify(params),
	}).then(convertToJSON); // convert result to JSON object
}

// Raw post (no JSON parsing)
export function postRaw(endpoint, params = {}) {
	const user = window.localStorage.getItem("userToken");
	return fetch(apiUrl + endpoint, {
		method: "post",
		headers: { "Content-type": "application/json", user: user },
		body: JSON.stringify(params),
	}).catch((error) => {
		// give a useful error message
		throw new Error(`POST request to ${endpoint} failed with error:\n${error}`);
	});
}

// Calls the API to execute a python script with the given parameters.
export function callPython(script, params) {
	var full_params = [];

	if (Array.isArray(params)) {
		full_params = [script, ...params];
	} else {
		full_params = [script, params];
	}

	return get("/api/python", {
		script: "router",
		"params[]": full_params,
	})
		.then((res) => {
			return JSON.parse(res["data"]);
		})
		.catch((error) => {
			throw new Error(`Python request failed with error:\n${error}`);
		});
}

// Convert linebreaks to HTML linebreaks
export function convertLinebreaksHTML(str) {
	return str.replace(/(\r\n|\r|\n)/g, "<br/>");
}

// Get first and last initials from a name
export function getInitials(name) {
	if (!name) return "";
	var initials = name.match(/\b\w/g) || [];
	initials = ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
	return initials;
}

// Uploads a file to the server
export function uploadFile(file, endpoint = "/uploadfile", folderPath = "") {
	const formData = new FormData();
	formData.append("folder_path", folderPath);
	formData.append("file", file);
	return fetch(apiUrl + endpoint, {
		method: "POST",
		body: formData,
	})
		.then(convertToJSON)
		.catch((error) => {
			throw new Error(`Upload failed with error:\n${error}`);
		});
}

// Uploads a file to the server
export function uploadDealFile(file, endpoint, fileJSON = {}, dealId = null) {
	const formData = new FormData();
	formData.append("dealId", dealId);
	formData.append("file", file);
	formData.append("fileJSON", JSON.stringify(fileJSON));

	const user = window.localStorage.getItem("userToken");

	return fetch(apiUrl + endpoint, {
		method: "POST",
		body: formData,
		headers: {
			user: user,
		},
	})
		.then(convertToJSON)
		.catch((error) => {
			throw new Error(`Upload failed with error:\n${error}`);
		});
}

// Get first name
export function getFirstName(name) {
	if (!name) return "";
	return name.split(" ")[0];
}

// Convert string to title case
export function toTitleCase(str) {
	return str.replace(
		/\w\S*/g,
		(txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
	);
}

// Replace image placeholders with React image components
// Placeholder format: [image:filename]
export function replaceImagePlaceholders(str) {
	return str.replace(/\[image:([^\]]+)\]/g, (match, filename) => (
		<img src={filename} alt="" />
	));
}

// Use local storage like a react state
export function useLocalStorage(key, defaultValue) {
	const [storedValue, setStoredValue] = useState(() => {
		try {
			const item = window.localStorage.getItem(key);
			if (item) {
				return JSON.parse(item);
			} else {
				window.localStorage.setItem(key, JSON.stringify(defaultValue));
				return defaultValue;
			}
		} catch (error) {
			return defaultValue;
		}
	});

	function setValue(newValue) {
		try {
			window.localStorage.setItem(key, JSON.stringify(newValue));
		} catch (error) {}
		setStoredValue(newValue);
	}

	return [storedValue, setValue];
}

/**
 * Converts a string to a color through a hash function.
 * @param {String} string
 * @returns {String} color - hex color
 */
export function stringToColor(string) {
	let hash = 0;
	let i;

	/* eslint-disable no-bitwise */
	for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash);
	}

	let color = "#";

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff;
		color += `00${value.toString(16)}`.slice(-2);
	}
	/* eslint-enable no-bitwise */

	return color;
}

/**
 * Searches a list of filenames for duplicates and
 * returns the appropriately suffixed filename (ie: filename-3)
 * if it is the third duplicate.
 * @param {String} filename
 * @param {Array} filenames
 * @returns {String} filename
 * @example
 * getUniqueFilename("file.txt", ["file.txt", "file-2.txt"]) // "file-3.txt"
 */
export function getUniqueFilename(fileName, fileNames) {
	// if fileNames is not an array or is empty, return the original filename
	if (!Array.isArray(fileNames) || fileNames.length === 0) {
		return fileName;
	}

	// Remove any existing "-N." suffix from the end of filenames
	const filenamesWithoutSuffixes = fileNames.map((fileName) =>
		fileName.replace(/-\d+\./, ".")
	);

	// Get the number of duplicates
	const numDuplicates = filenamesWithoutSuffixes.filter(
		(name) => name === fileName
	).length;

	// If there are duplicates, add a suffix to the filename
	if (numDuplicates > 0) {
		const extension = getFileExtension(fileName);
		const nameWithoutExtension = fileName.split(".").slice(0, -1).join(".");
		return `${nameWithoutExtension}-${numDuplicates + 1}.${extension}`;
	} else {
		return fileName;
	}
}

/**
 * Returns the file extension of a filename.
 * @param {String} filename
 * @returns {String} extension
 * @example
 * getFileExtension("file.txt") // "txt"
 */
export function getFileExtension(filename) {
	// Check for null, undefined, or non-string inputs
	if (typeof filename !== "string") {
		throw new Error("Filename must be a string");
	}

	// Check for filenames with no extension
	if (!filename.includes(".")) {
		return "";
	}

	// Check for hidden files with no extension
	if (filename[0] === "." && !filename.slice(1).includes(".")) {
		return "";
	}

	return filename.split(".").pop();
}

/**
 * Converts a date object to a formatted string.
 * Default date format is "Month Day, Year".
 * @param {Date} date
 * @param {Object} dateOptions - options for formatting the date
 * @returns {String} formattedDate
 */
export function formatDate(date, dateOptions = {}) {
	const defaultDateOptions = {
		year: "numeric",
		month: "long",
		day: "numeric",
	};
	const options = { ...defaultDateOptions, ...dateOptions };

	return new Date(date).toLocaleDateString("en-US", options);
}

export function formatDateWithTime(date, dateOptions = {}) {
	const defaultDateOptions = {
		month: "short",
		day: "numeric",
		hour: "numeric",
		minute: "numeric",
	};
	const options = { ...defaultDateOptions, ...dateOptions };

	return new Date(date).toLocaleDateString("en-US", options);
}

// PYTHON API
// Helper code to make a get request. Default parameter of empty JSON Object for params.
// Returns a Promise to a JSON Object.
export function getPython(endpoint, params = {}) {
	const user = window.localStorage.getItem("userToken");
	const fullPath = pythonUrl + endpoint + "?" + formatParams(params);
	return fetch(fullPath, {
		headers: { "Content-type": "application/json", user: JSON.parse(user) },
	})
		.then(convertToJSON)
		.catch((error) => {
			// give a useful error message
			throw new Error(
				`GET request to ${fullPath} failed with error:\n${error}`
			);
		});
}

// PYTHON API
// Helper code to make a delete request. Default parameter of empty JSON Object for params.
// Returns a Promise to a JSON Object.
export function delPython(endpoint, params = {}) {
	const user = window.localStorage.getItem("userToken");
	const fullPath = pythonUrl + endpoint + "?" + formatParams(params);
	return fetch(fullPath, {
		method: "delete",
		headers: { "Content-type": "application/json", user: JSON.parse(user) },
	})
		.then(convertToJSON)
		.catch((error) => {
			// give a useful error message
			throw new Error(
				`DELETE request to ${fullPath} failed with error:\n${error}`
			);
		});
}

// PYTHON API
// Helper code to make a post request. Default parameter of empty JSON Object for params.
// Returns a Promise to a JSON Object.
export function postPython(endpoint, params = {}) {
	const user = window.localStorage.getItem("userToken");
	return fetch(pythonUrl + endpoint, {
		method: "post",
		headers: { "Content-type": "application/json", user: JSON.parse(user) },
		body: JSON.stringify(params),
	})
		.then(convertToJSON) // convert result to JSON object
		.catch((error) => {
			// give a useful error message
			throw new Error(
				`POST request to ${endpoint} failed with error:\n${error}`
			);
		});
}

// PYTHON API
// Helper code to make a post request. Default parameter of empty JSON Object for params.
// Returns a Promise to a JSON Object.
export function patchPython(endpoint, params = {}) {
	const user = window.localStorage.getItem("userToken");
	return fetch(pythonUrl + endpoint, {
		method: "PATCH",
		headers: { "Content-type": "application/json", user: JSON.parse(user) },
		body: JSON.stringify(params),
	})
		.then(convertToJSON) // convert result to JSON object
		.catch((error) => {
			// give a useful error message
			throw new Error(
				`POST request to ${endpoint} failed with error:\n${error}`
			);
		});
}

// Download a file from an API endpoint through fetch and blob
export async function downloadAPIFile(endpoint, fileName = "download") {
	try {
		const user = window.localStorage.getItem("userToken");
		const fullPath = endpoint;

		const response = await fetch(fullPath, {
			headers: { "Content-type": "application/json", user: JSON.parse(user) },
		});
		const blob = await response.blob();

		const url = window.URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.href = url;
		a.download = fileName;

		document.body.appendChild(a);
		a.click();
		window.URL.revokeObjectURL(url);
		a.remove();
	} catch (error) {
		console.error(error);
	}
}

// Function to check how many uploaded deal files there are
export function hasUploadedDealFiles(files) {
	// Check through array for files that are not status "uploading" or "deleting"
	const uploadedFiles = files.filter(
		(file) =>
			file.status !== "uploading" &&
			file.status !== "deleting" &&
			file.status !== "deleted"
	);

	// If there are any uploaded files, return true
	if (uploadedFiles.length > 0) return true;
	else return false;
}

// Decodes the JWT
export function decodeToken(token) {
	if (!token) return null;

	try {
		const decodedToken = JSON.parse(
			Buffer.from(token.split(".")[1], "base64").toString()
		);

		return decodedToken;
	} catch (err) {
		console.log(err);
		return null;
	}
}

// Checks if the JWT is expired
export function checkTokenExpired(token, offset = 0) {
	// If no token, return false
	if (!token) return true;

	// Decode token
	const decodedToken = decodeToken(token);

	try {
		// Get delta between now and expiration date
		const delta = decodedToken.exp - Date.now() / 1000;

		// Allow for offset
		if (delta - offset < 0) return true;
	} catch (err) {
		console.log(err);
		return false;
	}
}

/** Changes memo type; tries to preserve memo content where sections match; otherwise, adds new default sections
 * @param {Object} memoJSON - memo object
 * @param {String} newMemoType - memo type
 * @returns {Object} memoJSON - memo object
 */
export async function changeMemoType(
	memoId,
	memoJSON,
	newMemoType,
	generationModelJSON
) {
	// If no generationModelJSON, throw error
	if (!generationModelJSON) throw new Error("No generation model provided");

	// Get new default sections
	const { defaultSections } = await get(`/api/docs/memo/default-sections`, {
		generationModelId: generationModelJSON._id,
		customerSpecific: generationModelJSON.customerSpecific,
		memoType: newMemoType,
	});

	// Get section types for new sections
	const { sectionTypes } = await get(`/api/docs/memo/section-types`, {
		generationModelId: generationModelJSON._id,
		customerSpecific: generationModelJSON.customerSpecific,
		memoType: newMemoType,
	});

	// Create newMemoJSON
	const newMemoJSON = {};

	// Loop through new default sections
	// If any match existing sections, preserve content
	// Otherwise, add new section with blank content
	defaultSections.forEach((newSection, index) => {
		let sectionNumber = index + 1; // Section numbers are 1-indexed

		// Populate content
		let sectionContent = "";

		// If section exists, preserve content
		for (const key of Object.keys(memoJSON)) {
			let oldSection = memoJSON[key];

			// If section_type is the same, preserve content
			if (oldSection.section_type === newSection) {
				sectionContent = oldSection.section_content;
				break; // Get first match
			}
		}

		let sectionHeader = sectionTypes.find(
			(section) => section.section_type === newSection
		).section_header;

		// Add section to JSON
		newMemoJSON[sectionNumber] = {
			section_type: newSection,
			section_header: sectionHeader,
			section_content: sectionContent,
		};
	});

	// Set memo type on backend
	post(`/api/docs/memo/${memoId}/subtype`, {
		subtype: newMemoType,
	});

	// Return memo object
	return newMemoJSON;
}

/**
 * Returns a debounced value.
 * @param {Any} value
 * @param {Number} delay - delay in milliseconds
 */
export function useDebounce(value, delay) {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [value, delay]);

	return debouncedValue;
}

/**
 * Returns a debounced callback function.
 * @param {Function} callback
 * @param {Number} delay - delay in milliseconds
 */
export function useDebouncedCallback(callback, delay) {
	const savedCallback = useRef();

	// Remember the latest callback.
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	return useCallback(
		(...args) => {
			const debouncedFn = () => {
				if (savedCallback.current) {
					savedCallback.current(...args);
				}
			};
			clearTimeout(window.debounceTimer);
			window.debounceTimer = setTimeout(debouncedFn, delay);
		},
		[delay]
	);
}

// Function to check if password is correct for PDF
// Returns true if password is correct, false otherwise
export async function checkFilePassword(file_id, dealId, password) {
	// 1. Download file to array buffer
	// 2. Check if password is correct using pdfjs
	// 3. Return true or false

	// Get signed URL for file
	const { signedURL } = await get(`/api/deal/${dealId}/deal-file/${file_id}`);

	// Download into array buffer from signed URL
	if (!signedURL) throw new Error("No signed URL found");
	const file = await fetch(signedURL); // Gets file as a ReadableStream

	// Convert to array buffer
	const fileBuffer = await file.arrayBuffer();

	// Check if password is correct
	let isCorrect = false;
	await pdfjs
		.getDocument({
			data: fileBuffer,
			password: password,
		})
		.promise.then((_) => {
			console.log("Password correct");
			isCorrect = true;
		})
		.catch((error) => {
			if (error.name === "PasswordException") {
				console.log("Password incorrect");
				isCorrect = false;
			}
		});

	return isCorrect;
}

// Check if the file is a PDF and if it has a password
export async function checkIfLocked(file) {
	return new Promise((resolve, reject) => {
		if (file.type !== "application/pdf") {
			resolve(false); // Not a PDF, so not locked
			return;
		}
		const fr = new FileReader();

		fr.onload = function () {
			let res = fr.result;
			pdfjs
				.getDocument(res)
				.promise.then((pdf) => {
					resolve(false);
				})
				.catch((error) => {
					if (error.name !== "PasswordException") {
						resolve(false);
						return;
					}
					resolve(true);
				});
		};

		try {
			fr.readAsArrayBuffer(file);
		} catch (error) {
			console.log(error);
		}
	});
}

// Unlock deal file
// For unlocked files, we just give the file object to the backend
// File is already on server just doesn't have password yet
export async function unlockFile(dealId, fileJSON) {
	console.log("Adding unlocked file");
	const res = await postPython(
		`/pyapi/deal/${dealId}/file/${fileJSON.file_id}/unlock`,
		{
			fileJSON: fileJSON,
		}
	);

	return { res };
}

// Truncate file name if too long
export function truncateFileName(fileName, maxLength) {
	if (!fileName) return;

	if (maxLength && fileName.length > maxLength) {
		fileName = fileName.substring(0, maxLength) + "…";
	}

	return fileName;
}
