import "./TitleTile.css";

/**
 * Title tile
 * @param {string} props.label - Title
 * @param {string} props.description - Description
 * @param {string} props.type - Type of tile
 */
export function TitleTile(props) {
	return (
		<div className={"title-tile " + (props.type + "-tile-bg" || "")}>
			<div className="u-flex u-align-center u-gap-10">
				<h1>{props.label}</h1>
				{props.bubble && <div className="link-tile-bubble">{props.bubble}</div>}
			</div>
			<span className="link-tile-description">{props.description}</span>
		</div>
	);
}
