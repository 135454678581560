import React, { useState, useEffect } from "react";
import { MenuItem, Select, TextField, ListSubheader } from "@mui/material";

import { get } from "../utilities.js";

import "../utilities.css";

function DealSelector({
	dealSelect,
	setDealId,
	setDealCompany,
	allowNewDeals,
}) {
	const [deals, setDeals] = useState([]);

	// Get deals from backend
	useEffect(() => {
		get("/api/deal/deals").then((res) => {
			setDeals(res);
		});

		// Focus on new deal textfield
		if (!dealSelect && allowNewDeals) {
			document.getElementById("new-deal-company-name").focus();
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// Handle deal change
	const handleDealChange = (event) => {
		if (event.target.value === "new_deal") {
			setDealId(null);
		} else {
			setDealId(event.target.value);
		}
	};

	return (
		<div className="deal-selector">
			<h2>Select Deal</h2>
			<div className="deal-selector-container u-flex u-gap-15">
				<Select
					displayEmpty
					value={
						deals.length > 0
							? dealSelect
								? dealSelect
								: "new_deal"
							: "new_deal"
					}
					sx={{ minWidth: 200 }}
					onChange={handleDealChange}
				>
					{allowNewDeals && (
						<MenuItem value="new_deal">
							<em>New Deal</em>
						</MenuItem>
					)}
					{deals.length > 0 && <ListSubheader>Existing Deals</ListSubheader>}
					{deals.map((deal) => {
						return (
							<MenuItem value={deal.dealId} key={deal.dealId}>
								{deal.companyName}
							</MenuItem>
						);
					})}
				</Select>
				{!dealSelect && allowNewDeals && (
					<TextField
						label="Company Name"
						onChange={(event) => {
							setDealCompany(event.target.value);
						}}
						id="new-deal-company-name"
						sx={{
							minWidth: 400,
						}}
					/>
				)}
			</div>
		</div>
	);
}

export default DealSelector;
