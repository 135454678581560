import { useState, useEffect, useContext, useMemo } from "react";
import {
	Select,
	TextField,
	Skeleton,
	IconButton,
	Dialog,
	DialogTitle,
	DialogActions,
	Button,
	CircularProgress,
	Menu,
	MenuItem,
	InputAdornment,
	Tooltip,
	ListItemIcon,
	ListSubheader,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
	Delete as DeleteIcon,
	ArrowDropUp,
	ArrowDropDown,
	FileDownload as DownloadIcon,
	History,
	Warning,
	ChatOutlined,
	SendOutlined,
	Close,
	Flare,
	Person,
	AutoMode,
} from "@mui/icons-material";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

// Components
import { SlideViewer } from "./Slides";
import SourceValidator from "./SourceValidator";

// Contexts
import MemoContext from "./contexts/MemoContext";
import UserContext from "./contexts/UserContext";
import DealContext from "./contexts/DealContext";

// Utilities
import {
	get,
	postPython,
	patchPython,
	hasUploadedDealFiles,
	formatDateWithTime,
	downloadAPIFile,
} from "../utilities";

// CSS
import "./Slides.css";
import "./SectionBuilder.css";

/**
 * A memo section block
 * @param {string} props.dealId The deal ID.
 * @param {number} props.section_number The number of the section.
 * @param {number} props.num_sections The total number of sections.
 * @param {function} props.deleteSectionCallback A callback function that is called when the user deletes a section.
 * @param {function} props.moveSectionUpCallback A callback function that is called when the user moves a section up.
 * @param {function} props.moveSectionDownCallback A callback function that is called when the user moves a section down.
 * @param {string} props.id The ID of the section block.
 * @returns {Component} A memo section block
 */
export function SectionBlock(props) {
	// Display states
	const [section_loading, setSectionLoading] = useState(false);
	const [section_editing, setSectionEditing] = useState(false);
	const [chatOpen, setChatOpen] = useState(false);

	// Contexts
	const memoContext = useContext(MemoContext);
	const userContext = useContext(UserContext);

	// Content history states
	const contentHistory =
		memoContext.memoJSON[props.section_number]["contentHistory"] || [];
	const contentHistoryIndex =
		memoContext.memoJSON[props.section_number]["contentHistoryIndex"];

	// Updates the section content in the JSON
	function handleSectionContentChange(content) {
		var newMemoJSON = { ...memoContext.memoJSON };
		newMemoJSON[props.section_number]["section_content"] = content;
		memoContext.setMemoJSON(newMemoJSON);
	}

	// Updates the section type and header in the JSON
	function handleSectionTypeChange(
		section_type,
		section_header,
		reset_content = true
	) {
		// Update JSON
		var newMemoJSON = { ...memoContext.memoJSON };
		newMemoJSON[props.section_number]["section_header"] = section_header;
		newMemoJSON[props.section_number]["section_type"] = section_type;

		// Reset content history
		newMemoJSON[props.section_number]["contentHistory"] = [];

		memoContext.setMemoJSON(newMemoJSON);

		// If custom section, focus on prompt input after 100 ms
		if (section_type === "custom") {
			setTimeout(() => {
				document
					.querySelector("#custom_prompt_" + props.section_number)
					.focus();
			}, 100);
		}

		if (reset_content) handleSectionContentChange("");
	}

	// Calls OpenAI API to generate text for the section
	function handleSectionWriting() {
		var section_type =
			memoContext.memoJSON[props.section_number]["section_type"];

		if (section_type == null) {
			alert("Please select a section type before generating text.");
		} else {
			setSectionLoading(true); // Show loading icon
			setChatOpen(false); // Close chat
			memoContext.memoJSON[props.section_number]["generating"] = true;
			handleSectionContentChange(""); // Clear content

			postPython(
				`/pyapi/memo/${props.memoId}/section/${props.section_number}`,
				{
					dealId: props.dealId,
					sectionType: section_type,
					memoFormat: memoContext.memoFormat,
					customPrompt:
						memoContext.memoJSON[props.section_number]["custom_prompt"],
					generationModel: props.generationModel,
					memoType: memoContext.memoType,
				}
			)
				.then((res) => {
					var new_content = res.memoSection[props.section_number].content; // Get content from response
					addToContentHistory(new_content, "generation"); // Add to content history
					handleSectionContentChange(new_content); // Update content
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => {
					setSectionLoading(false);
					memoContext.memoJSON[props.section_number]["generating"] = false;
				});
		}
	}

	// Adds content to content history
	function addToContentHistory(content, type = "generation") {
		// Get date/time local to browser
		let date = new Date();

		// Get full author name
		let authorName = `${userContext.user.firstName} ${userContext.user.lastName}`;

		// If generation, check if there are any previous generations
		if (type === "generation") {
			let prevGenerations = contentHistory.filter(
				(entry) => entry.type === "generation"
			);

			// If there are previous generations, change type to "regeneration"
			if (prevGenerations.length > 0) type = "regeneration";
		}

		// Assemble history object with date/time and content
		let newHistoryEntry = {
			type: type,
			date: date,
			content: content,
			author: authorName,
		};

		// Append to content history
		let oldContentHistory =
			memoContext.memoJSON[props.section_number]["contentHistory"] || [];
		let newContentHistory = [...oldContentHistory, newHistoryEntry];

		// Write to JSON
		let newMemoJSON = { ...memoContext.memoJSON };

		newMemoJSON[props.section_number]["contentHistory"] = newContentHistory;
		newMemoJSON[props.section_number]["contentHistoryIndex"] =
			newContentHistory.length - 1;

		memoContext.setMemoJSON(newMemoJSON);
	}

	// Calls OpenAI API to edit previously generated text
	function handleSectionEditing(editPrompt) {
		var section_type =
			memoContext.memoJSON[props.section_number]["section_type"];

		if (section_type == null) {
			alert("Please select a section type before editing text.");
		} else {
			setSectionEditing(true); // Show loading icon

			let newMemoJSON = { ...memoContext.memoJSON };
			newMemoJSON[props.section_number]["generating"] = true;
			memoContext.setMemoJSON(newMemoJSON);

			patchPython(
				`/pyapi/memo/${props.memoId}/section/${props.section_number}`,
				{
					dealId: props.dealId,
					sectionType: section_type,
					editPrompt: editPrompt,
					memoFormat: memoContext.memoFormat,
					generationModel: props.generationModel,
					memoType: memoContext.memoType,
				}
			)
				.then((res) => {
					if (res.memoSection) {
						let editedContent = res.memoSection.section_content;
						addToContentHistory(editedContent, "edit");
						handleSectionContentChange(editedContent);
					}
				})
				.catch((err) => {
					console.log(err);
				})
				.finally(() => {
					setSectionEditing(false);
					memoContext.memoJSON[props.section_number]["generating"] = false;
				});
		}
	}

	// Updates the custom prompt in the JSON
	function handleCustomPromptChange(section_number, custom_prompt) {
		var newMemoJSON = { ...memoContext.memoJSON };
		newMemoJSON[section_number]["custom_prompt"] = custom_prompt;
		memoContext.setMemoJSON(newMemoJSON);
	}

	return (
		<div className="u-flex u-flex-column section-block" id={props.id || ""}>
			<SectionBlockInputs
				dealId={props.dealId}
				memoId={props.memoId}
				section_number={props.section_number}
				section_type={
					memoContext.memoJSON[props.section_number]["section_type"]
				}
				section_header={
					memoContext.memoJSON[props.section_number]["section_header"]
				}
				section_content={
					memoContext.memoJSON[props.section_number]["section_content"]
				}
				custom_prompt={
					memoContext.memoJSON[props.section_number]["custom_prompt"]
				}
				sectionChangeCallback={handleSectionTypeChange}
				sectionWritingCallback={handleSectionWriting}
				deleteSectionCallback={props.deleteSectionCallback}
				sectionLoading={section_loading}
				num_sections={props.num_sections}
				moveSectionUpCallback={props.moveSectionUpCallback}
				moveSectionDownCallback={props.moveSectionDownCallback}
				customPromptChangeCallback={handleCustomPromptChange}
				contentHistory={contentHistory}
				contentHistoryIndex={contentHistoryIndex}
				chatOpen={chatOpen}
				setChatOpen={setChatOpen}
				sectionEditing={section_editing}
				sectionGenerating={section_loading}
				memoType={memoContext.memoType}
			/>
			<SectionChat
				chatOpen={chatOpen}
				setChatOpen={setChatOpen}
				editTextCallback={handleSectionEditing}
				sectionEditing={section_editing}
				sectionNumber={props.section_number}
			/>
			<SectionContent
				section_loading={section_loading}
				section_editing={section_editing}
				section_content={
					memoContext.memoJSON[props.section_number]["section_content"]
				}
				section_number={props.section_number}
			/>
		</div>
	);
}

/**
 * Inputs for the section block
 * @param {*} props
 * @returns {Component} The block of section inputs
 */
export function SectionBlockInputs(props) {
	const memoContext = useContext(MemoContext);
	const dealContext = useContext(DealContext);

	// State
	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

	// Display state
	const [disableTooltipMessage, setDisableTooltipMessage] = useState(null);
	const [disableTooltipColor, setDisableTooltipColor] = useState(null);
	const [disableGenerate, setDisableGenerate] = useState(false);

	// useEffect to update inOptions
	useEffect(
		() => {
			const inOptions = memoContext.memoJSON[props.section_number].inOptions;
			const noDealFiles = !hasUploadedDealFiles(dealContext.files);
			setDisableTooltipMessage(
				!inOptions
					? "Section not available for selected generation model"
					: noDealFiles
					? "Add source files to generate memo content"
					: null
			);
			setDisableTooltipColor(!inOptions ? "#ffbf00" : noDealFiles ? "" : null);
			setDisableGenerate(!inOptions || noDealFiles);
		}, // eslint-disable-next-line react-hooks/exhaustive-deps
		[
			// eslint-disable-next-line react-hooks/exhaustive-deps
			memoContext.memoJSON[props.section_number].inOptions,
			dealContext.files,
			memoContext.sectionTypes,
		]
	);

	return (
		<div className="section-inputs-container">
			<div className="u-flex u-gap-10 u-align-center section-block-inputs">
				<span className="section-block-number">
					Section {props.section_number || ""}
				</span>
				<SectionTypeSelect
					section_number={props.section_number}
					setSectionType={props.sectionChangeCallback}
					section_type={props.section_type}
					section_header={props.section_header}
				/>
				<Tooltip
					title={disableTooltipMessage}
					arrow
					componentsProps={{
						tooltip: {
							sx: {
								bgcolor: disableTooltipColor,
								"& .MuiTooltip-arrow": {
									color: disableTooltipColor,
								},
							},
						},
					}}
					disableHoverListener={!disableTooltipMessage}
				>
					<div>
						<LoadingButton
							variant="contained"
							onClick={props.sectionWritingCallback}
							loading={props.sectionLoading}
							className="generate_button"
							id={"generate_section_" + props.section_number}
							disableElevation
							disabled={disableGenerate}
						>
							<span>Generate</span>
						</LoadingButton>
					</div>
				</Tooltip>
				{props.section_content !== "" && (
					<LoadingButton
						startIcon={<ChatOutlined />}
						onClick={() => props.setChatOpen(!props.chatOpen)}
						sx={
							props.chatOpen ? { backgroundColor: "var(--primary-pale)" } : {}
						}
						variant="outlined"
					>
						Edit
					</LoadingButton>
				)}
				<div className="u-flex-grow-1"></div>
				{
					// If contentHistory is not empty, show history button
					props.contentHistory?.length > 0 && (
						<ContentHistoryButton
							contentHistory={props.contentHistory}
							contentHistoryIndex={props.contentHistoryIndex}
							sectionNumber={props.section_number}
							sectionEditing={props.sectionEditing}
							sectionGenerating={props.sectionGenerating}
						/>
					)
				}
				{
					// If not first section show move up button
					props.section_number > 1 && (
						<IconButton
							onClick={() => props.moveSectionUpCallback(props.section_number)}
						>
							<ArrowDropUp />
						</IconButton>
					)
				}
				{
					// If not last section show move down button
					props.section_number < props.num_sections && (
						<IconButton
							onClick={() =>
								props.moveSectionDownCallback(props.section_number)
							}
						>
							<ArrowDropDown />
						</IconButton>
					)
				}
				{
					// If not first section show delete button
					props.section_number > 1 && (
						<>
							<IconButton
								onClick={() => setConfirmDialogOpen(true)}
								className="delete-section-button"
							>
								<DeleteIcon />
							</IconButton>
							<Dialog
								open={confirmDialogOpen}
								onClose={() => setConfirmDialogOpen(false)}
							>
								<DialogTitle>
									Delete Section {props.section_number}?
								</DialogTitle>
								<DialogActions>
									<Button
										color="secondary"
										onClick={() => setConfirmDialogOpen(false)}
									>
										{" "}
										Cancel{" "}
									</Button>
									<Button
										autoFocus
										color="error"
										onClick={() => {
											props.deleteSectionCallback(props.section_number);
											setConfirmDialogOpen(false);
										}}
									>
										{" "}
										Delete{" "}
									</Button>
								</DialogActions>
							</Dialog>
						</>
					)
				}
			</div>
			{
				// If section_type is custom show custom prompt field
				props.section_type === "custom" && (
					<CustomPromptInput
						sectionNumber={props.section_number}
						customPromptChangeCallback={props.customPromptChangeCallback}
						customPrompt={props.custom_prompt}
					/>
				)
			}
		</div>
	);
}

export function CustomPromptInput({
	sectionNumber,
	customPromptChangeCallback,
	customPrompt,
}) {
	// State
	const [placeholder, setPlaceholder] = useState("");

	// Constants
	const placeholders = [
		"Create a table of the company's management team, with biographies",
		"Describe the company's competitive advantages",
		"Generate a list of the company's top suppliers",
	];

	// useEffect to select a random placeholder on load
	useEffect(() => {
		setPlaceholder(
			`e.g. ${placeholders[Math.floor(Math.random() * placeholders.length)]}`
		);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="custom-prompt-input-container">
			<TextField
				variant="outlined"
				size="small"
				value={customPrompt}
				onChange={(e) =>
					customPromptChangeCallback(sectionNumber, e.target.value)
				}
				id={`custom_prompt_${sectionNumber}`}
				label="Custom Prompt"
				className="custom-prompt-input"
				placeholder={placeholder}
				autoComplete="off"
			/>
		</div>
	);
}

/**
 * A component that allows the user to select a section type.
 * @param {function} props.callback A callback function that is called when the user selects a section type.
 */
export function SectionTypeSelect(props) {
	// State
	const [inOptions, setInOptions] = useState(true);

	// Context
	const memoContext = useContext(MemoContext);

	const customSection = {
		section_header: "Custom",
		section_type: "custom",
	};

	function updateSectionOptions() {
		return [...memoContext.sectionTypes, customSection];
	}

	let options = useMemo(
		() => updateSectionOptions(),
		[memoContext.sectionTypes] // eslint-disable-line react-hooks/exhaustive-deps
	);

	// useEffect to check if section is in options
	useEffect(
		() => {
			// If options haven't loaded yet, return true
			// Prevents false positives before options are loaded
			let inOptions = true;

			// Only check if options have populated
			if (options.length > 1) {
				inOptions =
					props.section_type &&
					options.find(
						(option) => option.section_type === props.section_type
					) !== undefined;
			}

			// Set inOptions in state
			setInOptions(inOptions);

			// Set in memoJSON for use elsewhere
			var newMemoJSON = { ...memoContext.memoJSON };
			newMemoJSON[props.section_number]["inOptions"] = inOptions;
			memoContext.setMemoJSON(newMemoJSON);
		}, // eslint-disable-next-line react-hooks/exhaustive-deps
		[
			props.section_header,
			options,
			memoContext.sectionTypes,
			props.section_number,
			memoContext.memoType,
		]
	);

	const startAdornment = !inOptions && (
		<InputAdornment position="end">
			<Warning sx={{ color: "#ffbf00", mr: 1 }} />
		</InputAdornment>
	);
	const textColor = !inOptions && "#ffbf00";

	return (
		<div className="u-flex section-type-select-container">
			<Select
				className="u-pointer section-type-select"
				onChange={(e) => {
					const sectionHeader = options.find(
						(option) => option.section_type === e.target.value
					).section_header;
					props.setSectionType(e.target.value, sectionHeader);
				}}
				id={"section_header_" + props.section_number}
				value={options.length === 1 ? "" : props.section_type}
				sx={{
					color: textColor,
					fontSize: "1.3rem",
					".MuiOutlinedInput-notchedOutline": { borderStyle: "none" },
				}}
				startAdornment={startAdornment}
				size="small"
			>
				{options.map((option) => (
					<MenuItem key={option.section_type} value={option.section_type}>
						{option.section_header}
					</MenuItem>
				))}
			</Select>
		</div>
	);
}

/**
 * A component that displays the content of a section.
 * @param {string} props.section_content The content of the section.
 * @param {number} props.section_number The number of the section.
 * @param {boolean} props.section_loading Whether or not the section is loading.
 * @param {boolean} props.section_editing Whether or not the section is being edited.
 * @returns {Component} The section content.
 */
export function SectionContent(props) {
	return (
		<div
			id={"section_content_" + props.section_number}
			className="section-content-body"
		>
			{props.section_loading ? (
				<SectionSkeleton animation="wave" />
			) : (
				<SectionParser
					section_content={props.section_content}
					section_editing={props.section_editing}
					section_number={props.section_number}
				/>
			)}
		</div>
	);
}

/**
 * Wrapper to return parsed section content based on format.
 * @param {*} props
 */
export function SectionParser(props) {
	const memoContext = useContext(MemoContext);

	if (memoContext.memoFormat === "docx") {
		return <DocxSectionParser {...props} />;
	} else {
		return <PptxSectionParser {...props} />;
	}
}

/**
 * Parses content to replace placeholders (ie. for images) with actual content.
 * Used for docx formatted memos.
 *
 * @param {string} props.section_content The content to parse.
 * @param {boolean} props.section_editing Whether or not the section is being edited.
 * @returns {Component} The parsed content.
 */
export function DocxSectionParser(props) {
	// Contexts
	const memoContext = useContext(MemoContext);

	// If content is not a string, return content
	if (typeof props.section_content !== "string") {
		return props.section_content;
		// If content is empty, return blank
	} else if (props.section_content === "") {
		return <></>;
	}

	function parseSection(content) {
		var content_array = [];

		// Parse images and return flattened array
		content_array = parseImages(content);

		// Replace source validation placeholders and return to content_array
		content_array = parseSourceValidation(content_array);

		// Map to ReactMarkdown or plain content
		content_array = content_array.map((content, content_idx) => {
			if (content.type === "span") {
				// Extract content
				let span_content = content.props.children;

				return (
					<ReactMarkdown
						remarkPlugins={[remarkGfm]}
						key={`markdown-container-${content_idx}`}
						components={{
							code: ParsedCodeBlock,
						}}
					>
						{span_content}
					</ReactMarkdown>
				);
			} else {
				return content;
			}
		});

		function parseSourceValidation(contentArray) {
			// Regex for source validation placeholders
			// [source:#]$xx million[/source]
			const source_regex = /\[source:(.*?)\](.*?)\[\/source\]/g;

			// Replace source validation placeholders with SourceValidator component
			let newContentArray = [];

			// Go through each element in content array
			contentArray.forEach((content, content_idx) => {
				// If content is a span, replace source validation placeholders
				if (content.type === "span") {
					// Replace source validation placeholders with SourceValidator component
					content = content.props.children.replace(source_regex, (match) => {
						// Get source and content from placeholder
						const source = match.replace(source_regex, "$1");
						const content = match.replace(source_regex, "$2");

						return (
							"```" +
							JSON.stringify({
								type: "source-validation",
								sourceNumber: source,
								content: content,
								sectionNumber: props.section_number,
							}) +
							"```"
						);
					});
					newContentArray.push(<span key={content_idx}>{content}</span>);
				} else {
					newContentArray.push(content);
				}
			});

			// Flatten content array
			newContentArray = newContentArray.flat();

			return newContentArray;
		}

		function parseImages(content) {
			let contentArray = [];

			// Regex for image placeholders
			// Brackets must be balanced
			// const img_regex = /!\[(.*?)\]\((.*?)\)/g;
			// const img_regex = /!\[(.*?)\]\(([^)"]*(?:"[^"]*")?\))/g;
			const img_regex =
				/!\[(.*?)\]\(((?:.*?\([^)]*\))*.*?)\s*(?:"([^"]*)")?\)/g;

			// Replace image placeholders with signed URLs
			var index = 0;

			content.replace(img_regex, (match) => {
				// Get alt and image URL from placeholder
				const imageURL = match.replace(img_regex, "$2");

				const match_index = content.indexOf(match, index);

				// Add content before image placeholder
				const content_before = content.slice(index, match_index);
				contentArray.push(<span key={index}>{content_before}</span>);

				// Get title from image URL
				const encodedTitle = imageURL.split("/").pop();
				const asciiTitle = decodeURIComponent(encodedTitle);
				const title = asciiTitle.split(".")[0];

				// Replace parentheses in fileName with ASCII codes
				const encodedURL = imageURL.replace("(", "%28").replace(")", "%29");

				// Add image object generated from placeholder
				contentArray.push(
					<ChartImage
						url={encodedURL}
						title={title}
						docId={memoContext.memoId}
						key={imageURL}
					/>
				);

				// Update index
				index = match_index + match.length;
			});

			// Add content after last image placeholder
			if (index < content.length) {
				const content_after = content.slice(index);
				contentArray.push(<span key={index}>{content_after}</span>);
			}

			// Flatten content array
			contentArray = contentArray.flat();

			return contentArray;
		}

		return content_array;
	}

	// Return content
	return (
		<div className={props.section_editing ? "section-editing" : ""}>
			{parseSection(props.section_content)}
			{props.section_editing && (
				<div className="section-editing-loading-container">
					<div className="section-editing-loading-content">
						<h3>Editing</h3>
						<CircularProgress />
					</div>
				</div>
			)}
		</div>
	);
}

/**
 * Parses code blocks to display in ReactMarkdown.
 */
export function ParsedCodeBlock({ children }) {
	// State
	const [type, setType] = useState("code");
	const [json, setJSON] = useState({});

	useEffect(() => {
		// Check if content is JSON
		try {
			var parsedContent = JSON.parse(children[0]);

			// If JSON, set type and content
			setType(parsedContent.type);
			setJSON(parsedContent);
		} catch (err) {
			// If not JSON, return content
			console.log(err);

			setType("code");
			setJSON({ content: children[0] });
		}
	}, [children]);

	// Return content based on type
	switch (type) {
		case "source-validation":
			return (
				<SourceValidator
					sourceNumber={json.sourceNumber}
					sectionNumber={json.sectionNumber}
					content={json.content}
				/>
			);
		default:
			return <code>{json.content}</code>;
	}
}

/**
 * Component to display signed images from customer GCP storage bucket.
 */
export function SignedImage({ url, docId }) {
	// State
	const [signedUrl, setSignedUrl] = useState(null);

	useEffect(() => {
		if (url) {
			get(`/api/utils/signed-blob/`, { url: url, docId: docId }).then((res) => {
				setSignedUrl(res.signedUrl);
			});
		}
	}, [url, docId]);

	// If no signedURL, return blank
	if (!signedUrl) return <></>;
	// If signedURL, return image
	return <img src={signedUrl} alt="" />;
}

/**
 * Container component for a chart image in a memo section.
 *
 * @param {string} url The URL of the chart image.
 * @param {string} docId The ID of the memo document.
 * @param {Component} children The children of the component.
 */
export function ChartImage({ url, docId, title }) {
	const [showDownload, setShowDownload] = useState(false);

	// Context
	const memoContext = useContext(MemoContext);

	// useEffect to check if chart data in charts
	useEffect(() => {
		get(`/api/docs/memo/${memoContext.memoId}/chart`, {
			chartTitle: title,
		}).then((res) => {
			if (res.chart) setShowDownload(true);
		});
	}, [title]); // eslint-disable-line react-hooks/exhaustive-deps

	if (!url) return <></>;

	// If url, return image
	return (
		<div className="chart-image">
			<SignedImage url={url} docId={docId} />
			{showDownload && <ChartDownloadButton docId={docId} chartTitle={title} />}
		</div>
	);
}

/** Download button for chart data. */
export function ChartDownloadButton({ docId, chartTitle }) {
	const [downloading, setDownloading] = useState(false);

	// Download chart data
	async function downloadChart() {
		setDownloading(true);
		downloadAPIFile(
			`${process.env.REACT_APP_PYTHON_URL}/pyapi/memo/${docId}/chart?chartTitle=${chartTitle}`,
			chartTitle
		)
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setDownloading(false);
			});
	}

	return (
		<div className="u-flex chart-download-button" onClick={downloadChart}>
			{downloading ? (
				<CircularProgress size="24px" sx={{ color: "#fff", mr: "5px" }} />
			) : (
				<DownloadIcon sx={{ mr: "5px" }} />
			)}{" "}
			{downloading ? "Downloading..." : "Download Excel"}
		</div>
	);
}

/**
 * Parses slide content to display on page.
 * Used for pptx formatted memos.
 *
 * @param {string} props.section_content The content to parse.
 * @param {boolean} props.section_editing Whether or not the section is being edited.
 */
export function PptxSectionParser(props) {
	// Content empty or null return blank
	if (!props.section_content || props.section_content === "") return <></>;

	// If content not empty, try to parse
	let section_content = [
		{
			slide_title: "",
			slide_content: [],
		},
	];

	// If content is already Array, simply replace template
	if (Array.isArray(props.section_content)) {
		section_content = props.section_content;
	} else {
		// If content is string, try to parse
		try {
			section_content = JSON.parse(props.section_content);
		} catch (err) {
			// Do nothing
		}
	}

	return (
		<SlideViewer
			slides={section_content}
			sectionEditing={props.section_editing}
		/>
	);
}

/**
 * A component that displays a skeleton of a section. Intended to imitate a paragraph of text.
 * @param {boolean} props.animation Whether or not to animate the skeleton.
 * @returns {Component} The section skeleton.
 */
export function SectionSkeleton(props) {
	//States
	const memoContext = useContext(MemoContext);
	const memoFormat = memoContext.memoFormat;

	//Word doc skeleton
	if (memoFormat === "docx") {
		return (
			<div className="u-flex u-flex-column u-gap-10">
				<Skeleton
					variant="rectangular"
					height="1em"
					animation={props.animation}
				/>
				<Skeleton
					variant="rectangular"
					height="1em"
					animation={props.animation}
				/>
				<Skeleton
					variant="rectangular"
					height="1em"
					width="55%"
					animation={props.animation}
				/>
				<Skeleton
					variant="rectangular"
					height="1em"
					animation={props.animation}
				/>
				<Skeleton
					variant="rectangular"
					height="1em"
					animation={props.animation}
				/>
				<Skeleton
					variant="rectangular"
					height="1em"
					animation={props.animation}
				/>
				<Skeleton
					variant="rectangular"
					height="1em"
					width="85%"
					animation={props.animation}
				/>
				<Skeleton
					variant="rectangular"
					height="1em"
					animation={props.animation}
				/>
				<Skeleton
					variant="rectangular"
					height="1em"
					animation={props.animation}
				/>
				<Skeleton
					variant="rectangular"
					height="1em"
					animation={props.animation}
				/>
				<Skeleton
					variant="rectangular"
					height="1em"
					width="25%"
					animation={props.animation}
				/>
			</div>
		);
	}
	//Powerpoint skeleton
	else {
		return (
			//Note regular slide height is 425px, 330px feels better visually, compared to having more bullet points which feels cluttered
			<div
				className="u-flex u-flex-column slide margin-auto"
				style={{ height: "330px", textAlign: "justify" }}
			>
				<div className="u-flex u-gap-10">
					<Skeleton
						variant="text"
						width={250}
						height={40}
						padding={"var(--1)"}
						display="flex"
						animation={props.animation}
					/>
				</div>
				<div
					className="u-flex u-gap-10"
					style={{
						borderTop: "1px solid var(--secondary)",
						paddingTop: "5px",
					}}
				>
					<div className="u-flex u-flex-column u-gap-30 flex-child margin-veritcal-auto">
						<Skeleton
							width={25}
							height={25}
							variant="circular"
							animation={props.animation}
						/>
						<Skeleton
							width={25}
							height={25}
							variant="circular"
							animation={props.animation}
						/>
						<Skeleton
							width={25}
							height={25}
							variant="circular"
							animation={props.animation}
						/>
						<Skeleton
							width={25}
							height={25}
							variant="circular"
							animation={props.animation}
						/>
						<Skeleton
							width={25}
							height={25}
							variant="circular"
							animation={props.animation}
						/>
					</div>
					<div className="u-flex u-flex-column u-gap-5">
						<Skeleton
							variant="text"
							width={450}
							height={25}
							padding={"var(--1)"}
							display="flex"
							animation={props.animation}
						/>
						<Skeleton
							variant="text"
							width={400}
							height={25}
							padding={"var(--1)"}
							display="flex"
							animation={props.animation}
						/>
						<Skeleton
							variant="text"
							width={375}
							height={25}
							padding={"var(--1)"}
							display="flex"
							animation={props.animation}
						/>
						<Skeleton
							variant="text"
							width={350}
							height={25}
							padding={"var(--1)"}
							display="flex"
							animation={props.animation}
						/>
						<Skeleton
							variant="text"
							width={400}
							height={25}
							padding={"var(--1)"}
							display="flex"
							animation={props.animation}
						/>
						<Skeleton
							variant="text"
							width={375}
							height={25}
							padding={"var(--1)"}
							display="flex"
							animation={props.animation}
						/>
						<Skeleton
							variant="text"
							width={450}
							height={25}
							padding={"var(--1)"}
							display="flex"
							animation={props.animation}
						/>
						<Skeleton
							variant="text"
							width={300}
							height={25}
							padding={"var(--1)"}
							display="flex"
							animation={props.animation}
						/>
						<Skeleton
							variant="text"
							width={250}
							height={25}
							padding={"var(--1)"}
							display="flex"
							animation={props.animation}
						/>
					</div>
				</div>
			</div>
		);
	}
}

/**
 * Button to download Excel model for given deal
 * @param {string} props.dealId The deal ID.
 * @returns {Component} The download button.
 */
export function ModelDownloadButton(props) {
	function downloadModel(dealId) {
		// Get signed URL from backend
		get(`/api/deal/${dealId}/model`)
			.then((res) => {
				window.location = res.signedURL;
			})
			.catch((err) => {
				alert("Error downloading model");
				console.log(err);
			});
	}

	return (
		<Button
			onClick={() => downloadModel(props.dealId)}
			variant="contained"
			color="excelDoc"
			startIcon={<DownloadIcon />}
			disableElevation
		>
			Download Model
		</Button>
	);
}

/**
 * Interface to "chat" with produced section output.
 * Consists of text field and button to send text to OpenAI API.
 * @param {*} props
 * @param {Boolean} props.chatOpen Whether or not to show the chat interface.
 * @param {function} props.setChatOpen Callback to set chatOpen.
 * @returns {Component} The chat interface.
 */
export function SectionChat(props) {
	const [chatInput, setChatInput] = useState("");

	// Function to handle chat submit
	function handleChatSubmit(event) {
		event.preventDefault();
		props.editTextCallback(chatInput);
	}

	// Constants
	const sectionChatId = "section_" + props.sectionNumber + "_chat_input";

	// Defining chat send button
	const chatButton = (
		<LoadingButton
			onClick={() => {
				props.editTextCallback(chatInput);
			}}
			className="section-chat-button"
			loading={props.sectionEditing}
			disabled={chatInput === ""}
			type="submit"
			endIcon={<SendOutlined />}
		>
			Send
		</LoadingButton>
	);

	// useEffect to clear input when editing changes to false
	useEffect(() => {
		if (!props.sectionEditing) setChatInput("");
	}, [props.sectionEditing]);

	// useEffect to focus on chat input when chat opens
	useEffect(() => {
		if (props.chatOpen) {
			document.querySelector("#" + sectionChatId).focus();
		}
	}, [props.chatOpen, sectionChatId]);

	if (!props.chatOpen) return null;
	return (
		<form
			className="section-chat-container u-flex u-align-center u-gap-10"
			onSubmit={handleChatSubmit}
		>
			<ChatOutlined sx={{ fontSize: 30, color: "var(--primary)" }} />
			<TextField
				value={chatInput}
				size="small"
				onChange={(e) => setChatInput(e.target.value)}
				id={sectionChatId}
				label="Chat to Edit"
				className="section-chat-input u-flex-grow-1"
				InputProps={{
					endAdornment: chatButton,
				}}
				autoComplete="off"
				disabled={props.sectionEditing}
			/>
			<IconButton onClick={() => props.setChatOpen(false)}>
				<Close />
			</IconButton>
		</form>
	);
}

/**
 * Icon button to display content history for a given section
 * @param {Array} props.contentHistory The content history for the section
 */
export function ContentHistoryButton({
	contentHistory,
	contentHistoryIndex,
	sectionNumber,
	sectionEditing,
	sectionGenerating,
}) {
	// State
	const [contentHistoryOpen, setContentHistoryOpen] = useState(false);
	const [contentHistoryAnchor, setContentHistoryAnchor] = useState(null);

	// Context
	const memoContext = useContext(MemoContext);

	const sortedHistory = useMemo(
		() => openAndSortContentHistory(contentHistory),
		[contentHistory]
	);

	// Function to handle opening and sorting content history
	function openAndSortContentHistory(contentHistory) {
		const newContentHistory = [...contentHistory];

		// Convert any date and time strings to an actual date object
		newContentHistory.forEach((content, index) => {
			let contentDate = new Date(content.date);

			// Check if time in contentDate is 00:00:00
			// If so, check if content has date and time
			// If so, create new date object with date and time

			if (content.time) {
				const dateText = content.date + ", 2023 " + content.time;
				contentDate = new Date(dateText);
				content.time = null; // Remove time from content
			}

			content.date = contentDate;
			content.index = index;

			// If no type, set type to generation
			if (!content.type) content.type = "generation";
		});

		// Sort content history by date
		newContentHistory.sort((a, b) => b.date - a.date);

		return newContentHistory;
	}

	// Handles a click on the content history button
	function handleOpenContentHistory(event) {
		setContentHistoryAnchor(event.currentTarget);
		setContentHistoryOpen(true);
	}

	// Handles a click on a content history item
	function handleContentHistoryClick(idx) {
		setContentHistoryOpen(false);

		// Update memoJSON
		var newMemoJSON = { ...memoContext.memoJSON };
		newMemoJSON[sectionNumber]["section_content"] = contentHistory[idx].content;
		newMemoJSON[sectionNumber]["contentHistoryIndex"] = idx;
		memoContext.setMemoJSON(newMemoJSON);
	}

	// If no content history, return empty
	if (!contentHistory) return <></>;

	return (
		<>
			<IconButton onClick={handleOpenContentHistory}>
				<History />
			</IconButton>
			<ContentHistoryMenu
				contentHistoryOpen={contentHistoryOpen}
				setContentHistoryOpen={setContentHistoryOpen}
				contentHistoryAnchor={contentHistoryAnchor}
				contentHistoryIndex={contentHistoryIndex}
				handleContentHistoryClick={handleContentHistoryClick}
				sortedHistory={sortedHistory}
				sectionEditing={sectionEditing}
				sectionGenerating={sectionGenerating}
			/>
		</>
	);
}

export function ContentHistoryMenu({
	contentHistoryOpen,
	setContentHistoryOpen,
	contentHistoryAnchor,
	contentHistoryIndex,
	handleContentHistoryClick,
	sortedHistory,
	sectionEditing,
	sectionGenerating,
}) {
	return (
		<Menu
			open={contentHistoryOpen}
			onClose={() => setContentHistoryOpen(false)}
			anchorEl={contentHistoryAnchor}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "right",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
		>
			<ListSubheader>Section History</ListSubheader>
			{sortedHistory.map((content) => (
				<MenuItem
					key={content.index}
					onClick={() => {
						handleContentHistoryClick(content.index);
					}}
					selected={content.index === contentHistoryIndex}
					disabled={sectionEditing || sectionGenerating}
				>
					<div className="u-flex u-align-center u-gap-15">
						<HistoryEntryPrefix type={content.type} />
						{/* Label/date */}
						<span className="u-flex u-justify-center" style={{ minWidth: 120 }}>
							{content.label
								? content.label
								: `${formatDateWithTime(content.date)}`}
						</span>
						{/* Author */}
						{content.author && (
							<span className="u-flex u-align-center u-justify-center">
								<ListItemIcon sx={{ minWidth: "24px !important", mr: "4px" }}>
									<Person />
								</ListItemIcon>
								{content.author}
							</span>
						)}
					</div>
				</MenuItem>
			))}
			<HistoryDisabledWarning disabled={sectionEditing || sectionGenerating} />
		</Menu>
	);
}

export function HistoryEntryPrefix({ type }) {
	const backgroundColors = {
		edit: "var(--primary-pale)",
		regeneration: "var(--secondary-pale)",
		generation: "var(--primary)",
	};

	const fontColors = {
		generation: "var(--primary-text)",
	};

	return (
		<div
			className="content-history-item-prefix u-flex u-align-center"
			style={{
				minWidth: 130,
				backgroundColor: backgroundColors[type],
				color: fontColors[type],
			}}
		>
			<ListItemIcon sx={{ minWidth: "24px !important", mr: 1 }}>
				{type === "edit" ? (
					<ChatOutlined />
				) : type === "regeneration" ? (
					<AutoMode />
				) : (
					<Flare sx={{ color: fontColors[type] }} />
				)}
			</ListItemIcon>
			<span
				className="u-flex u-justify-center u-flex-grow-1"
				style={{ fontWeight: 500 }}
			>
				{type === "edit"
					? "Edited"
					: type === "regeneration"
					? "Regenerated"
					: "Generated"}
			</span>
		</div>
	);
}

export function HistoryDisabledWarning({ disabled }) {
	// If not disabled, return empty
	if (!disabled) return null;

	return (
		<div className="content-history-disabled-warning-container">
			<div className="content-history-disabled-warning">
				Disabled while generating / editing
			</div>
		</div>
	);
}

export default SectionTypeSelect;
