import "./Footer.css";

export function Footer(props) {
	return (
		<footer className="footer-container u-flex-column">
			<div className="u-flex-grow-1"></div>
			<div className="u-flex-grow-0">© 2023 Deepdive Software Inc.</div>
		</footer>
	);
}

export default Footer;
