import { createContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

// Utilities
import {
	post,
	get,
	useLocalStorage,
	decodeToken,
	checkTokenExpired,
} from "../../utilities";

export const UserContext = createContext({
	user: null,
	userToken: null,
	refreshToken: null,
	refreshingToken: false,
	login: () => {},
	submitMFA: () => {},
	register: () => {},
	logout: () => {},
	logoutWithError: () => {},
	verifyToken: () => {},
	getRefreshedToken: () => {},
	updateUser: () => {},
});

export function UserContextProvider(props) {
	// State
	const [user, setUser] = useLocalStorage("user", null);
	const [userToken, setUserToken] = useLocalStorage("userToken", null);
	const [refreshToken, setRefreshToken] = useLocalStorage("refreshToken", null);

	// Display state
	const [refreshingToken, setRefreshingToken] = useState(false);

	// Hooks
	const navigate = useNavigate();

	// Constants
	const expiryBuffer = 5 * 60; // 5 mins

	function handleRefreshToken() {
		// If userToken is within buffer of expiring, refresh
		if (userToken && checkTokenExpired(userToken, expiryBuffer)) {
			setRefreshingToken(true);
			getRefreshedToken().then(() => {
				setRefreshingToken(false);
			});
		}
	}

	// useEffect to check if userToken is valid and refresh if not
	useEffect(() => {
		// Run immediately
		handleRefreshToken();

		// Check every minute
		const tokenCheckInterval = setInterval(() => {
			handleRefreshToken();
		}, 60 * 1000);

		return () => {
			clearInterval(tokenCheckInterval);
		};
	}, [userToken]); /* eslint-disable-line react-hooks/exhaustive-deps */

	async function login(credentials) {
		var email = credentials.email;
		var password = credentials.password;

		await post("/api/auth/login", {
			email,
			password,
		});
	}

	async function submitMFA(userEmail, mfaCode) {
		var { userToken, refreshToken } = await post("/api/auth/mfa", {
			email: userEmail,
			mfaCode: mfaCode,
		});

		var newUser = decodeToken(userToken);

		if (!checkTokenExpired(userToken)) {
			setUserToken(userToken);
			setRefreshToken(refreshToken);
			setUser(newUser);
			navigate("/");
		}

		return newUser;
	}

	async function register(credentials) {
		const { email, firstName, lastName, password, password2 } = credentials;

		const res = await post("/api/users/complete-registration", {
			email: email,
			firstName: firstName,
			lastName: lastName,
			password: password,
			password2: password2,
		});

		console.log(res);

		let { userToken, refreshToken } = res;

		let newUser = decodeToken(userToken);

		if (!checkTokenExpired(userToken)) {
			setUserToken(userToken);
			setRefreshToken(refreshToken);
			setUser(newUser);
			navigate("/");
		}
	}

	function logout() {
		setUser(null);
		setUserToken(null);
		setRefreshToken(null);
		navigate("/");
	}

	function logoutWithError(errorMessage) {
		setUser(null);
		setUserToken(null);
		setRefreshToken(null);
		navigate("/login?accountError=" + errorMessage);
	}

	// Function to verify token and allow page access
	function verifyToken() {
		// If no token, return false
		if (!userToken) return false;

		try {
			if (checkTokenExpired(userToken)) return false;
		} catch (err) {
			console.log(err);
			return false;
		}

		return true;
	}

	// Function to refresh token
	// Sends existing token to server
	// If valid, returns new token
	async function getRefreshedToken() {
		// If no local token, return false
		if (!refreshToken) return false;

		try {
			let { newUserToken } = await get(`/api/auth/refresh`, {
				refreshToken,
			});

			// If token is invalid, return false
			if (!newUserToken) return false;

			// If token is valid, update userToken and user
			setUserToken(newUserToken);
			setUser(decodeToken(newUserToken));

			return true;
		} catch (err) {
			const { errorMessage, forceLogout } = err.res;

			// If logout is true, log user out with error message
			if (forceLogout) {
				logoutWithError(errorMessage);
			}
		}
	}

	const value = useMemo(
		() => ({
			user,
			userToken,
			refreshToken,
			refreshingToken,
			login,
			submitMFA,
			register,
			logout,
			logoutWithError,
			verifyToken,
			getRefreshedToken,
			updateUser: (newUser) => {
				setUser(newUser);
			},
		}),
		/* eslint-disable-line react-hooks/exhaustive-deps */ [
			user,
			userToken,
			refreshToken,
			refreshingToken,
			getRefreshedToken,
		]
	);

	return (
		<UserContext.Provider value={value}>{props.children}</UserContext.Provider>
	);
}

export default UserContext;
