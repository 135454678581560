import { useState, useEffect, useContext } from "react";
import { Fab, Divider } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
	Add as PlusIcon,
	FileDownload as DownloadIcon,
} from "@mui/icons-material";

// Components
import { SectionBlock } from "./SectionBuilder.js";

// Contexts
import MemoContext from "./contexts/MemoContext.js";

// Utilities
import { post, downloadAPIFile } from "../utilities.js";

// CSS
import "../utilities.css";
import "./BuildMemo.css";

/**
 * UI to build a memo
 * @param {*} props
 * @returns {Component}
 */
function BuildMemo(props) {
	// Memo context
	const memoContext = useContext(MemoContext);

	// State
	const [numSections, setNumSections] = useState(0); // Number of memo sections

	// Update number of sections when memoJSON changes
	useEffect(() => {
		setNumSections(Object.keys(memoContext.memoJSON).length);
	}, [memoContext.memoJSON]);

	// Callbacks for section blocks
	function addSection() {
		var section_number = numSections + 1;
		var newMemoJSON = { ...memoContext.memoJSON };

		// Get first section type from memoContext
		let { section_type, section_header } = memoContext.sectionTypes[0] || {};

		// Add new section
		let newSection = {
			section_header: section_header || "",
			section_type: section_type || "",
			section_content: "",
		};
		newMemoJSON[section_number] = newSection;

		memoContext.setMemoJSON(newMemoJSON);

		// After 0.1 seconds, scroll to new section
		setTimeout(() => {
			document
				.getElementById(`section-${section_number}`)
				.scrollIntoView({ behavior: "smooth" });
		}, 100);
	}

	// Deletes section from memo JSON
	function deleteSection(section_number) {
		var newMemoJSON = { ...memoContext.memoJSON };

		// Delete section from memo JSON
		delete newMemoJSON[section_number];

		// Decrement all sections after deleted section
		for (var i = section_number + 1; i <= numSections; i++) {
			newMemoJSON[i - 1] = newMemoJSON[i];
			delete newMemoJSON[i];
		}

		memoContext.setMemoJSON(newMemoJSON);
	}

	// Moves section up in memo JSON
	function moveSectionUp(section_number) {
		// Swap section with section above it
		var newMemoJSON = { ...memoContext.memoJSON };
		var temp = newMemoJSON[section_number - 1];
		newMemoJSON[section_number - 1] = newMemoJSON[section_number];
		newMemoJSON[section_number] = temp;

		memoContext.setMemoJSON(newMemoJSON);
	}

	// Moves section down in memo JSON
	function moveSectionDown(section_number) {
		// Swap section with section below it
		var newMemoJSON = { ...memoContext.memoJSON };
		var temp = newMemoJSON[section_number + 1];
		newMemoJSON[section_number + 1] = newMemoJSON[section_number];
		newMemoJSON[section_number] = temp;

		memoContext.setMemoJSON(newMemoJSON);
	}

	// Save memo JSON to database when memoJSON changes (if allowed)
	useEffect(() => {
		if (memoContext.allowSaving) saveMemoJSON();
	}, [memoContext.memoJSON]); // eslint-disable-line react-hooks/exhaustive-deps

	// Saves JSON to database
	function saveMemoJSON() {
		post(`/api/docs/memo/${props.memoId}/content`, {
			content: memoContext.memoJSON,
		}).then((res) => {});
	}

	return (
		<div className="build-memo-container u-flex u-flex-column u-gap-15">
			{/* Mapping memoJSON section to SectionBlocks */}
			{[...Array(Object.keys(memoContext.memoJSON).length).keys()].map(
				(index) => {
					return (
						<div key={index} className="section-block-container">
							<SectionBlock
								key={index + 1}
								id={"section-" + (index + 1)}
								section_number={index + 1}
								num_sections={Object.keys(memoContext.memoJSON).length}
								deleteSectionCallback={deleteSection}
								moveSectionUpCallback={moveSectionUp}
								moveSectionDownCallback={moveSectionDown}
								saveMemoJSONCallback={saveMemoJSON}
								dealId={props.dealId}
								memoId={props.memoId}
								generationModel={props.generationModelJSON}
							/>
							{/* Divider if not the last section */}
							{index !== memoContext.memoJSON.length - 1 && <Divider />}
						</div>
					);
				}
			)}
			<Fab
				variant="extended"
				onClick={addSection}
				sx={{ position: "fixed", right: 30, bottom: 20 }}
			>
				<PlusIcon />
				Add Section
			</Fab>
		</div>
	);
}

export function DownloadMemoButton({ memoId, dealId }) {
	// Display state
	const [memoDownloading, setMemoDownloading] = useState(false); // Whether memo is downloading

	// Context
	const memoContext = useContext(MemoContext);

	// Vars
	let memoFormat = memoContext.memoFormat;

	// Generates and downloads an Office document of current memo content
	function generateOfficeDoc() {
		// Set memoFormat
		if (memoFormat === "pptx-hybrid") memoFormat = "pptx"; // hybrid is just pptx for generation
		let memoName = memoContext.title;

		setMemoDownloading(true);
		// Downloads file from API
		downloadAPIFile(
			`${process.env.REACT_APP_PYTHON_URL}/pyapi/memo/${memoId}/${memoFormat}?dealId=${dealId}`,
			memoName
		)
			.then(() => {
				setMemoDownloading(false);
			})
			.catch(() => {
				alert("Error downloading memo.");
				setMemoDownloading(false);
			});
	}

	return (
		<LoadingButton
			variant="contained"
			color={memoFormat === "docx" ? "wordDoc" : "pptDoc"}
			startIcon={<DownloadIcon />}
			className="download-memo-button u-flex-grow-0"
			onClick={generateOfficeDoc}
			loading={memoDownloading}
			disableElevation
			sx={{ flex: "none" }}
		>
			{memoFormat === "docx" ? "Word" : "PowerPoint"}
		</LoadingButton>
	);
}

export default BuildMemo;
