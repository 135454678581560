import { useState, useEffect } from "react";
import {
	Button,
	InputAdornment,
	TextField,
	IconButton,
	Dialog,
	DialogContent,
	DialogActions,
	DialogTitle,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Delete, Tune } from "@mui/icons-material";

// Utilities
import { post, get } from "../utilities.js";

// CSS
import "../utilities.css";
import "./ModelInputs.css";

/**
 * Financial model creation interface.
 * Includes inputs and outputs and download link.
 * @param {JSON} props.modelInputs - Model inputs
 * @returns {JSX.Element} - ModelCreator component
 */
function ModelCreator(props) {
	const [open, setOpen] = useState(false);
	const [modelInputs, setModelInputs] = useState({});

	// Set model inputs based on dealId and get API
	useEffect(() => {
		// Get model inputs from API
		get(`/api/docs/model/${props.memoId}/inputs`).then((response) => {
			// Check if model inputs exist
			if (response.modelInputs) {
				setModelInputs(response.modelInputs);
			}
		});
	}, [props.memoId]);

	// Set input value in state
	function setInputValue(inputName, value) {
		setModelInputs((prevInputs) => {
			let newInputs = { ...prevInputs };
			newInputs[inputName] = value;
			return newInputs;
		});
	}

	// Handle save model inputs
	function handleModelInputsSave() {
		// Close dialog
		setOpen(false);

		// Save model inputs to database
		post(`/api/docs/model/${props.memoId}/inputs`, {
			modelInputs: modelInputs,
		}).then((response) => {
			console.log(response);
		});
	}

	return (
		<>
			<Button
				variant="contained"
				color="secondary"
				onClick={() => setOpen(true)}
				disableElevation
			>
				<Tune /> Inputs
			</Button>
			<Dialog
				sx={{
					"& .MuiDialog-container": {
						"& .MuiPaper-root": {
							width: "100%",
							maxWidth: "700px", // Set your width here
						},
					},
				}}
				open={open}
				onClose={() => setOpen(false)}
			>
				<DialogTitle>Model Inputs</DialogTitle>
				<DialogContent>
					<div className="model-creator-container u-flex-column u-gap-20">
						<ModelInputs
							modelInputs={modelInputs}
							setInputValue={setInputValue}
						/>
						<DebtTranches
							modelInputs={modelInputs}
							setInputValue={setInputValue}
						/>
					</div>
				</DialogContent>
				<DialogActions>
					<Button color="secondary" onClick={() => setOpen(false)}>
						Cancel
					</Button>
					<Button onClick={handleModelInputsSave}>Save</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

/**
 * Inputs component for the financial model
 * @param {int} props.dealId - Deal ID
 * @param {JSON} props.modelInputs - Model inputs
 * @returns {JSX.Element} - ModelInputs component
 */
export function ModelInputs(props) {
	var modelInputs = props.modelInputs;

	return (
		<div className="model-inputs-container u-flex-column u-gap-15">
			<h3>Purchase Information</h3>
			<div className="purchase-price-container u-flex u-gap-20">
				<div className="purchase-multiple-container u-flex-row">
					<div className="purchase-multiple-label">Purchase Multiple</div>
					<TextField
						type="number"
						variant="standard"
						InputProps={{
							inputProps: {
								min: 0,
								step: 0.5,
							},
							endAdornment: (
								<InputAdornment position="end">x EBITDA</InputAdornment>
							),
						}}
						value={modelInputs.purchaseMultiple}
						onChange={(e) =>
							props.setInputValue("purchaseMultiple", e.target.value)
						}
					/>
				</div>
				<div className="purchase-EBITDA-container u-flex-row">
					<div className="purchase-EBITDA-label">Purchase EBITDA</div>
					<TextField
						type="number"
						variant="standard"
						InputProps={{
							startAdornment: <InputAdornment position="end">$</InputAdornment>,
							endAdornment: (
								<InputAdornment position="end">million</InputAdornment>
							),
						}}
						value={modelInputs.purchaseEBITDA}
						onChange={(e) =>
							props.setInputValue("purchaseEBITDA", e.target.value)
						}
					/>
				</div>
				<div className="purchase-year-container u-flex-row">
					<div className="purchase-year-label">Purchase Year</div>
					<TextField
						type="number"
						variant="standard"
						InputProps={{
							inputProps: {
								min: 0,
								step: 1,
							},
						}}
						value={modelInputs.purchaseYear}
						onChange={(e) =>
							props.setInputValue("purchaseYear", e.target.value)
						}
					/>
				</div>
			</div>
		</div>
	);
}

/**
 * Financial model outputs
 * @param {*} props
 * @param {JSON} props.modelInputs - Model inputs
 * @returns
 */
export function ModelOutputs(props) {
	// Passes JSON to backend to generate model
	function generateModel() {
		let modelInputs = JSON.stringify(props.modelInputs);

		// Send JSON to backend
		post("api/model/model", {
			modelInputs: modelInputs,
			memoId: props.memoId,
		}).then((response) => {
			console.log(response);
		});
	}

	return (
		<LoadingButton variant="contained" onClick={generateModel} disableElevation>
			Generate Model
		</LoadingButton>
	);
}

/**
 * Table of debt tranches, editable by user
 * Can add/remove tranches
 * Fields include tranche name, tranche amount (multiple of EBITDA), tranche interest rate, tranche term
 * @param {Object} props.modelInputs - Model inputs
 * @param {function} props.setInputValue - Function to set input value in state
 * @returns {JSX.Element} - DebtTranches component
 */
export function DebtTranches(props) {
	var tranches = props.modelInputs.debtTranches;

	// useEffect if Purchase EBITDA changes
	useEffect(() => {
		// Calculate amount for each tranche
		var newTranches = [...tranches];
		newTranches.forEach((tranche, index) => {
			var amount = tranche.multiple * props.modelInputs.purchaseEBITDA;
			updateTranche(index, "amount", amount);
		});
	}, [props.modelInputs.purchaseEBITDA]); // eslint-disable-line react-hooks/exhaustive-deps

	// Add tranche to table
	function addTranche() {
		var newTranche = {
			name: "Tranche " + (tranches.length + 1),
			multiple: 0,
			amount: 0,
			interestRate: 0,
			amortization: 0,
		};
		props.setInputValue("debtTranches", [...tranches, newTranche]);
	}

	// Delete tranche from table
	function deleteTranche(index) {
		var newTranches = [...tranches];
		newTranches.splice(index, 1);
		props.setInputValue("debtTranches", newTranches);
	}

	// Update tranche[index] field in table
	function updateTranche(index, field, value) {
		var newTranches = [...tranches];
		newTranches[index][field] = value;
		props.setInputValue("debtTranches", newTranches);
	}

	// Handle tranche multiple change
	function handleTrancheMultipleChange(e, index) {
		// Set multiple value in state
		updateTranche(index, "multiple", naturalRound(e));

		// Calculate amount
		var amount = e.target.value * props.modelInputs.purchaseEBITDA;
		updateTranche(index, "amount", amount);
	}

	// Handle tranche amount change
	function handleTrancheAmountChange(e, index) {
		// Set value in state
		updateTranche(index, "amount", e.target.value);

		// Calculate multiple
		var multiple = e.target.value / props.modelInputs.purchaseEBITDA;
		updateTranche(index, "multiple", multiple.toFixed(2));
	}

	// Natural round
	function naturalRound(e) {
		let dec = e.target.value.indexOf(".");
		let tooLong = e.target.value.length > dec + 3;
		let invalidNum = isNaN(parseFloat(e.target.value));

		if ((dec >= 0 && tooLong) || invalidNum) {
			e.target.value = e.target.value.slice(0, -1);
		}
		return e.target.value;
	}

	return (
		<div className="debt-tranches-container u-flex-column">
			<h3>Debt Tranches</h3>
			<div className="debt-tranches-table-container">
				<table className="debt-tranches-table">
					<thead>
						<tr>
							<th align="left">Name</th>
							<th>x EBITDA</th>
							<th>$ Amount</th>
							<th>Interest Rate</th>
							<th>Amortization</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{tranches.map((tranche, index) => (
							<tr key={index}>
								{/* Tranche name */}
								<td>
									<TextField
										variant="standard"
										value={tranche.name}
										onChange={(e) =>
											updateTranche(index, "name", e.target.value)
										}
										sx={{ minWidth: 120 }}
									/>
								</td>
								{/* EBITDA multiple */}
								<td>
									<TextField
										variant="standard"
										type="number"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">x</InputAdornment>
											),
											inputProps: {
												style: {
													textAlign: "right",
												},
												min: 0,
												step: 0.5,
											},
										}}
										value={tranche.multiple}
										onChange={(e) => handleTrancheMultipleChange(e, index)}
									/>
								</td>
								{/* Funded amount */}
								<td>
									<TextField
										variant="standard"
										type="number"
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">$</InputAdornment>
											),
											endAdornment: (
												<InputAdornment position="end">M</InputAdornment>
											),
											inputProps: {
												style: {
													textAlign: "right",
												},
												min: 0,
												step: 1,
											},
										}}
										value={tranche.amount}
										onChange={(e) => handleTrancheAmountChange(e, index)}
									/>
								</td>
								{/* Interest rate */}
								<td>
									<TextField
										variant="standard"
										type="number"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">%</InputAdornment>
											),
											inputProps: {
												style: {
													textAlign: "right",
												},
											},
										}}
										value={(tranche.interestRate * 100).toFixed(2)}
										onChange={(e) =>
											updateTranche(index, "interestRate", e.target.value / 100)
										}
									/>
								</td>
								{/* Amortization */}
								<td>
									{" "}
									<TextField
										variant="standard"
										type="number"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">% / yr</InputAdornment>
											),
											inputProps: {
												style: {
													textAlign: "right",
												},
											},
										}}
										value={(tranche.amortization * 100).toFixed(2)}
										onChange={(e) =>
											updateTranche(index, "amortization", e.target.value / 100)
										}
									/>
								</td>
								{/* Delete button */}
								<td>
									{index === 0 ? (
										<div className="debt-tranche-table-delete-placeholder" />
									) : (
										<IconButton onClick={() => deleteTranche(index)}>
											<Delete />
										</IconButton>
									)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<div className="debt-tranches-add-container u-flex-row">
				<Button className="debt-tranches-add-button" onClick={addTranche}>
					Add Tranche
				</Button>
			</div>
		</div>
	);
}

// TODO today:
// 2. Generate model and output with saved JSON

export default ModelCreator;
