import { useState, useContext, useEffect, useMemo } from "react";
import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Menu,
	MenuList,
	MenuItem,
	ListItemIcon,
	IconButton,
	TextField,
	ListSubheader,
	Collapse,
	Badge,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
	Download,
	Delete,
	Add,
	DriveFolderUpload,
	Http as HTTP,
	PictureAsPdfOutlined,
	TextSnippetOutlined,
	LinkOutlined,
	OpenInBrowser,
	FileOpenOutlined,
	Folder as FolderIcon,
	ExpandLess,
	ExpandMore,
	DescriptionOutlined,
	InsertChartOutlined,
	BallotOutlined,
	Email,
	Lock,
	LockOpen,
	Check,
	Warning,
} from "@mui/icons-material";

// Contexts
import UserContext from "./contexts/UserContext";
import DealContext from "./contexts/DealContext";

// Utilities
import {
	checkFilePassword,
	checkIfLocked,
	del,
	get,
	getFileExtension,
	post,
	unlockFile,
	uploadDealFile,
	truncateFileName,
} from "../utilities";

// CSS
import "./DealFiles.css";

/**
 * Displays all files that have been added to a given deal.
 * @param {int} props.dealId - Deal ID
 */
function DealFiles(props) {
	const dealContext = useContext(DealContext); // Deal information
	const userContext = useContext(UserContext); // User information

	// State
	const [folderStructure, setFolderStructure] = useState({});
	const [lockedFileIds, setLockedFileIds] = useState([]); // [file_id, ...]

	// Display state
	const [dragCounter, setDragCounter] = useState(0);
	const [showPasswordDialog, setShowPasswordDialog] = useState(false);
	const [lockedFilesUnprompted, setLockedFilesUnprompted] = useState(false);

	// Consts
	const dealFiles = dealContext.files.filter(
		(file) => file.status !== "deleted" && file.status !== "deleting"
	);
	const numFiles = dealFiles.length;

	// Fetch deal files for specific dealId from backend
	useEffect(() => {
		if (props.dealId !== null && props.dealId !== undefined) {
			get(`/api/deal/${props.dealId}/deal-files`)
				.then((res) => {
					dealContext.setFiles(res.files);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}, [props.dealId]); // eslint-disable-line react-hooks/exhaustive-deps

	// Function to add multiple deal files from array
	async function addDealFiles(files) {
		// If no files, return
		if (files.length === 0) {
			return;
		}

		let dealFiles = [...dealContext.files];
		// Filter out deleted files
		dealFiles = dealFiles.filter((f) => f.status !== "deleted");

		// Array to contain new file objects
		let newFiles = [];

		// Set up each new file object
		for (const file of files) {
			// Get filename
			let newFileName = file.name;

			// Give each file a new ID (max + 1)
			let newFileId = 0;
			if (dealFiles.length > 0) {
				let maxFileId = Math.max(...dealFiles.map((f) => f.file_id));
				newFileId = maxFileId + 1;
			}

			// Check relative path for folder uploads
			let subfolders = [];
			if (file.webkitRelativePath) {
				// Break up path into folders, remove file name
				subfolders = file.webkitRelativePath.split("/");
				subfolders.pop(); // Remove file name
			}

			// Set up file path (ie: /data/dealId/sources/fileName)
			let filePath = `${props.dealId}/sources/${newFileName}`;

			// Set up new file object
			let newFileJSON = {
				file_id: newFileId,
				file_path: filePath,
				file_name: newFileName,
				file_type: "." + getFileExtension(newFileName),
				status: "uploading",
				subfolders: subfolders,
			};

			// Add file to files array
			dealFiles.push(newFileJSON);

			// Update files array
			newFiles.push(newFileJSON);
		}

		// Update files array
		dealContext.setFiles(dealFiles);

		let newFileIndex = 0;

		// Reset locked file IDs
		setLockedFileIds([]);

		// Add each file to deal
		for (const file of files) {
			let newFileJSON = newFiles[newFileIndex];

			// Add files asynchronously
			addDealFile(file, null, newFileJSON).then((res) => {
				// If file was added successfully, update file status
				if (!res.locked) {
					// Get file ID from JSON
					let newFileId = newFileJSON.file_id;

					setFileStatus(dealFiles, newFileId, null);
					// Update files array
					dealContext.setFiles(dealFiles);
				} else {
					// Get file ID from JSON
					let newFileId = newFileJSON.file_id;
					setFileStatus(dealFiles, newFileId, "locked");

					// Add to locked file IDs
					setLockedFileIds((oldLockedFileIds) => [
						...oldLockedFileIds,
						newFileId,
					]);

					// If one file is locked, show password dialog
					// Additional files will populate as they are added
					if (!showPasswordDialog) {
						setLockedFilesUnprompted(true);
						setShowPasswordDialog(true);
					}
				}
			});

			newFileIndex = newFileIndex + 1;
		}
	}

	// Add file to deal from file name
	// Returns new file object
	async function addDealFile(file, URL = null, fileJSON = null) {
		// If no dealId, get one from backend
		let dealId = props.dealId;
		if (dealId === null || dealId === undefined) {
			const res = await post("/api/deal", {
				author: userContext.user.name,
			});
			dealId = res.dealId;
		}

		// File upload
		if (!URL && file) {
			const isLocked = await checkIfLocked(file);
			if (isLocked) {
				// Set file to locked and password to "" in fileJSON
				fileJSON.status = "locked";
				fileJSON.password = "";

				await uploadDealFile(file, `/api/deal/${dealId}/deal-file`, fileJSON);

				return { file: file, locked: true, password: "" };
			} else {
				// Add file to deal folder
				await uploadDealFile(file, `/api/deal/${dealId}/deal-file`, fileJSON);
			}
		} else {
			let dealFiles = [...dealContext.files];
			dealFiles = dealFiles.filter((f) => f.status !== "deleted");

			// Get new file ID
			let newFileId = 0;
			if (dealFiles.length > 0) {
				let maxFileId = Math.max(...dealFiles.map((f) => f.file_id));
				newFileId = maxFileId + 1;
			}

			// Set up new file object
			let newFileJSON = {
				file_id: newFileId,
				file_path: URL,
				file_name: file.name,
				file_type: ".url",
				status: "uploading",
				url: URL,
			};

			dealFiles = [...dealFiles, newFileJSON];
			dealContext.setFiles(dealFiles);

			await post(`/api/deal/${dealId}/deal-file-url`, {
				url: URL,
				fileJSON: newFileJSON,
			}).then((res) => {
				// Update file with page_title from response
				newFileJSON.page_title = res.file.page_title;
				let newDealFiles = [...dealContext.files];
				let fileIndex = newDealFiles.findIndex((f) => f.file_id === newFileId);
				newDealFiles[fileIndex] = newFileJSON;
				dealContext.setFiles(newDealFiles);

				if (res.success) {
					setFileStatus(dealFiles, newFileId, null);
				} else {
					deleteDealFile(file);
				}
			});
		}

		return { file: file, locked: false };
	}

	// Delete file from deal (just frontend)
	function deleteDealFile(fileId) {
		let files = [...dealContext.files];
		setFileStatus(files, fileId, "deleting");

		// Delete file from backend
		del(`/api/deal/${props.dealId}/deal-file/${fileId}`)
			.then((res) => {
				if (res.success) {
					setFileStatus(files, fileId, "deleted");
				}
			})
			.catch((err) => {
				console.log(err);
				setFileStatus(files, fileId, null);
			});
	}

	// Set file status
	function setFileStatus(files, fileId, status) {
		let newFiles = [...files];

		// Check if file exists before doing anything
		if (newFiles.find((f) => f.file_id === fileId)) {
			// Update status
			let fileIndex = newFiles.findIndex((f) => f.file_id === fileId);
			newFiles[fileIndex].status = status;
			dealContext.setFiles(newFiles);
		}
	}

	// Handle drag over
	function handleDragOver(event) {
		event.preventDefault();
	}

	// Handle drop
	function handleDrop(event) {
		event.preventDefault();
		event.stopPropagation();

		// Reset dragging
		setDragCounter(0);

		const files = [...event.dataTransfer.files];

		if (files && files.length > 0) {
			// Add each file to deal
			addDealFiles(files);
		}
	}

	// Handle drag enter
	function handleDragEnter(event) {
		event.preventDefault();
		event.stopPropagation();

		setDragCounter((prevCounter) => prevCounter + 1);
	}

	// Handle drag leave
	function handleDragLeave(event) {
		event.preventDefault();
		event.stopPropagation();

		setDragCounter((prevCounter) => prevCounter - 1);
	}

	// Get folder structure from files
	function getFolderStructure(files) {
		let folderStructure = {};

		files.forEach((file) => {
			let subfolders = file.subfolders || [];
			let currentFolder = folderStructure;

			// Add each subfolder to folder structure
			subfolders.forEach((subfolder) => {
				if (!currentFolder[subfolder]) {
					currentFolder[subfolder] = {};
				}
				currentFolder = currentFolder[subfolder];
			});

			// Add file to folder structure
			currentFolder[file.file_id] = file;
		});

		return folderStructure;
	}

	// useEffect to update folder structure
	useEffect(() => {
		let folderStructure = getFolderStructure(dealContext.files);
		setFolderStructure(folderStructure);
	}, [dealContext.files]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div
			id="upload-files-container"
			className={
				"upload-files-container u-flex-column" +
				(dragCounter !== 0 ? " dragging" : "") +
				(props.showBackground ? " show-background" : "")
			}
			onDragOver={handleDragOver}
			onDrop={handleDrop}
			onDragEnter={handleDragEnter}
			onDragLeave={handleDragLeave}
		>
			<div className="u-flex u-align-center deal-files-header">
				<h3>Source Files{numFiles > 0 ? ` (${numFiles})` : null}</h3>
				<UnlockFilesButton
					dealId={props.dealId}
					setShowPasswordDialog={setShowPasswordDialog}
					setLockedFileIds={setLockedFileIds}
				/>
				<div className="u-flex-grow-1" />
				<AddFileButton
					dealId={props.dealId}
					addDealFile={addDealFile}
					addDealFiles={addDealFiles}
					setFileStatus={setFileStatus}
					deleteDealFile={deleteDealFile}
				/>
			</div>
			{
				// If no dealId, display message
				!props.dealId && (
					<MenuItem disabled>Select a deal to add or view files</MenuItem>
				)
			}
			{props.dealId && dealFiles.length === 0 && (
				<MenuItem disabled>Add files to get started</MenuItem>
			)}
			<MenuList>
				{/* Folders and subfolders first */}
				{Object.keys(folderStructure).map((folder, index) =>
					// If has file_id, is a file; display file
					folderStructure[folder].file_id !== undefined ? (
						<DealFile
							file={folderStructure[folder]}
							dealId={props.dealId}
							key={folderStructure[folder].file_id}
							deleteDealFile={deleteDealFile}
							setFileStatus={setFileStatus}
							maxFileNameLength={props.maxFileNameLength}
						/>
					) : (
						// Otherwise, is a folder; display folder
						<DealFolder
							folderName={folder}
							folderPath={[folder]}
							contents={folderStructure[folder]}
							dealId={props.dealId}
							key={index}
							deleteDealFile={deleteDealFile}
							setFileStatus={setFileStatus}
							maxFileNameLength={props.maxFileNameLength}
						/>
					)
				)}
			</MenuList>
			<PasswordDialog
				open={showPasswordDialog}
				setOpen={setShowPasswordDialog}
				dealId={props.dealId}
				fileIds={lockedFileIds}
				setFileStatus={setFileStatus}
				unprompted={lockedFilesUnprompted}
			/>
		</div>
	);
}

/**
 * A folder that has been added to the deal.
 * @param {object} props.folder - Folder object
 * @param {int} props.dealId - Deal ID
 */
export function DealFolder({
	folderName,
	folderPath,
	contents,
	dealId,
	level = 0,
	deleteDealFile,
	setFileStatus,
}) {
	// All contained file IDs
	const [fileIds, setFileIds] = useState([]);

	// Display state
	const [open, setOpen] = useState(false);
	const [uploading, setUploading] = useState(false);
	const [menuAnchor, setMenuAnchor] = useState(null);

	// Contexts
	const dealContext = useContext(DealContext);

	// Match all file IDs to context where subfolders match folderPath
	useEffect(() => {
		let containedFiles = dealContext.files;

		// Filter out deleted files
		containedFiles = containedFiles.filter(
			(f) => f.status !== "deleted" && f.status !== "deleting"
		);

		// Filter out files that don't match folderPath
		containedFiles = containedFiles.filter((f) => {
			if (f.subfolders) {
				return f.subfolders.join("/") === folderPath.join("/");
			}
			return false;
		});

		// Set file IDs
		setFileIds(containedFiles.map((f) => f.file_id));

		// Set uploading state
		setUploading(containedFiles.some((f) => f.status === "uploading"));
	}, [dealContext.files]); // eslint-disable-line react-hooks/exhaustive-deps

	// Handle menu opening/closing
	function handleMenu(anchor) {
		if (menuAnchor) {
			setMenuAnchor(null);
		} else if (!uploading) {
			setMenuAnchor(anchor);
		}
	}

	// If no files in folder, return null
	if (fileIds.length === 0) {
		return null;
	}

	// Display folder and contents
	return (
		<div>
			<MenuItem
				className="deal-file u-flex u-align-center u-gap-15 folder"
				onClick={() => setOpen(!open)}
				onContextMenu={(event) => {
					event.preventDefault();
					event.stopPropagation();

					handleMenu(event.currentTarget);
				}}
				sx={level > 0 ? { pl: level * 4 } : {}} // Indent based on level
			>
				{uploading ? (
					<CircularProgress color="inherit" size="20px" />
				) : (
					<FolderIcon />
				)}
				{folderName}
				<div className="u-flex-grow-1" />
				{open ? <ExpandLess /> : <ExpandMore />}
			</MenuItem>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<MenuList>
					{Object.keys(contents).map((folder, index) =>
						// If has file_id, is a file; display file
						contents[folder].file_id !== undefined ? (
							<DealFile
								file={contents[folder]}
								dealId={dealId}
								key={contents[folder].file_id}
								level={level + 1}
								deleteDealFile={deleteDealFile}
								setFileStatus={setFileStatus}
							/>
						) : (
							// Otherwise, is a folder; display folder
							<DealFolder
								folderName={folder}
								folderPath={[...folderPath, folder]}
								contents={contents[folder]}
								dealId={dealId}
								key={index}
								level={level + 1}
								deleteDealFile={deleteDealFile}
								setFileStatus={setFileStatus}
							/>
						)
					)}
				</MenuList>
			</Collapse>
			<FolderMenu
				folderName={folderName}
				folderPath={folderPath}
				contents={contents}
				deleteDealFile={deleteDealFile}
				menuAnchor={menuAnchor}
				handleMenu={handleMenu}
			/>
		</div>
	);
}

/** Folder menu to delete */
export function FolderMenu({
	folderName,
	folderPath,
	contents,
	deleteDealFile,
	menuAnchor,
	handleMenu,
}) {
	// Contexts
	const dealContext = useContext(DealContext);

	const [showDeleteDialog, setShowDeleteDialog] = useState(false);

	function handleDialogClick(e, option) {
		if (option === "delete") {
			let folderPathStr = folderPath.join("/");

			// Delete all files where subfolders begin with folderPath
			dealContext.files.forEach((file) => {
				if (
					file.subfolders &&
					file.subfolders.join("/").startsWith(folderPathStr)
				) {
					deleteDealFile(file.file_id);
				}
			});
		}
		setShowDeleteDialog(false);
	}

	return (
		<>
			<Menu
				anchorEl={menuAnchor}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				keepMounted
				transformOrigin={{ vertical: "top", horizontal: "right" }}
				open={Boolean(menuAnchor)}
				onClose={() => handleMenu()}
				disableAutoFocusItem
			>
				<MenuItem disabled>{folderName}</MenuItem>
				<MenuItem
					onClick={() => {
						setShowDeleteDialog(true);
						handleMenu();
					}}
				>
					<ListItemIcon>
						<Delete />
					</ListItemIcon>{" "}
					Delete
				</MenuItem>
			</Menu>
			<Dialog
				open={showDeleteDialog}
				onClose={() => {
					setShowDeleteDialog(false);
					handleMenu();
				}}
			>
				<DialogTitle>Delete {folderName} and all contents?</DialogTitle>
				<DialogActions>
					<Button
						onClick={(e) => handleDialogClick(e, "cancel")}
						color="secondary"
					>
						Cancel
					</Button>
					<Button
						color="error"
						onClick={(e) => {
							handleDialogClick(e, "delete");
						}}
					>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

/**
 * A file that has been added to the deal.
 *
 * @param {object} props.file - File object
 * @param {int} props.dealId - Deal ID
 * @param {function} props.deleteDealFile - Callback to delete file from deal
 * @param {function} props.setFileStatus - Callback to set file status
 */
export function DealFile(props) {
	const [menuAnchor, setMenuAnchor] = useState(null);

	// Handle menu opening/closing
	function handleMenu(anchor) {
		if (menuAnchor) {
			setMenuAnchor(null);
		} else if (props.file.status !== "uploading") {
			setMenuAnchor(anchor);
		}
	}

	// If status is "deleted" or "deleting", return null
	if (props.file.status === "deleted" || props.file.status === "deleting") {
		return null;
	}

	return (
		<>
			<MenuItem
				className={
					"deal-file u-flex u-align-center u-gap-15 " + props.file.status
				}
				onClick={(event) => {
					handleMenu(event.currentTarget);
				}}
				onContextMenu={(event) => {
					event.preventDefault();
					event.stopPropagation();

					handleMenu(event.currentTarget);
				}}
				sx={props.level > 0 ? { pl: props.level * 4 } : {}} // Indent based on level
			>
				<FileName file={props.file} maxLength={props.maxFileNameLength} />
			</MenuItem>
			<FileMenu
				file={props.file}
				dealId={props.dealId}
				anchor={menuAnchor}
				deleteDealFile={props.deleteDealFile}
				handleMenu={handleMenu}
				setFileStatus={props.setFileStatus}
				maxFileNameLength={props.maxFileNameLength}
			/>
		</>
	);
}

/**
 * File name display with icon. Will use webpage title for URL, file name for PDF, TXT.
 */
export function FileName({ file, maxLength }) {
	// Remove file extension from file name
	function formatFileName(fileName) {
		if (!fileName) return;

		fileName = fileName.split(".").slice(0, -1).join("."); // Remove file extension
		fileName = truncateFileName(fileName, maxLength); // Truncate file name if too long
		return fileName;
	}

	const fileName = useMemo(
		() => formatFileName(file.file_name),
		[file.file_name] // eslint-disable-line react-hooks/exhaustive-deps
	);

	const fileTitle = useMemo(
		() => truncateFileName(file.page_title, maxLength),
		[file.page_title] // eslint-disable-line react-hooks/exhaustive-deps
	);

	return (
		<div className="u-flex u-align-center u-gap-5">
			<FileIcon file={file} />
			{file.page_title ? fileTitle : fileName}
		</div>
	);
}

/**
 * File icon based on file type. Will use MUI icons for PDF, TXT, but favicon for URL.
 */
export function FileIcon({ file }) {
	const FILE_ICONS = {
		pdf: <PictureAsPdfOutlined sx={{ color: "red" }} />,
		txt: <TextSnippetOutlined />,
		url: getFavicon(file.url),
		docx: <DescriptionOutlined sx={{ color: "#185ABD" }} />,
		xlsx: <InsertChartOutlined sx={{ color: "#107C41" }} />,
		xls: <InsertChartOutlined sx={{ color: "#107C41" }} />,
		xlsm: <InsertChartOutlined sx={{ color: "#107C41" }} />,
		pptx: <BallotOutlined sx={{ color: "#C43E1C" }} />,
		eml: <Email sx={{ color: "#FFD700" }} />,
	};

	// Function to get favicon from URL; if not found, return LinkOutlined
	function getFavicon(url) {
		// If no URL, return LinkOutlined
		if (!url) {
			return <LinkOutlined />;
		}

		// Get base URL (ie: google.com)
		url = new URL(url);
		let baseURL = url.origin;
		let faviconURL = baseURL + "/favicon.ico";

		// Return favicon
		return (
			<img
				src={faviconURL}
				alt={baseURL}
				className="file-icon"
				onError={(e) => {
					e.target.onerror = null;
					e.target.src = "/favicon.ico";
				}}
				width="24px"
			/>
		);
	}

	let fileIcon = null;

	// If locked, return lock icon
	if (file.status === "locked") {
		fileIcon = <Lock />;
	}
	// If unlocked, return unlocked icon
	else if (file.status === "unlocked") {
		fileIcon = <LockOpen />;
	}
	// If not undefined or null, is loading; return loading icon
	else if (file.status !== undefined && file.status !== null) {
		fileIcon = <CircularProgress color="inherit" size="20px" />;
	}
	// If file type is null or undefined, return null
	else if (file.file_type === null || file.file_type === undefined) {
		fileIcon = null;
	}
	// If file type is in FILE_ICONS, return icon
	else if (file.file_type.replace(".", "").toLowerCase() in FILE_ICONS) {
		fileIcon = <>{FILE_ICONS[file.file_type.replace(".", "").toLowerCase()]}</>;
	}
	// Otherwise, return file extension in text
	else {
		fileIcon = (
			<div className="file-icon-text">{file.file_type.replace(".", "")}</div>
		);
	}

	return <div className="file-icon">{fileIcon}</div>;
}

/**
 * Button to add a file to the deal.
 * @param {int} props.dealId - Deal ID
 * @param {function} props.addDealFile - Callback to add file to deal
 * @param {function} props.setFileStatus - Callback to set file status
 * @param {function} props.deleteDealFile - Callback to delete file from deal
 */
export function AddFileButton(props) {
	const [menuAnchor, setMenuAnchor] = useState(null);

	/**
	 * Takes the user's chosen local file and uploads it to the server.
	 * @param {*} event
	 */
	function handleFileChoice(event) {
		// Add each file to deal
		props.addDealFiles(event.target.files);

		// Clear file input
		event.target.value = "";
	}

	// Handle folder choice
	function handleFolderChoice(event) {
		// Get files
		let files = event.target.files;

		// Add each file to deal
		props.addDealFiles(files);
	}

	// Handle add file button click
	function handleAddClick(event) {
		if (menuAnchor) {
			setMenuAnchor(null);
		} else {
			setMenuAnchor(event.currentTarget);
		}
	}

	return (
		<>
			<IconButton onClick={handleAddClick} disabled={!props.dealId}>
				<Add />
			</IconButton>
			<AddFileMenu
				dealId={props.dealId}
				handleFileChoice={handleFileChoice}
				handleFolderChoice={handleFolderChoice}
				anchor={menuAnchor}
				handleAddClick={handleAddClick}
				addDealFile={props.addDealFile}
				setFileStatus={props.setFileStatus}
				deleteDealFile={props.deleteDealFile}
			/>
		</>
	);
}

/**
 * Add file menu with options to add from disk or URL
 * @param {int} props.dealId - Deal ID
 * @param {function} props.handleFileChoice - Callback to handle file choice
 * @param {function} props.handleFolderChoice - Callback to handle folder choice
 * @param {object} props.anchor - Anchor element for menu
 * @param {function} props.handleAddClick - Callback to handle add button click
 * @param {function} props.addFileCallback - Callback to add file to deal
 */
export function AddFileMenu(props) {
	const [urlDialogOpen, setUrlDialogOpen] = useState(false);
	const [url, setUrl] = useState("");

	// Menu items
	const singleFileMenuItems = ["From Disk", "From Link"];
	const singleFileMenuIcons = {
		"From Disk": <FileOpenOutlined />,
		"From Link": <HTTP />,
	};

	const folderMenuItems = ["Data Room"];
	const folderMenuIcons = {
		"Data Room": <DriveFolderUpload />,
	};

	function handleCloseMenu(item) {
		// Single file upload
		if (item === "From Disk") {
			document.getElementById("new-file-upload").click();
		} else if (item === "From Link") {
			setUrl("");
			setUrlDialogOpen(true);
			// Focus on URL input
			setTimeout(() => {
				document.getElementById("file-url-input").focus();
			}, 100);
		}
		// Folder upload
		else if (item === "Data Room") {
			document.getElementById("new-folder-upload").click();
		}

		props.handleAddClick(); // Close menu
	}

	function handleURLDialogClose() {
		setUrlDialogOpen(false);
	}

	function handleURLSubmission() {
		let localURL = url.trim();

		// If no prefix, add https://
		if (!localURL.startsWith("http://") && !localURL.startsWith("https://")) {
			localURL = "https://" + localURL;
		}

		// Validate entire url
		if (!localURL.match(/^(http|https):\/\/[^ "]+$/)) {
			alert("Invalid URL");
			return;
		}

		// Get base URL (ie: google.com)
		let baseURL = localURL.split("/")[2];

		// Add .txt extension to URL for fileName
		let file = { name: baseURL + ".txt" };

		// Close window and let animation play out in files area
		setUrlDialogOpen(false);

		// Add file to deal
		props.addDealFile(file, localURL);
	}

	return (
		<Menu
			id={"add-file-menu"}
			anchorEl={props.anchor}
			anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
			keepMounted
			transformOrigin={{ vertical: "top", horizontal: "right" }}
			onClose={handleCloseMenu}
			open={Boolean(props.anchor)}
		>
			{/* Single file upload */}
			<ListSubheader>Upload File</ListSubheader>
			{singleFileMenuItems.map((item) => (
				<MenuItem key={item} onClick={() => handleCloseMenu(item)}>
					<ListItemIcon>{singleFileMenuIcons[item]}</ListItemIcon> {item}
				</MenuItem>
			))}

			{/* Folder upload */}
			<ListSubheader>Upload Folder</ListSubheader>
			{folderMenuItems.map((item) => (
				<MenuItem key={item} onClick={() => handleCloseMenu(item)}>
					<ListItemIcon>{folderMenuIcons[item]}</ListItemIcon> {item}
				</MenuItem>
			))}

			{/* URL upload dialog */}
			<Dialog open={urlDialogOpen} onClose={handleURLDialogClose}>
				<DialogTitle>Enter Link Address</DialogTitle>
				<DialogContent>
					<TextField
						id="file-url-input"
						variant="outlined"
						type="url"
						value={url}
						onChange={(event) => setUrl(event.target.value)}
						sx={{ minWidth: 450 }}
						autoComplete="off"
						placeholder="https://example.com/..."
					></TextField>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleURLDialogClose} color="secondary">
						Cancel
					</Button>
					<Button onClick={handleURLSubmission}>Add</Button>
				</DialogActions>
			</Dialog>
			{/* Single file upload dialog */}
			<input
				type="file"
				accept=".pdf,.txt,.csv,.docx,.xlsx,.xlsm,.pptx,.eml"
				id="new-file-upload"
				multiple="multiple"
				hidden
				onChange={(event) => props.handleFileChoice(event)}
			/>
			{/* Folder upload dialog */}
			<input
				type="file"
				webkitdirectory="true"
				directory
				id="new-folder-upload"
				hidden
				onChange={(event) => props.handleFolderChoice(event)}
			/>
		</Menu>
	);
}

/**
 * File menu with options to download or delete the file
 * @param {object} props.file - File object
 * @param {int} props.dealId - Deal ID
 * @param {function} props.deleteDealFile - Callback to delete file from deal
 * @param {object} props.anchor - Anchor element for menu
 */
export function FileMenu(props) {
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [showPasswordDialog, setShowPasswordDialog] = useState(false);

	const menuItems = props.file.url
		? ["Open Page", "Delete"]
		: props.file.status === "locked"
		? ["Enter Password", "Download", "Delete"]
		: ["Download", "Delete"];
	const menuIcons = {
		Download: <Download />,
		Delete: <Delete />,
		"Open Page": <OpenInBrowser />,
		"Enter Password": <LockOpen />,
	};

	async function handleCloseMenu(item) {
		if (item === "Download") {
			// Download file
			let { signedURL } = await get(`/api/deal/${props.dealId}
				/deal-file/${props.file.file_id}`);
			window.location = signedURL;
		} else if (item === "Delete") {
			setShowDeleteDialog(true);
		} else if (item === "Open Page") {
			window.open(props.file.url, "_blank");
		} else if (item === "Enter Password") {
			setShowPasswordDialog(true);
		}
		props.handleMenu(); // Close menu
	}

	// function to handle Dialog click
	function handleDialogClick(e, option) {
		e.preventDefault();

		if (option === "Delete") {
			props.deleteDealFile(props.file.file_id);
		}
		setShowDeleteDialog(false);
	}

	return (
		<>
			<Menu
				id={"file-menu-" + props.file.file_id}
				anchorEl={props.anchor}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				keepMounted
				transformOrigin={{ vertical: "top", horizontal: "right" }}
				onClose={props.handleMenu}
				open={Boolean(props.anchor)}
				disableAutoFocusItem
			>
				<MenuItem disabled>
					{props.file.page_title
						? truncateFileName(props.file.page_title, props.maxFileNameLength)
						: truncateFileName(props.file.file_name, props.maxFileNameLength)}
				</MenuItem>
				{menuItems.map((item) => (
					<MenuItem key={item} onClick={() => handleCloseMenu(item)}>
						<ListItemIcon>{menuIcons[item]}</ListItemIcon> {item}
					</MenuItem>
				))}
			</Menu>
			<Dialog
				open={showDeleteDialog}
				onClose={() => setShowDeleteDialog(false)}
			>
				<DialogTitle>
					Delete{" "}
					{props.file.page_title ? props.file.page_title : props.file.file_name}
					?
				</DialogTitle>
				<DialogActions>
					<Button
						onClick={(e) => handleDialogClick(e, "cancel")}
						color="secondary"
					>
						Cancel
					</Button>
					<Button
						color="error"
						onClick={() => {
							setShowDeleteDialog(false);
							props.deleteDealFile(props.file.file_id);
						}}
					>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
			<PasswordDialog
				open={showPasswordDialog}
				setOpen={setShowPasswordDialog}
				dealId={props.dealId}
				fileIds={[props.file.file_id]}
				setFileStatus={props.setFileStatus}
			/>
		</>
	);
}

export function PasswordDialog({
	open,
	setOpen,
	dealId,
	fileIds,
	setFileStatus,
	unprompted = false,
}) {
	// State
	const [passwords, setPasswords] = useState({});
	const [files, setFiles] = useState([]);

	// Display state
	const [passwordErrors, setPasswordErrors] = useState({});
	const [passwordLoading, setPasswordLoading] = useState({});
	const [passwordCorrect, setPasswordCorrect] = useState({});

	// Context
	const dealContext = useContext(DealContext);

	function handlePasswordDialogClose() {
		setPasswords({});
		setOpen(false);
	}

	async function handleDialogSubmission() {
		setPasswords({});
		setOpen(false);
	}

	// Handle password change
	function handlePasswordChange(file, newPassword) {
		// Change password in state
		let newPasswords = { ...passwords };
		newPasswords[file.file_id] = newPassword;
		setPasswords(newPasswords);

		// Clear password error
		let newErrors = { ...passwordErrors };
		newErrors[file.file_id] = false;
		setPasswordErrors(newErrors);
	}

	// Submit password for check
	async function submitPassword(file) {
		// Set loading
		setPasswordLoading({ ...passwordLoading, [file.file_id]: true });

		const password = passwords[file.file_id];

		// Check password
		const unlocked = await checkFilePassword(file.file_id, dealId, password);

		// Set loading
		setPasswordLoading({ ...passwordLoading, [file.file_id]: false });

		// If unlocked, then set file status to unlocked and store password
		if (unlocked) {
			// Clear password error
			let newErrors = { ...passwordErrors };
			newErrors[file.file_id] = false;
			setPasswordErrors(newErrors);

			// Set password correct
			let newCorrect = { ...passwordCorrect };
			newCorrect[file.file_id] = true;
			setPasswordCorrect(newCorrect);

			setFileStatus(dealContext.files, file.file_id, "unlocked");

			// Update file with password
			let newFiles = [...dealContext.files];

			// Find file index
			let fileIndex = newFiles.findIndex((f) => f.file_id === file.file_id);

			// Update file with password
			newFiles[fileIndex].password = password;

			// Update files array
			dealContext.setFiles(newFiles);

			// Unlock file
			setFileStatus(dealContext.files, file.file_id, "uploading");
			unlockFile(dealId, file).finally(() => {
				setFileStatus(dealContext.files, file.file_id, null);
			});
		} else {
			// Set password error
			let newErrors = { ...passwordErrors };
			newErrors[file.file_id] = true;
			setPasswordErrors(newErrors);
		}
	}

	// useEffect to set files from fileIds
	useEffect(() => {
		let newFiles = dealContext.files.filter((file) =>
			fileIds.includes(file.file_id)
		);

		// Set files
		setFiles(newFiles);
	}, [fileIds]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Dialog open={open} onClose={handlePasswordDialogClose} maxWidth="md">
			<DialogTitle className="u-flex u-align-center">
				<Warning color="warning" sx={{ mr: 1 }} />
				{fileIds.length > 1 ? "Enter Passwords" : "Enter Password"}
			</DialogTitle>
			<DialogContent>
				<div style={{ marginBottom: 10 }}>
					{unprompted
						? "One or more files you are uploading are password protected. Please unlock them below."
						: null}
				</div>
				<table cellPadding={4}>
					{files.map((file) => (
						<tr>
							<td width={400}>
								<FileName file={file} maxLength={45} />
							</td>
							<td>
								<TextField
									key={file.file_id}
									label="Password"
									variant="outlined"
									id={`password-${file.file_id}`}
									onKeyDown={(event) => {
										// Stop propagation to prevent focusing menu items in DealFiles
										event.stopPropagation();
									}}
									onChange={(event) => {
										let newPassword = event.target.value;
										handlePasswordChange(file, newPassword);
									}}
									sx={
										passwordCorrect[file.file_id]
											? { color: "green", minWidth: 300 }
											: { minWidth: 300 }
									}
									size="small"
									autoComplete="off"
									error={passwordErrors[file.file_id]}
									disabled={passwordCorrect[file.file_id]}
								/>
							</td>
							<td>
								<LoadingButton
									onClick={() => submitPassword(file)}
									variant="contained"
									disableElevation
									loading={passwordLoading[file.file_id]}
									disabled={passwordCorrect[file.file_id]}
								>
									{passwordCorrect[file.file_id] ? <Check /> : "Unlock"}
								</LoadingButton>
							</td>
						</tr>
					))}
				</table>
			</DialogContent>
			<DialogActions>
				<Button onClick={handlePasswordDialogClose} color="secondary">
					Cancel
				</Button>
				<Button onClick={handleDialogSubmission}>OK</Button>
			</DialogActions>
		</Dialog>
	);
}

/**
 * Button to unlock files
 */
export function UnlockFilesButton({ setShowPasswordDialog, setLockedFileIds }) {
	// State
	const [lockedFileIdsLocal, setLockedFileIdsLocal] = useState([]);

	// Display state
	const [hover, setHover] = useState(false);

	// Context
	const dealContext = useContext(DealContext);

	// Handle password dialog opening
	function handlePasswordDialogOpen() {
		setLockedFileIds(lockedFileIdsLocal);
		setShowPasswordDialog(true);
	}

	// useEffect to collect locked files
	useEffect(() => {
		let lockedFiles = dealContext.files.filter(
			(file) => file.status === "locked"
		);
		setLockedFileIdsLocal(lockedFiles.map((file) => file.file_id));
	}, [dealContext.files]); // eslint-disable-line react-hooks/exhaustive-deps

	// If no locked files, return null
	if (lockedFileIdsLocal.length === 0) {
		return null;
	}

	return (
		<div style={{ marginLeft: 10 }}>
			<IconButton
				onClick={handlePasswordDialogOpen}
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
			>
				<Badge badgeContent={lockedFileIdsLocal.length} color="warning">
					{hover ? <LockOpen /> : <Lock />}
				</Badge>
			</IconButton>
		</div>
	);
}

export default DealFiles;
