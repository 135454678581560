import { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
	Button,
	IconButton,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
} from "@mui/material";
import { Add, Delete as DeleteIcon } from "@mui/icons-material";

// Contexts
import UserContext from "../components/contexts/UserContext";

// Components
import Dashboard, { NewDocumentDropdown } from "./Dashboard";

// Utilities
import { get, del, formatDate } from "../utilities";

// CSS
import "../utilities.css";
import "./Home.css";

const DEFAULT_NAV_TILES = [
	{
		to: "/memo?memoFormat=docx",
		className: "memo-tile-bg",
		label: "Word Memo",
		description: "EOI-stage memo in paragraph form",
	},
	{
		to: "/memo?memoFormat=pptx",
		className: "memo-pptx-tile-bg",
		label: "PowerPoint Memo",
		description: "EOI-stage memo with slides",
	},
	{
		to: "/question-list",
		className: "question-tile-bg",
		label: "Question List",
		description: "Generate a follow-up question list",
	},
	{
		to: "/sidekick",
		className: "sidekick-tile-bg",
		label: "Sidekick",
		description: "Diligence Q&A and analysis",
	},
];
const ROUTING_PREFIXES = {
	memo: "/memo/",
	"question-list": "/question-list/",
};

/**
 * Home page for the app.
 *
 * Displays a welcome message and navigation tiles, as well as lists of
 * documents and deals.
 * @param {*} props
 */
export function Home(props) {
	// Contexts
	const userContext = useContext(UserContext);

	// Info states
	const [docs, setDocs] = useState([]);
	const [deals, setDeals] = useState([]);

	// Display states
	const [docsLoading, setDocsLoading] = useState(true);
	const [dealsLoading, setDealsLoading] = useState(true);

	const documentTableHeaders = [
		"Type",
		"Title",
		"Company",
		"Author",
		"Updated",
		"",
	]; // final col is delete button
	const documentTableAlignments = [
		"left",
		"left",
		"right",
		"right",
		"right",
		"right",
	];

	const dealTableHeaders = ["Company", "Created", "Documents", ""]; // final col is delete button
	const dealTableAlignments = ["left", "right", "right", "right"];

	// useEffect to load documents and deals
	useEffect(() => {
		setDocsLoading(true);
		get("/api/docs/").then((docs) => {
			setDocs(docs);
			setDocsLoading(false);
		});

		setDealsLoading(true);
		get("/api/deal/deals/").then((deals) => {
			setDeals(deals);
			setDealsLoading(false);
		});
	}, []);

	// Deletes a document from the database
	function handleDeleteDocument(id) {
		del(`/api/docs/${id}`, { deleteFolder: true }).then((res) => {
			if (res.success) {
				setDocs((prevDocs) => {
					var newDocs = [...prevDocs];
					newDocs = newDocs.filter((doc) => doc._id !== id);
					return newDocs;
				});
			}
		});
	}

	// Converts the document database objects to a table row
	function documentsToRows(docs) {
		return docs.map((doc) => {
			let { _id, type, title, companyName, author, lastUpdated } = doc;
			let content = [
				<DocumentDisplayText doc={doc} />,
				title,
				companyName,
				author,
				formatDate(lastUpdated),
				<DeleteButton
					deleteId={_id}
					deleteTitle={title}
					deleteCallback={() => handleDeleteDocument(_id)}
				/>,
			];
			let link = ROUTING_PREFIXES[type] + _id;

			let mappedContent = content.map((con) => {
				return { content: con, link: link };
			});

			// Get rid of link for last entry (delete button)
			mappedContent[mappedContent.length - 1].link = null;

			return mappedContent;
		});
	}

	// Converts the deal database objects to a table row
	function dealsToRows(deals) {
		return deals.map((deal) => {
			let { dealId, companyName, created } = deal;

			// Get number of documents with this dealId
			let dealDocs = docs.filter((doc) => doc.dealId === dealId);
			let docCount = dealDocs.length;

			// Set up delete dialog content with associated docs
			let deleteDialogContent = dealDocs.length > 0 && (
				<div>
					This will also delete the following documents:
					<ul>
						{dealDocs.map((doc) => (
							<li key={doc._id}>{doc.title}</li>
						))}
					</ul>
				</div>
			);

			// Format content for table
			let content = [
				companyName,
				formatDate(created),
				docCount,
				<DeleteButton
					deleteId={dealId}
					deleteTitle={companyName}
					content={deleteDialogContent}
					deleteCallback={() => handleDeleteDeal(dealId)}
				/>,
			];

			// Add link to deal page
			let link = `/deal/${dealId}`;

			let mappedContent = content.map((con, index) => {
				return { content: con, link: link };
			});

			// Get rid of link for last entry (delete button)
			mappedContent[mappedContent.length - 1].link = null;

			return mappedContent;
		});
	}

	// Deletes a deal from the database
	function handleDeleteDeal(dealId) {
		del(`/api/deal/${dealId}`).then((res) => {
			if (res.success) {
				// Remove deal from state
				setDeals((prevDeals) => {
					var newDeals = [...prevDeals];
					newDeals = newDeals.filter((deal) => deal.dealId !== dealId);
					return newDeals;
				});

				// Remove associated documents from state
				setDocs((prevDocs) => {
					var newDocs = [...prevDocs];
					newDocs = newDocs.filter((doc) => doc.dealId !== dealId);
					return newDocs;
				});
			}
		});
	}

	return (
		<div className="app-container">
			<div className="home-container u-flex-column u-flex-wrap u-gap-40">
				<h1>Welcome, {userContext.user ? userContext.user.firstName : null}</h1>
				<NavTiles tiles={DEFAULT_NAV_TILES} />
				<ItemList
					title={"Your documents"}
					headers={documentTableHeaders}
					items={documentsToRows(docs)}
					alignments={documentTableAlignments}
					newLabel={"New Document"}
					newLink={"/memo"}
					loading={docsLoading}
					newDropdown
				/>
				<ItemList
					title={"Your deals"}
					headers={dealTableHeaders}
					items={dealsToRows(deals)}
					alignments={dealTableAlignments}
					newLabel={"New Deal"}
					newLink={"/deal"}
					loading={dealsLoading}
				/>
			</div>
		</div>
	);
}

/**
 * A section of navigation tiles that link to different pages.
 * @param {*} props
 */
export function NavTiles({ tiles }) {
	return (
		<div className="build-document-container u-flex-column u-gap-20">
			<h2>Get started</h2>
			<div className="home-tiles-container u-flex u-gap-10 u-space-between">
				{tiles.map((tile, index) => (
					<LinkTile
						to={tile.to}
						className={tile.className}
						label={tile.label}
						description={tile.description}
						bubble={tile.bubble}
						desaturate={tile.desaturate}
						key={`navtile_${index}`}
					/>
				))}
			</div>
		</div>
	);
}

/**
 * A list of documents that the user has created or has access to.
 * @param {*} props
 */
export function ItemList({
	title,
	headers,
	alignments,
	items,
	newLabel,
	newLink,
	newDropdown,
	loading,
	dealId,
}) {
	// State
	const [newDocMenuOpen, setNewDocMenuOpen] = useState(false);
	const [newDocAnchorEl, setNewDocAnchorEl] = useState(null);

	// Hooks
	const navigate = useNavigate();

	// Handle new document button
	function handleNewDocClick(e) {
		if (newDropdown) {
			setNewDocMenuOpen(true);
			setNewDocAnchorEl(e.currentTarget);
		} else {
			navigate(newLink);
		}
	}

	return (
		<div className="your-documents-container u-flex-column u-gap-20">
			<div className="documents-header-container u-flex u-gap-10">
				<div className="u-flex-grow-1">
					<h2>{title}</h2>
				</div>
				<Button
					variant="outlined"
					color="secondary"
					startIcon={<Add />}
					className="add-document-button"
					onClick={handleNewDocClick}
				>
					{newLabel || "New"}
				</Button>
				<NewDocumentDropdown
					open={newDocMenuOpen}
					onClose={() => setNewDocMenuOpen(false)}
					anchorEl={newDocAnchorEl}
					dealId={dealId}
				/>
			</div>
			<Dashboard
				headers={headers}
				tableContent={items}
				alignments={alignments}
				loading={loading}
			/>
		</div>
	);
}

// A tile with a gradient background and icon that links somewhere
export function LinkTile(props) {
	return (
		<Link className={"u-flex-1"} to={props.to}>
			<div
				className={"link-tile" + (props.className ? " " + props.className : "")}
			>
				<div className="u-flex u-align-center u-gap-10">
					<h3>{props.label}</h3>
					{props.bubble && (
						<div className="link-tile-bubble">{props.bubble}</div>
					)}
				</div>
				<span className="link-tile-description">{props.description}</span>
			</div>
		</Link>
	);
}

/**
 * Formats the document type and format for display.
 * @param {*} props
 */
export function DocumentDisplayText(props) {
	// Formatted text for each type of doc
	const displayType = {
		memo: "Memo",
		"question-list": "Questions",
	};

	// Letter for icon for each format of memo
	const formatIconLetters = {
		docx: "W",
		pptx: "P",
		"pptx-hybrid": "P",
	};

	return (
		<span>
			{/* Always show document type */}
			{displayType[props.doc.type]}
			{/* If memo, display Word/PPTX icon */}
			{props.doc.type === "memo" && (
				<span
					className={
						"memo-format-icon " +
						(props.doc.format ? " " + props.doc.format : "docx")
					}
				>
					{formatIconLetters[props.doc.format ? props.doc.format : "docx"]}
				</span>
			)}
		</span>
	);
}

/**
 * Button to delete a document from the database.
 *
 * Also deletes associated folder from GCP storage.
 * @param {*} props
 */
export function DeleteButton(props) {
	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

	return (
		<>
			<IconButton
				onClick={() => setConfirmDialogOpen(true)}
				className="delete-section-button"
			>
				<DeleteIcon />
			</IconButton>
			<Dialog
				open={confirmDialogOpen}
				onClose={() => setConfirmDialogOpen(false)}
			>
				<DialogTitle>Delete {props.deleteTitle}?</DialogTitle>
				{props.content && <DialogContent>{props.content}</DialogContent>}
				<DialogActions>
					<Button color="secondary" onClick={() => setConfirmDialogOpen(false)}>
						{" "}
						Cancel{" "}
					</Button>
					<Button
						autoFocus
						color="error"
						onClick={() => {
							if (props.deleteCallback) props.deleteCallback(props.deleteId);
							setConfirmDialogOpen(false);
						}}
					>
						{" "}
						Delete{" "}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default Home;
