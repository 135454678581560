import React, { useState, useContext, useEffect, useCallback } from "react";

// Libraries
import {
	Dialog,
	DialogContent,
	DialogTitle,
	CircularProgress,
} from "@mui/material";
import { PictureAsPdf } from "@mui/icons-material";
import { Document, Page } from "react-pdf";

// Utilities
import { get } from "../utilities";

// Contexts
import { MemoContext } from "./contexts/MemoContext";

// CSS
import "./SourceValidator.css";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";

export function SourceValidator({ content, sourceNumber, sectionNumber }) {
	// Display state
	const [open, setOpen] = useState(false);

	// Open the dialog
	const handleClickOpen = () => {
		setOpen(true);
	};

	// Close the dialog
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<div className="source-validator" onClick={handleClickOpen}>
				{content}
			</div>
			<SourceValidatorDialog
				open={open}
				onClose={handleClose}
				content={content}
				sourceNumber={sourceNumber}
				sectionNumber={sectionNumber}
			/>
		</>
	);
}

export function SourceValidatorDialog({
	open,
	onClose,
	content,
	sourceNumber,
	sectionNumber,
}) {
	// State
	const [sourceInfo, setSourceInfo] = useState({});

	// Context
	const memoContext = useContext(MemoContext);

	// useEffect to get the source info
	useEffect(() => {
		const sources = memoContext.memoJSON[sectionNumber].sources;
		const sourceInfoInContext = sources[sourceNumber];

		if (sourceInfoInContext) {
			setSourceInfo(sourceInfoInContext);
		} else {
			setSourceInfo({ document: "Unknown" });
		}
	}, [sourceNumber]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
			<DialogTitle>Source Validation (BETA)</DialogTitle>
			<DialogContent>
				<div className="source-validator-dialog-content">
					<SourceValidatorLineItem heading="Datapoint" item={content} />
					<SourceValidatorLineItem
						heading="Source Document"
						item={
							<SourceValidatorFile
								dealId={sourceInfo.dealId}
								fileId={sourceInfo.fileId}
								fileName={sourceInfo.fileName}
								pageNumber={sourceInfo.page}
							/>
						}
					/>
					<div className="source-validator-dialog-page">
						<SourceValidatorPDFPage
							dealId={sourceInfo.dealId}
							fileId={sourceInfo.fileId}
							pageNumber={sourceInfo.page}
							searchText={sourceInfo.highlight}
						/>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
}

export function SourceValidatorLineItem({ heading, item }) {
	return (
		<div className="source-validator-line-item">
			<span className="heading">{heading}</span>
			<span className="content">{item}</span>
		</div>
	);
}

export function SourceValidatorFile({ dealId, fileId, fileName, pageNumber }) {
	async function getFile() {
		let { signedURL } = await get(`/api/deal/${dealId}/deal-file/${fileId}`);
		window.open(signedURL);
	}

	return (
		<div className="u-flex u-gap-5 source-validator-file" onClick={getFile}>
			<PictureAsPdf sx={{ color: "red" }} />{" "}
			{fileName + " | Page " + pageNumber}
		</div>
	);
}

export function SourceValidatorPDFPage({
	dealId,
	fileId,
	pageNumber,
	searchText,
}) {
	// State
	const [filePath, setFilePath] = useState("");

	// Display state
	const [loading, setLoading] = useState(true);

	async function getFileURL() {
		let { signedURL } = await get(`/api/deal/${dealId}/deal-file/${fileId}`);
		return signedURL;
	}

	// Highlight function
	function highlightPattern(text, pattern) {
		return text.replace(pattern, (value) => `<mark>${value}</mark>`);
	}

	// Custom text renderer
	const textRenderer = useCallback(
		(textItem) => highlightPattern(textItem.str, searchText),
		[searchText]
	);

	useEffect(() => {
		getFileURL().then((url) => {
			setFilePath(url);
			setLoading(false);
		});
	}, [dealId, fileId]); // eslint-disable-line react-hooks/exhaustive-deps

	// If no filePath, return loading
	if (loading) return <CircularProgress />;

	return (
		<div className="source-validator-page-container">
			<Document
				file={{
					url: filePath,
				}}
				className="section-sources-file-page-container"
			>
				<Page
					pageNumber={pageNumber}
					scale={1.35}
					customTextRenderer={textRenderer}
				/>
			</Document>
		</div>
	);
}

export default SourceValidator;
