import {
	IconButton,
	Tooltip,
	Avatar,
	Menu,
	MenuItem,
	ListItemIcon,
} from "@mui/material";
import "./NavBar.css";
import { getInitials } from "../utilities";
import { useContext, useState } from "react";

import UserContext from "./contexts/UserContext";
import { Link } from "react-router-dom";
import { Person, Settings, Logout } from "@mui/icons-material";

const settings = ["Profile", "Logout"];
const settingsIcons = {
	Profile: <Person />,
	Settings: <Settings />,
	Logout: <Logout />,
};

/**
 * Menu for user profile settings
 * @param {*} props
 */
export default function ProfileMenu(props) {
	const [anchorElUser, setAnchorElUser] = useState(null);
	const userContext = useContext(UserContext);
	const userName = userContext.user.firstName + " " + userContext.user.lastName;

	function handleOpenUserMenu(event) {
		setAnchorElUser(event.currentTarget);
	}

	function handleCloseUserMenu(setting) {
		if (setting === "Logout") userContext.logout();

		setAnchorElUser(null);
	}

	return (
		<div className="profile-menu">
			<Tooltip title="Profile settings">
				<IconButton sx={{ borderRadius: 2 }} onClick={handleOpenUserMenu}>
					<Avatar alt={userContext.user ? userName : null} variant="rounded">
						{userContext.user ? getInitials(userName) : null}
					</Avatar>
				</IconButton>
			</Tooltip>
			<Menu
				id="menu-appbar"
				anchorEl={anchorElUser}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				keepMounted
				transformOrigin={{ vertical: "top", horizontal: "right" }}
				onClose={handleCloseUserMenu}
				open={Boolean(anchorElUser)}
			>
				{settings.map((setting) => (
					<MenuItem
						key={setting}
						onClick={() => handleCloseUserMenu(setting)}
						component={Link}
						to={`/${setting.toLowerCase()}`}
					>
						<ListItemIcon>{settingsIcons[setting]}</ListItemIcon>
						{setting}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
}
