import { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";

import { DocxSectionParser } from "./SectionBuilder.js";

import "./Slides.css";

/**
 * A single slide display div.
 * @param {*} props
 */
export function Slide(props) {
	try {
		return (
			<div className="slide">
				<div className="slide-title">
					<h2>{props.slide.slide_title}</h2>
				</div>
				<ul className="slide-bullets">
					{props.slide.slide_content.map((bullet, index) => (
						<li key={index}>
							<DocxSectionParser section_content={bullet} />
						</li>
					))}
				</ul>
			</div>
		);
	} catch (err) {
		console.log(err);
	}
}

/**
 * A slide viewer.
 * @param {*} props
 */
export function SlideViewer(props) {
	const [currentSlide, setCurrentSlide] = useState(0);

	// Handle slide change
	function handleSlideChange(action) {
		if (action === "prev") {
			setCurrentSlide(Math.max(currentSlide - 1, 0));
		} else if (action === "next") {
			setCurrentSlide(Math.min(currentSlide + 1, props.slides.length - 1));
		}
	}

	// useEffect to reset currentSlide when slides change
	useEffect(() => {
		setCurrentSlide(0);
	}, [props.slides]);

	return (
		<div className={props.sectionEditing ? "section-editing" : ""}>
			<div className="slide-viewer-container">
				<SlideTracker currentSlide={currentSlide} slides={props.slides} />
				<div className="slide-viewer">
					<SlideChanger
						type="prev"
						currentSlide={currentSlide}
						slides={props.slides}
						onClick={() => handleSlideChange("prev")}
						enabled={currentSlide > 0}
					/>
					<Slide slide={props.slides[currentSlide]} />
					<SlideChanger
						type="next"
						currentSlide={currentSlide}
						slides={props.slides}
						onClick={() => handleSlideChange("next")}
						enabled={currentSlide < props.slides.length - 1}
					/>
				</div>
			</div>
			{props.sectionEditing && (
				<div className="section-editing-loading-container">
					<div className="section-editing-loading-content">
						<h3>Editing</h3>
						<CircularProgress />
					</div>
				</div>
			)}
		</div>
	);
}

/**
 * A slide changer.
 * i.e. "<" or ">"
 * @param {*} props
 */
export function SlideChanger(props) {
	const LABELS = {
		prev: "<",
		next: ">",
	};

	// Handle click
	function handleOnClick() {
		if (props.enabled) {
			props.onClick();
		}
	}

	return (
		<div
			className={"slide-changer" + (props.enabled ? " enabled" : "")}
			onClick={handleOnClick}
		>
			<div className="slide-changer-label">{LABELS[props.type]}</div>
		</div>
	);
}

/**
 * A slide tracker.
 * i.e. "Slide 1 of 10"
 */
export function SlideTracker(props) {
	return (
		<div className="slide-tracker">
			Slide {props.currentSlide + 1} of {props.slides.length}
		</div>
	);
}

export default Slide;
