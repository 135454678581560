import { useContext, useState, useEffect } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { Alert, Snackbar, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

// Context
import UserContext from "../components/contexts/UserContext";

// CSS
import "./Login.css";
import "../utilities.css";

/**
 * Login page. If logged in, redirects to home.
 */
export function Login() {
	const [loginStage, setLoginStage] = useState("login"); // [ login, mfa ]
	const [userEmail, setUserEmail] = useState(""); // Email of user

	// If loginStage is login, show login form
	if (loginStage === "login")
		return (
			<LoginForm setUserEmail={setUserEmail} setLoginStage={setLoginStage} />
		);
	// Else if loginStage is mfa, show mfa form
	else if (loginStage === "mfa") return <MFAForm userEmail={userEmail} />;
}

/**
 * Loading placeholder during authentication
 * @returns {Component}
 */
export function LoadingPlaceholder() {
	// Just a green gradient for now
	return <div className="login-container"></div>;
}

/**
 * Login form
 * @returns {Component}
 */
export function LoginForm({ setUserEmail, setLoginStage }) {
	// State
	const [emailError, setEmailError] = useState(false);
	const [passwordError, setPasswordError] = useState(false);
	const [accountError, setAccountError] = useState(""); // Error related to account/session expiry

	// Display states
	const [loading, setLoading] = useState(false); // To be used for login animation
	const [displayAccountError, setDisplayAccountError] = useState(false); // To be used for account error snackbar

	// Context
	const userContext = useContext(UserContext);

	// Hooks
	const [searchParams, setSearchParams] = useSearchParams();

	// useEffect to focus email field on load
	useEffect(() => {
		if (document.getElementById("email"))
			document.getElementById("email").focus();
	}, []);

	// useEffect to get account error from URL
	useEffect(() => {
		if (searchParams.get("accountError")) {
			setDisplayAccountError(true);
			setAccountError(searchParams.get("accountError"));

			// Remove account error from URL
			searchParams.delete("accountError");
			setSearchParams(searchParams);
		}
	}, [searchParams, setSearchParams]);

	// Login script
	function handleLogin(e) {
		e.preventDefault();

		// Set loading to true
		setLoading(true);

		// reset errors
		setEmailError(false);
		setPasswordError(false);

		userContext
			.login({
				email: e.target.email.value,
				password: e.target.password.value,
			})
			.then((result) => {
				setUserEmail(e.target.email.value);
				setLoginStage("mfa");
			})
			.catch((err) => {
				if (err.status === 404) setEmailError(true);
				else if (err.status === 401) setPasswordError(true);
				else if (err.status === 403) {
					setDisplayAccountError(true);
					setAccountError(err.res.errorMessage);
				} else console.log(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}

	// If logged in, redirect to home
	if (userContext.userToken && userContext.verifyToken()) {
		return <Navigate to="/" />;
	}

	return (
		<div className="login-container">
			<div className="login-form-container u-flex-column u-gap-40">
				<div className="logo-large logo-green logo-container">deepdive</div>
				<form className="login-form u-gap-30" onSubmit={handleLogin}>
					<div className="u-flex-column">
						<TextField
							type="text"
							id="email"
							name="email"
							label="Email"
							error={emailError}
							helperText={emailError ? "User not found" : " "}
						/>
						<TextField
							type="password"
							id="password"
							name="password"
							label="Password"
							error={passwordError}
							helperText={passwordError ? "Incorrect password" : " "}
						/>
					</div>
					<LoadingButton
						type="submit"
						variant="contained"
						loading={loading}
						disableElevation
					>
						Login
					</LoadingButton>
				</form>
			</div>
			<Snackbar
				open={displayAccountError}
				autoHideDuration={3000}
				onClose={() => setDisplayAccountError(false)}
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
			>
				<Alert severity="warning">{accountError}</Alert>
			</Snackbar>
		</div>
	);
}

/**
 * MFA form
 * @returns {Component}
 */
export function MFAForm({ userEmail }) {
	// State
	const [code, setCode] = useState(""); // Code from user
	const [submitted, setSubmitted] = useState(false); // Whether code has been submitted
	const [error, setError] = useState(false); // Error from server

	// Context
	const userContext = useContext(UserContext);

	// Consts
	const numDigits = 6;

	// Function to submit MFA code
	function submitMFA(e) {
		if (e) e.preventDefault();
		setError(false);
		setSubmitted(true);

		// Send code to server
		userContext
			.submitMFA(userEmail, code)
			.then((res) => {})
			.catch((err) => {
				console.log(err);
				setError(true);
			})
			.finally(() => {
				setSubmitted(false);
				setCode("");
			});
	}

	return (
		<div className="login-container">
			<div className="login-form-container u-flex-column u-gap-40">
				<div className="logo-large logo-green logo-container">deepdive</div>
				<form className="login-form u-gap-30" onSubmit={submitMFA}>
					<div className="mfa-alert">
						A {numDigits}-digit code has been sent to <b>{userEmail}</b>. Please
						enter it below.
					</div>
					<MFAInput
						code={code}
						setCode={setCode}
						submitMFA={submitMFA}
						error={error}
						setError={setError}
						submitted={submitted}
						numDigits={numDigits}
					/>
					<LoadingButton
						type="submit"
						variant="contained"
						loading={submitted}
						disableElevation
					>
						Submit Code
					</LoadingButton>
				</form>
			</div>
		</div>
	);
}

/**
 * Numeric MFA code input. Separate TextFields for each digit. Default is 6 digits.
 */
export function MFAInput({
	numDigits = 6,
	code,
	setCode,
	error,
	setError,
	submitMFA,
	submitted,
	fontSize = 30,
}) {
	numDigits = parseInt(numDigits);

	// State
	const [input, setInput] = useState(Array(numDigits).fill("")); // Input from user

	// Function handle change; sets code and clears error if code is not blank
	function handleChange(digit, index) {
		if (digit !== "") setError(false);

		// If input is blank, set to null
		setInput((prevInput) => {
			let newInput = [...prevInput];

			// Set if number
			if (!isNaN(digit)) newInput[index] = digit;
			// If blank, set to blank
			else if (digit === "") newInput[index] = "";
			return newInput;
		});
	}

	// useEffect to submit if code is complete
	useEffect(() => {
		if (code.length === numDigits && !submitted) {
			submitMFA();
		}
	}, [code, submitMFA, submitted, numDigits]);

	// useEffect to focus on first digit on load
	useEffect(() => {
		if (document.getElementById("mfa-0"))
			document.getElementById("mfa-0").focus();
	}, []);

	// useEffect to set code from input array
	useEffect(() => {
		setCode(input.join(""));
	}, [input, setCode]);

	return (
		<div className="u-flex-column u-gap-10">
			<div className="u-flex u-gap-10">
				{input.map((digit, index) => (
					<MFADigit
						key={index}
						index={index}
						digit={digit}
						setDigit={(digit, ind) => handleChange(digit, ind)}
						error={error}
						fontSize={fontSize}
						numDigits={numDigits}
					/>
				))}
			</div>
			<div
				className="u-flex u-justify-center"
				style={{ color: "var(--error)", fontWeight: 500, minHeight: "20px" }}
			>
				{error ? "Incorrect Code" : null}
			</div>
		</div>
	);
}

/**
 * Single digit within MFA input.
 */
export function MFADigit({
	index,
	digit,
	setDigit,
	error,
	fontSize,
	numDigits,
	success,
}) {
	function handleInput(e) {
		// If backspace, delete first, then go back to previous digit
		if (e.key === "Backspace") {
			// If current digit is not blank, delete and go back
			if (digit !== "") setDigit("", index);
			else if (index > 0) setDigit("", index - 1);

			// If first digit, do nothing
			if (index === 0) return;
			document.getElementById(`mfa-${index - 1}`).focus();
		}

		// If left or right arrow, move to previous or next digit
		if (e.key === "ArrowLeft") {
			// If first digit, do nothing
			if (index === 0) return;
			document.getElementById(`mfa-${index - 1}`).focus();
		} else if (e.key === "ArrowRight") {
			// If last digit, do nothing
			if (index === numDigits - 1) return;
			document.getElementById(`mfa-${index + 1}`).focus();
		}

		// Allow delete, return key
		if (e.key === "Delete" || e.key === "Enter") return;

		// Allow paste (Ctrl+V, Cmd+V)
		if (e.ctrlKey || e.metaKey) return;

		// Disallow spacebar
		if (e.key === " " || e.key === "Spacebar") e.preventDefault();

		// If key is not a number, prevent default
		if (isNaN(e.key)) e.preventDefault();
	}

	// Function to handle change
	function handleChange(e) {
		let input = e.target.value;

		// Limit to end of numDigits from current index
		input = input.slice(0, numDigits - index);

		// If input is not a number, set to blank
		if (isNaN(input)) {
			alert("Pasted value must be a number");
			return;
		}

		// Get first digit and set
		let newDigit = input[0];
		setDigit(newDigit, index);

		// If more than one digit, advance and set following
		if (input.length > 1) {
			for (let i = 1; i < input.length; i++) {
				newDigit = input[i];
				setDigit(newDigit, index + i);
			}

			// Focus on last digit pasted
			document.getElementById(`mfa-${index + input.length - 1}`).focus();
			return;
		}

		// If not blank, advance to next digit
		if (newDigit !== "") {
			if (index < numDigits - 1)
				document.getElementById(`mfa-${index + 1}`).focus();
		}
		// If blank, go back to previous digit and select all
		else if (index > 0) {
			document.getElementById(`mfa-${index - 1}`).focus();
			document.getElementById(`mfa-${index - 1}`).select();
		}
	}

	return (
		<TextField
			type="text"
			id={`mfa-${index}`}
			name={`mfa-${index}`}
			onKeyDown={handleInput}
			onInput={handleChange}
			onFocus={(e) => e.target.select()}
			value={digit}
			autoComplete="off"
			error={error}
			inputProps={{
				style: {
					fontSize: fontSize,
					textAlign: "center",
				},
			}}
		/>
	);
}

export default Login;
