import { Add, Warning } from "@mui/icons-material";
import {
	MenuList,
	MenuItem,
	IconButton,
	CircularProgress,
} from "@mui/material";

// CSS
import "./DocumentOutline.css";

/**
 * Component that displays the document outline (section numbers and headings)
 * @param {object} docJSON - the JSON object of the document, divided into sections
 */
export function DocumentOutline({ docJSON, oneIndex, addSection }) {
	const content = docJSON;

	let docSections = [];

	if (content) {
		// Get length of content and transform into range of numbers
		const contentLength = Object.keys(content).length;
		const indexes = [...Array(contentLength).keys()];

		// Cycle through sections by index and add to docSections
		docSections = indexes.map((index) => {
			// If content is not an array, 1-index the section number
			const sectionNumber = oneIndex ? index + 1 : index;

			// Get section
			const section = content[sectionNumber];

			// Get section_content and section_header
			const sectionContent = section.section_content || section.questions;
			const sectionHeader = section.section_header || section.category;

			// Get generating
			const generating = section.generating;

			// Get inOptions
			const inOptions = section.inOptions;

			// Return section
			return {
				sectionNumber,
				sectionContent,
				sectionHeader,
				generating,
				inOptions,
			};
		});
	}

	return (
		<div className="drawer-section">
			<div className="document-outline-header">
				<h3>Document Outline</h3>
				<div className="u-flex-grow-1" />
				<OutlineAddSectionButton addSection={addSection} />
			</div>
			<MenuList>
				{!content ? (
					<MenuItem>No content</MenuItem>
				) : (
					docSections.map((section) => {
						// Get link to section
						const link = `#section-${section.sectionNumber}`;

						// onClick handler
						function handleClick() {
							const element = document.querySelector(link);
							element.scrollIntoView({
								behavior: "smooth",
								block: "start",
							});
						}

						return (
							<MenuItem
								key={section.sectionNumber}
								onClick={handleClick}
								sx={{ display: "flex" }}
							>
								<span style={{ fontWeight: 500, marginRight: 8 }}>
									Section{" "}
									{oneIndex ? section.sectionNumber : section.sectionNumber + 1}
								</span>
								<OutlineSectionNotInOptions inOptions={section.inOptions} />
								<span
									style={
										section.inOptions === false ? { color: "#ffbf00" } : null
									}
								>
									{section.sectionHeader}
								</span>
								<OutlineSectionGeneratingIndicator
									generating={section.generating}
								/>
							</MenuItem>
						);
					})
				)}
			</MenuList>
		</div>
	);
}

export function OutlineSectionGeneratingIndicator({ generating }) {
	return generating ? (
		<CircularProgress size="18px" thickness={4.6} sx={{ ml: 1 }} />
	) : null;
}

export function OutlineSectionNotInOptions({ inOptions }) {
	return inOptions === false ? (
		<Warning sx={{ mr: 0.4, color: "#ffbf00" }} />
	) : null;
}

export function OutlineAddSectionButton({ addSection }) {
	return (
		<IconButton onClick={addSection}>
			<Add />
		</IconButton>
	);
}

export default DocumentOutline;
