import { Snackbar, Alert } from "@mui/material";

export function AlertSnackbar({ snackbar, setSnackbar, sx }) {
	if (!snackbar) return null;

	return (
		<Snackbar
			open={snackbar.open}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			sx={{
				...sx,
			}}
			autoHideDuration={6000}
			onClose={() => setSnackbar({ ...snackbar, open: false })}
		>
			<Alert severity={snackbar.severity}>{snackbar.message}</Alert>
		</Snackbar>
	);
}

export default AlertSnackbar;
