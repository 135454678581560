import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Tooltip,
	Select,
	MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Warning } from "@mui/icons-material";

// 1P components
import DocumentOutline from "./DocumentOutline";
import DealFiles from "./DealFiles";
import BuildMemo from "./BuildMemo";
import ModelSelector from "./ModelSelector";

// Contexts
import MemoContext from "./contexts/MemoContext";
import DealContext from "./contexts/DealContext";

// Utilities
import { del, hasUploadedDealFiles, post, changeMemoType } from "../utilities";

// CSS
import "./DocumentEditor.css";
import "../utilities.css";

export function DocumentEditor({
	docId,
	dealId,
	deal,
	docInfo,
	handleSetDocId,
	generationModel,
	setGenerationModel,
	generationModelJSON,
	generationModels,
}) {
	return (
		<div className="u-flex">
			<LeftMenu
				dealId={dealId}
				deal={deal}
				docId={docId}
				docInfo={docInfo}
				generationModel={generationModel}
				setGenerationModel={setGenerationModel}
				generationModelJSON={generationModelJSON}
				generationModels={generationModels}
				title={docInfo?.title}
			/>
			<BuildMemo
				memoId={docId}
				dealId={dealId}
				memoInfo={docInfo}
				setMemoIdCallback={handleSetDocId}
				generationModelJSON={generationModelJSON}
			/>
		</div>
	);
}

/**
 * A menu that slides in from a given position.
 * @param {string} position - the position of the menu, can be "left, right, top, bottom"
 * @param {React.Component} children - the contents of the menu
 * @param {number} toolbarPadding - the number of toolbars to add to the top of the menu
 */
export function DrawerMenu({ children, drawerWidth, heightOffset }) {
	if (!drawerWidth) {
		drawerWidth = 340;
	}

	const drawerHeight = `calc(100% - ${heightOffset ? heightOffset : 0}px`;

	return (
		<>
			<div
				className="left-menu"
				style={{ width: drawerWidth, height: drawerHeight }}
			>
				{children
					? children.map((item, index) => (
							<div key={index}>
								<div className="drawer-section">{item}</div>
							</div>
					  ))
					: null}
			</div>
			<div className="left-menu-spacer" style={{ width: drawerWidth }} />
		</>
	);
}

/**
 * The left menu of the document editor.
 * @param {string} dealId - the id of the deal
 */
export function LeftMenu({
	dealId,
	deal,
	docId,
	generationModel,
	generationModelJSON,
	setGenerationModel,
	generationModels,
	title,
}) {
	// Context
	const memoContext = useContext(MemoContext);

	// Function to add a section
	function addSection() {
		let docJSON = { ...memoContext.memoJSON };

		// Get first section type from memoContext
		let { section_type, section_header } = memoContext.sectionTypes[0] || {};

		// Add new section
		let newSection = {
			section_header: section_header || "",
			section_type: section_type || "",
			section_content: "",
		};

		// Add new section to docJSON
		let newSectionNumber = Object.keys(docJSON).length + 1;
		docJSON[newSectionNumber] = newSection;

		// Update memoJSON
		memoContext.setMemoJSON(docJSON);

		// After 0.1 seconds, scroll to new section
		setTimeout(() => {
			document
				.getElementById(`section-${newSectionNumber}`)
				.scrollIntoView({ behavior: "smooth" });
		}, 100);
	}

	const memoTypeSelector = (
		<MemoTypeSelector
			key="memoTypeSelector"
			dealId={dealId}
			availableMemoTypes={generationModelJSON.memoTypes}
			generationModelJSON={generationModelJSON}
		/>
	);
	const modelSelector = (
		<ModelSelector
			key="modelSelector"
			docId={docId}
			model={generationModel}
			setModel={setGenerationModel}
			models={generationModels}
		/>
	);
	const dealFiles = (
		<DealFiles dealId={dealId} key="dealFiles" maxFileNameLength={30} />
	);
	const docOutline = (
		<DocumentOutline
			docJSON={memoContext.memoJSON}
			oneIndex={true}
			key="docOutline"
			addSection={addSection}
		/>
	);
	const dealInfo = (
		<DealInfo deal={deal} key="dealInfo" allowSelection={false} />
	);
	const deleteDocumentButton = (
		<DeleteDocumentButton
			docId={docId}
			docTitle={title}
			key="delete"
			sx={{
				display: "flex",
				justifyContent: "center",
				position: "fixed",
				bottom: "0px",
				width: "340px",
				backgroundImage:
					"linear-gradient(180deg, rgba(255,255,255,0), rgba(255,255,255,1))",
				padding: "10px 0px",
			}}
		/>
	);

	const deleteDocumentSpacer = (
		<div style={{ height: "60px" }} key="deleteSpacer" />
	);

	const content = [
		modelSelector,
		memoTypeSelector,
		dealFiles,
		docOutline,
		dealInfo,
		deleteDocumentSpacer,
		deleteDocumentButton,
	];

	return (
		<DrawerMenu position="left" heightOffset={113}>
			{content}
		</DrawerMenu>
	);
}

export function EditorToolbar() {
	const toolbarItems = [];

	return (
		<div className="editor-toolbar u-flex u-align-center u-gap-20">
			{toolbarItems}
		</div>
	);
}

/**
 * Displays the deal information. Sometimes allows selection of the deal.
 *
 * @param {string} deal - the deal object
 * @param {boolean} allowSelection - whether or not to allow selection of the deal
 * @param {array} deals - the list of all accessible deals
 */
export function DealInfo({ deal }) {
	// If no deal, make a dummy
	if (!deal) {
		deal = {
			companyName: "",
			created: "",
			_id: "",
		};
	}

	const formattedCreated = new Date(deal.created).toLocaleDateString("en-US", {
		year: "numeric",
		month: "long",
		day: "numeric",
	});

	const contents = [
		{
			label: "Company",
			value: deal.companyName ? deal.companyName : "Loading...",
		},
		{
			label: "Created",
			value: deal.created ? formattedCreated : "Loading...",
		},
	];

	return (
		<div className="deal-info">
			<div className="drawer-section-header">
				<h3>Deal Information</h3>
			</div>
			<table style={{ padding: "6px 16px" }}>
				<tbody>
					{contents.map((item) => (
						<tr key={item.label}>
							<td style={{ borderSpacing: 6 }}>
								<span style={{ fontWeight: 500, marginRight: 8 }}>
									{item.label}
								</span>
							</td>
							<td>{item.value}</td>
						</tr>
					))}
				</tbody>
			</table>
			<div className="u-flex u-gap-10" style={{ padding: "6px 16px" }}>
				<Link
					className="u-flex-grow-1"
					to={deal._id ? `/deal/${deal._id}` : "#"}
				>
					<Button
						className="u-flex-grow-1"
						color="secondary"
						variant="outlined"
						disabled={!deal._id}
					>
						Deal Homepage
					</Button>
				</Link>
				<Link
					className="u-flex-grow-1"
					to={deal._id ? `/sidekick?dealId=${deal._id}` : "#"}
				>
					<Button variant="outlined" disabled={!deal._id}>
						Start Sidekick
					</Button>
				</Link>
			</div>
		</div>
	);
}

export function DeleteDocumentButton({ docId, docTitle, sx }) {
	const navigate = useNavigate();

	// State
	const [open, setOpen] = useState(false);

	// Function to delete document
	function deleteDocument() {
		// Delete document
		del(`/api/docs/${docId}`).then((res) => {
			if (res.success) {
				navigate("/");
			}
		});
	}

	return (
		<div className="delete-document-button" style={sx}>
			<Button
				variant="contained"
				color="error"
				onClick={() => {
					setOpen(true);
				}}
				disableElevation
			>
				Delete Document
			</Button>
			<Dialog onClose={() => setOpen(false)} open={open}>
				<DialogTitle>
					Delete <span style={{ fontWeight: 500 }}>{docTitle}</span>?
				</DialogTitle>
				<DialogContent>
					Are you sure you want to delete this document? This action cannot be
					undone.
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpen(false)} color="secondary">
						Cancel
					</Button>
					<Button onClick={deleteDocument} color="error">
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export function GenerateMemoButton() {
	// State
	const [loading, setLoading] = useState(false); // Whether memo is generating by click

	// Context
	const memoContext = useContext(MemoContext);
	const dealContext = useContext(DealContext);

	// Consts
	const disableGenerate = !hasUploadedDealFiles(dealContext.files);
	const disableMessage = disableGenerate
		? "Add source files to generate memo content"
		: null;

	// Generates all memo sections that are blank
	function generateFullMemo() {
		setLoading(true);

		// Go through each section to see if there is content, if not, generate
		let sectionGenerating = false;
		Object.keys(memoContext.memoJSON).forEach((sectionNumber) => {
			var section_content_div = document.getElementById(
				"section_content_" + sectionNumber
			);

			if (section_content_div !== null) {
				var existing_content = section_content_div.innerHTML;

				if (existing_content === "") {
					// Click generate button for section
					var generate_button = document.getElementById(
						"generate_section_" + sectionNumber
					);
					generate_button.click();
					sectionGenerating = true;
				}
			}
		});

		// If no sections are generating, set loading to false and display snackbar
		if (!sectionGenerating) {
			setLoading(false);
			const snackbarMessage = {
				message: "No blank sections to generate",
				severity: "warning",
			};

			memoContext.displaySnackbar(snackbarMessage);
		}
	}

	// useEffect to check whether all sections have finished generating
	// If so, set loading to false
	useEffect(() => {
		// Only if we've clicked the generate button
		if (loading) {
			// Check if some sections are generating
			var someGenerating = false;
			Object.keys(memoContext.memoJSON).forEach((sectionNumber) => {
				someGenerating =
					someGenerating || memoContext.memoJSON[sectionNumber].generating;
			});

			setLoading(someGenerating);
		}
	}, [memoContext.memoJSON, loading]);

	return (
		<Tooltip
			arrow
			title={disableMessage}
			disableHoverListener={!disableMessage}
		>
			<div className="generate-memo-button">
				<LoadingButton
					variant="contained"
					color="primary"
					disableElevation
					onClick={generateFullMemo}
					loading={loading}
					disabled={disableGenerate}
				>
					Generate Memo
				</LoadingButton>
			</div>
		</Tooltip>
	);
}

export function MemoTypeSelector({ availableMemoTypes, generationModelJSON }) {
	const memoContext = useContext(MemoContext);

	// State
	const [memoTypes, setMemoTypes] = useState([]);
	const [newMemoType, setNewMemoType] = useState("direct_pe");

	// Display state
	const [displayMemoTypeChangeDialog, setDisplayMemoTypeChangeDialog] =
		useState(false);

	// Function to set memo type on backend when changed
	function setMemoType(memoType) {
		// Set memo type in context
		memoContext.setMemoType(memoType);

		// Set memo type on backend
		post(`/api/docs/memo/${memoContext.memoId}/subtype`, {
			subtype: memoType,
		});
	}

	// useEffect to filter out memo types that are not allowed
	useEffect(() => {
		if (availableMemoTypes) {
			setMemoTypes(availableMemoTypes);

			// If current memo type is not allowed, set to first allowed type
			if (
				!availableMemoTypes.some((item) => item.value === memoContext.memoType)
			) {
				setMemoType(availableMemoTypes[0].value);
			}
		}
	}, [availableMemoTypes]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="memo-type-selector">
			<div className="drawer-container">
				<div className="drawer-section-header">
					<h3>Memo Type</h3>
				</div>
				<div className="drawer-section">
					<Select
						fullWidth
						value={memoTypes.length ? memoContext.memoType : ""}
						onChange={(event) => {
							let newMemoTypeObj = memoTypes.find(
								(item) => item.value === event.target.value
							);
							setNewMemoType(newMemoTypeObj);
							setDisplayMemoTypeChangeDialog(true);
						}}
						sx={{
							minWidth: 200,
							".MuiOutlinedInput-notchedOutline": { borderStyle: "none" },
						}}
						size="small"
					>
						{memoTypes.map((item) => (
							<MenuItem key={item.value} value={item.value}>
								{item.label}
							</MenuItem>
						))}
					</Select>
					<MemoTypeChangeDialog
						open={displayMemoTypeChangeDialog}
						setOpen={setDisplayMemoTypeChangeDialog}
						newMemoType={newMemoType}
						generationModelJSON={generationModelJSON}
					/>
				</div>
			</div>
		</div>
	);
}

export function MemoTypeChangeDialog({
	open,
	setOpen,
	newMemoType,
	generationModelJSON,
}) {
	// Context
	const memoContext = useContext(MemoContext);

	// Function to change memo type
	async function handleMemoTypeChange() {
		let oldMemoJSON = { ...memoContext.memoJSON };
		let newMemoJSON = await changeMemoType(
			memoContext.memoId,
			oldMemoJSON,
			newMemoType.value,
			generationModelJSON
		);

		memoContext.setMemoJSON(newMemoJSON);
		memoContext.setMemoType(newMemoType.value);

		// Display snackbar
		memoContext.displaySnackbar({
			message: `Memo type changed to ${newMemoType.label}`,
			severity: "success",
		});

		// Close dialog
		setOpen(false);
	}

	return (
		<Dialog onClose={() => setOpen(false)} open={open}>
			<DialogTitle sx={{ color: "var(--warning)" }}>
				<div className="u-flex u-align-center">
					<Warning sx={{ mr: 1 }} />
					Change Memo Type?
				</div>
			</DialogTitle>
			<DialogContent>
				<p>
					Are you sure you want to change the memo type to{" "}
					<b>{newMemoType.label}?</b>
				</p>
				<p>This will reset the memo content.</p>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => setOpen(false)} color="secondary">
					Cancel
				</Button>
				<Button onClick={handleMemoTypeChange} color="warning">
					Change
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default DocumentEditor;
