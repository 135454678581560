import { useState, useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { TextField, Button, Snackbar, Alert } from "@mui/material";

// Contexts
import UserContext from "../components/contexts/UserContext";

// CSS
import "../utilities.css";
import "./Login.css";

function Register() {
	// User context
	const userContext = useContext(UserContext);

	// Info states
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [password2, setPassword2] = useState("");

	// Error states
	const [passwordError, setPasswordError] = useState(" ");
	const [accountError, setAccountError] = useState("");

	// Display states
	const [displaySnackbar, setDisplaySnackbar] = useState(false);

	// Search params
	const [searchParams] = useSearchParams();

	// Handle verification
	function handleRegister(event) {
		event.preventDefault();

		// Check if passwords match
		if (password !== password2) {
			setPasswordError("Passwords do not match");
			return;
		}

		// Check both passwords are not empty
		if (password === "" || password2 === "") {
			setPasswordError("Password is required");
			return;
		}

		// Assemble credentials
		const credentials = {
			email,
			firstName,
			lastName,
			password,
			password2,
		};

		userContext.register(credentials).catch((err) => {
			console.log(err);
			if (err.status === 401) {
				setAccountError(err.res.errorMessage);
				setDisplaySnackbar(true);
			} else if (err.status === 400) {
				setPasswordError(err.res.errorMessage);
			}
		});
	}

	// useEffect to set email, firstName, lastName from search params
	useEffect(() => {
		setEmail(searchParams.get("email"));
		setFirstName(searchParams.get("firstName"));
		setLastName(searchParams.get("lastName"));
	}, [searchParams]);

	// useEffect to select password input on load
	useEffect(() => {
		if (!userContext.userToken) document.getElementById("password").focus();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// Check if logged in
	if (userContext.userToken && userContext.verifyToken()) {
		return (
			<div className="u-flex-column u-align-center u-gap-15">
				<h3>
					You are already logged in. Please log out to register a new account.
				</h3>
			</div>
		);
	}

	return (
		<div className="login-container">
			<div className="login-form-container u-flex-column u-align-center u-gap-40">
				<div className="logo-large logo-green logo-container">deepdive</div>
				<form
					className="verify-email-form u-flex-column u-gap-10"
					onSubmit={handleRegister}
				>
					<div className="u-flex-column u-gap-30">
						<div className="u-flex-column u-gap-10">
							<TextField
								label="Email"
								value={email}
								id="username"
								disabled
								onChange={(e) => setEmail(e.target.value)}
							/>
							<div className="u-flex u-gap-15">
								<TextField
									className="u-flex-grow-1"
									label="First Name"
									value={firstName}
									onChange={(e) => setFirstName(e.target.value)}
								/>
								<TextField
									className="u-flex-grow-1"
									label="Last Name"
									value={lastName}
									onChange={(e) => setLastName(e.target.value)}
								/>
							</div>
						</div>
						<div className="u-flex-column u-gap-10">
							<TextField
								label="Password"
								value={password}
								id="password"
								type="password"
								autoComplete="new-password"
								onChange={(e) => {
									setPassword(e.target.value);
									setPasswordError(" ");
								}}
								error={passwordError !== " "}
							/>
							<TextField
								label="Confirm Password"
								type="password"
								autoComplete="new-password"
								value={password2}
								helperText={passwordError}
								error={passwordError !== " "}
								onChange={(e) => {
									setPassword2(e.target.value);
								}}
							/>
						</div>
					</div>
					<Button
						variant="contained"
						color="primary"
						type="submit"
						disableElevation
					>
						Register
					</Button>
				</form>
			</div>
			<Snackbar
				open={displaySnackbar}
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				onClose={() => setDisplaySnackbar(false)}
				autoHideDuration={3000}
			>
				<Alert severity="error">{accountError}</Alert>
			</Snackbar>
		</div>
	);
}

export default Register;
