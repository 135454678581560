// Memo context
import { createContext } from "react";

export const MemoContext = createContext({
	allowSaving: false,
	setAllowSaving: () => {},
	sectionTypes: [],
	setSectionTypes: () => {},
	title: "",
	setTitle: () => {},
	target: "",
	setTarget: () => {},
	memoJSON: {},
	setMemoJSON: () => {},
	memoFormat: "",
	setMemoFormat: () => {},
});

export default MemoContext;
