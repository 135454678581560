import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import NavBar from "./components/NavBar";
import reportWebVitals from "./reportWebVitals";

import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";

import { BrowserRouter } from "react-router-dom";

// User context
import { UserContextProvider } from "./components/contexts/UserContext.js";
import { ProtectedRoute } from "./components/ProtectedRoute";

const root = ReactDOM.createRoot(document.getElementById("root"));
const ddTheme = createTheme({
	components: {
		MuiInput: {
			styleOverrides: {
				root: {
					"&:before": {
						borderBottom: "none",
					},
					"&:hover:not(.Mui-disabled, .Mui-error):before": {
						borderBottom: "none",
					},
					"&.Mui-focused:after": {
						borderBottom: "none",
					},
				},
			},
		},
	},
	palette: {
		primary: {
			main: "#00e575",
			contrastText: "#ffffff",
		},
		secondary: {
			main: "#666",
		},
		wordDoc: {
			main: "#002366",
			dark: "#003288",
			contrastText: "#ffffff",
		},
		excelDoc: {
			main: "#217346",
			dark: "#219346",
			contrastText: "#ffffff",
		},
		pptDoc: {
			main: "#d24726",
			dark: "#e34726",
			contrastText: "#ffffff",
		},
	},
	typography: {
		fontFamily: ["Barlow", "sans-serif"].join(","),
	},
});

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<UserContextProvider>
				<ThemeProvider theme={ddTheme}>
					<ProtectedRoute>
						<NavBar />
						<App />
					</ProtectedRoute>
				</ThemeProvider>
			</UserContextProvider>
		</BrowserRouter>
	</React.StrictMode>
);

reportWebVitals();
